import EPG from "./EPG";
export default class Record {
  constructor(item, recordsList) {
    // Record
    this.idRecord = item.idRecord;
    this.idSeries = item.idSeries;
    this.idEvent = item.idEvent;
    this.idChannel = item.idChannel;
    this.status = item.status;
    this.context = item.context;
    this.epgContext = item.epgContext;
    this.startUtc = item.startUtc;
    this.endUtc = item.endUtc;
    this.preGap = item.preGap;
    this.postGap = item.postGap;
    this.seekSeconds = item.seekSeconds;
    this.program = undefined;
    if (item.epgContext && item.epgContext !== "{}") {
      this.program = new EPG(JSON.parse(item.epgContext), recordsList);
      this.program.setFta(true);
    } else if (item.context && item.context !== "{}") {
      this.program = new EPG(JSON.parse(item.context), recordsList);
      this.program.setFta(true);
    }
  }

  getIdRecord() {
    return this.idRecord;
  }

  getIdSeries() {
    return this.idSeries;
  }

  getIdEvent() {
    return this.idEvent;
  }

  getIdChannel() {
    return this.idChannel;
  }

  getStatus() {
    return this.status;
  }

  getContext() {
    return this.context;
  }

  getEpgContext() {
    return this.kidProfile;
  }

  getStartUtc() {
    return this.startUtc;
  }

  getEndUtc() {
    return this.endUtc;
  }

  getPreGap() {
    return this.preGap;
  }

  getPostGap() {
    return this.postGap;
  }

  getSeekSeconds() {
    return this.seekSeconds;
  }

  getProgram() {
    return this.program;
  }
}
