import InlineSVG from "inline-svg-react";
import React from "react";

const Download = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Download.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Download.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M11.4564292,12.8318984 L15.1094558,16.4751593 L14.0027408,17.5670792 L8.47955409,12.0586577 L14.0027408,6.55023618 L15.1094558,7.64215608 L11.45834,11.2835113 L21,11.2835113 L21,12.8318984 L11.4564292,12.8318984 Z M8.95952371,18.5038734 L8.95952371,20.064803 L5.86274951,20.064803 C4.34185851,20.064803 3.02403984,19.1348576 3.02403984,17.8274706 L3.02403984,6.17252942 C3.02403984,4.86514242 4.34185851,3.93519696 5.86274951,3.93519696 L8.95952371,3.93519696 L8.95952371,5.49612658 L5.86274951,5.49612658 C5.10323897,5.49612658 4.57242693,5.87070485 4.57242693,6.17252942 L4.57242693,17.8274706 C4.57242693,18.1292951 5.10323897,18.5038734 5.86274951,18.5038734 L8.95952371,18.5038734 Z"
            id="Combined-Shape"
            fill="#FFFFFF"
            transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000) "
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Download;
