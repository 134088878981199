import PropTypes from "prop-types";
import React, { Component } from "react";
import { List } from "react-virtualized";

export default class AnimatedList extends Component {
  listRef = React.createRef();
  isScrolling = true;

  state = {
    scrollTop: null,
  };

  constructor(props) {
    super(props);

    this._onScroll = this.onScroll.bind(this);
  }

  componentDidMount() {
    if (this.props.scrollToRow) {
      this.isScrolling = true;
      this._initScroll();
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.scrollToRow !== prevProps.scrollToRow &&
      this.props.scrollToRow !== -1
    ) {
      this.isScrolling = true;
      this._initScroll();
    }
  }

  render() {
    return (
      <List
        {...this.props}
        onScroll={this.onScroll}
        ref={this.listRef}
        scrollToRow={undefined}
      />
    );
  }

  _initScroll() {
    if (this.listRef && this.listRef.current) {
      this._scrollTopFinal = this.listRef.current.getOffsetForRow({
        index: this.props.scrollToRow,
      });
      this.setState({ scrollTop: this._scrollTopFinal });
    }
  }

  onScroll = ({ scrollTop }) => {
    if (scrollTop !== undefined && this._scrollTopInitial > 0) {
      this.props.onScroll();
      this._scrollTopInitial = scrollTop;
    }
    this.setState({ scrollTop: scrollTop });
  };
}

AnimatedList.propTypes = {
  scrollToRow: PropTypes.number,
  onScroll: PropTypes.func,
};

AnimatedList.defaultProps = {
  onScroll: () => {},
};
