import InlineSVG from "inline-svg-react";
import React from "react";

const SeekBackward = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/SeekBackward.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/SeekBackward.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M4.072 17.536a9.132 9.132 0 01-.502-.973l-.446.197c.156.352.333.695.53 1.025l.418-.249zm-.89-2.018a9.335 9.335 0 01-.258-1.066l-.48.087c.069.379.16.753.271 1.12l.466-.141zm-.388-2.144a9.447 9.447 0 01-.005-1.096l-.487-.026c-.02.384-.019.77.005 1.153l.487-.03zm.12-2.202c.062-.36.145-.716.249-1.068l-.468-.138c-.11.37-.196.745-.262 1.123l.48.083zm.63-2.123a9.35 9.35 0 01.489-.982l-.423-.242c-.192.335-.364.68-.515 1.032l.448.192zm1.085-1.898c.218-.297.45-.58.698-.848l-.358-.33c-.26.28-.504.577-.733.89l.393.288zm1.49-1.612c.279-.239.57-.461.872-.665L6.72 4.47a9.972 9.972 0 00-.917.7l.317.369zm3.889-2.007a9.406 9.406 0 011.078-.202l-.062-.484c-.381.049-.76.12-1.133.212l.117.474zm2.072-.34c5.103 0 9.241 4.217 9.241 9.423 0 5.205-4.138 9.423-9.24 9.423-2.867 0-5.517-1.34-7.26-3.591l-.385.298c1.834 2.37 4.625 3.78 7.644 3.78 5.375 0 9.729-4.438 9.729-9.91 0-5.473-4.354-9.91-9.729-9.91v.487z" />
          <path d="M8.932 2.948l3.367 1.98V.969z" />
          <text x="7" y="14" style={{ fontSize: "5px", fontFamily: "arial" }}>
            -15s
          </text>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default SeekBackward;
