import InlineSVG from "inline-svg-react";
import React from "react";

const Favorites = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Favorites.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Favorites.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M17.1396476,1.90909091 C20.3986041,1.90909091 22.6363636,4.53904641 22.6363636,7.98701299 C22.6363636,13.2159957 15.530497,21.7792208 12.093974,21.7792208 C12.0779986,21.7792208 12.0624153,21.778818 12.047238,21.7780583 C12.0319281,21.7788197 12.0161847,21.7792208 12,21.7792208 C8.53774764,21.7792208 1.36363636,13.2170062 1.36363636,7.98701299 C1.36363636,4.5386595 3.62624854,1.90909091 6.91084591,1.90909091 C9.13754284,1.90909091 10.730338,3.026463 12.0428803,4.96873554 C13.3440922,3.01340328 14.9057044,1.90909091 17.1396476,1.90909091 Z M17.2363793,15.9896977 C19.5381249,13.2158818 21,10.2376019 21,7.98701299 C21,5.38945936 19.4309851,3.54545455 17.1396476,3.54545455 C15.2464578,3.54545455 13.9612227,4.72332773 12.7687252,6.94691785 L12.0516796,8.28395671 L11.3284499,6.9502528 C10.1339949,4.74756496 8.80465909,3.54545455 6.91084591,3.54545455 C4.58916931,3.54545455 3,5.39235954 3,7.98701299 C3,10.2356089 4.47580483,13.2131683 6.79969844,15.986707 C8.75740309,18.3232037 10.9959195,20.0557646 11.9316486,20.1396714 C11.9487269,20.1373711 11.9665042,20.1354642 11.9849366,20.1340463 L12.0503023,20.1290182 L12.1156346,20.1344626 C12.1317238,20.1358033 12.1473549,20.1375308 12.1624599,20.1396714 C13.0851035,20.0543639 15.2999074,18.323325 17.2363793,15.9896977 Z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Favorites;
