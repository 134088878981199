import InlineSVG from "inline-svg-react";
import React from "react";

const Live = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Live.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Live.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path
            fillRule="evenodd"
            d="M18.128 18.792H5.871c-.411 0-.746.41-.746.916s.335.917.746.917h12.257c.413 0 .747-.41.747-.917 0-.507-.334-.916-.747-.916M22.044 4H1.957A.958.958 0 0 0 1 4.957v10.835c0 .528.429.957.957.957h20.087a.957.957 0 0 0 .956-.957V4.957A.957.957 0 0 0 22.044 4zM2.833 14.915h18.333V5.833H2.833v9.082z"
          ></path>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Live;
