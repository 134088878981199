import classnames from "classnames";
import Logo from "components/LogoContent";
import PropTypes from "prop-types";
import React from "react";
import { withTranslation } from "react-i18next";

import style from "./style.module.css";

function ErrorBoundary(props) {
  const { message, rootClassName, isPopup, t } = props;
  const finalClassName = classnames(rootClassName, style.root);

  let errorMessage = t("Oops, something went wrong") + "...";
  if (message) {
    errorMessage = message;
  }

  return (
    <div className={finalClassName}>
      <Logo className={isPopup ? style.logoPopup : style.logo} />
      {isPopup ? <h4>{errorMessage}</h4> : <h1>{errorMessage}</h1>}
    </div>
  );
}

ErrorBoundary.defaultProps = {
  message: "",
};

ErrorBoundary.propTypes = {
  message: PropTypes.string,
};

export default withTranslation()(ErrorBoundary);
