import classnames from "classnames";
import AverageRating from "components/AverageRating";
import StarFull from "components/Icons/StarFull";
import StarOutline from "components/Icons/StarOutline";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Rating extends Component {
  setValue(score) {
    if (score !== this.props.asset.getScore()) {
      if (this.props.onStarClick) {
        this.props.onStarClick(score);
      }
    }
  }

  render() {
    const { asset, rootClassName } = this.props;
    const finalClassName = classnames(rootClassName, style.root);
    const stars = [];
    for (let i = 0; i < 5; i++) {
      if (i < asset.getScore()) {
        stars.push(
          <StarFull
            className={style.fullStar}
            key={i}
            onClick={this.setValue.bind(this, i + 1)}
          />
        );
      } else {
        stars.push(
          <StarOutline
            className={style.emptyStar}
            key={i}
            onClick={this.setValue.bind(this, i + 1)}
          />
        );
      }
    }
    return (
      <div className={finalClassName}>
        <AverageRating averageScore={asset.getAverageScore()} />
        <span className={style.separator}>|</span>
        <div className={style.scoreStarContainer}>{stars}</div>
      </div>
    );
  }
}
