import classnames from "classnames";
import ArrowHeading from "components/Icons/Arrowheading";
import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Accordion extends Component {
  state = {
    isExpanded: false,
  };

  toggleClickAccordion() {
    const { isExpanded } = this.state;
    this.setState({ isExpanded: !isExpanded });
  }

  render() {
    const { rootClassName, title } = this.props;
    const { isExpanded } = this.state;
    const direction = getFromLocal("layoutDirection");
    return (
      <div className={style.root}>
        <div
          className={style.title}
          onClick={this.toggleClickAccordion.bind(this)}
        >
          {title}
        </div>
        <ArrowHeading
          onClick={this.toggleClickAccordion.bind(this)}
          className={classnames(rootClassName, style.arrow, {
            [style.arrowOpened]: !isExpanded,
            [style.arrowClosed]: isExpanded,
            [style.rightArrowDirecton]: direction.toLocaleLowerCase() === "rtl",
          })}
        />
        <div
          className={classnames(rootClassName, style.container, {
            [style.opened]: !isExpanded,
            [style.closed]: isExpanded,
          })}
        >
          {this.props.children}
        </div>
      </div>
    );
  }
}
