import InlineSVG from "inline-svg-react";
import React from "react";

const Sad = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Sad.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Sad.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 30 30" {...props}>
          <circle r={15} cx={15} cy={15} />
          <text x="10" y="19">
            :(
          </text>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Sad;
