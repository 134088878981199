import InlineSVG from "inline-svg-react";
import React from "react";

const PlayerCloseFullscreen = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/PlayerCloseFullscreen.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/PlayerCloseFullscreen.svg");
    } catch (err) {
      return (
        <svg width="24" height="24" {...props}>
          <path d="M6.644 4.063h1.95v5.594H1v-1.95h5.644V4.063zm10.712 15.875h-1.95v-5.595H23v1.95h-5.644v3.645zm5.581-12.294v1.95h-7.594V4h1.95v3.644h5.644zM1.062 16.356v-1.95h7.595V20h-1.95v-3.644H1.062z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default PlayerCloseFullscreen;
