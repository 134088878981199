import { formatFullDateAndTime } from "../helpers/timeHelper";

export default class Device {
  constructor(data) {
    //console.log("data => ", data);
    let item = data;
    if (item) {
      this.name = item.name;
      this.type = item.type;
      this.moralityLevel = item.moralityLevel;
      this.uniqueDeviceId = item.uniqueDeviceId;
      this.lastLoginDate = item.lastLoginDate;
    }
  }

  getName() {
    return this.name;
  }

  getType() {
    return this.type;
  }

  getMoralityLevel() {
    return this.moralityLevel;
  }

  getUniqueDeviceId() {
    return this.uniqueDeviceId;
  }

  getLastLoginDate() {
    const lastLoginDate = formatFullDateAndTime(new Date(this.lastLoginDate * 1000));
    return lastLoginDate;
  }
}
