import InlineSVG from "inline-svg-react";
import React from "react";

const Record = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Record.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Record.svg");
    } catch (err) {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#8C8C8C">
              <path
                d="M12 21.667c5.339 0 9.667-4.328 9.667-9.667 0-5.339-4.328-9.667-9.667-9.667-5.339 0-9.667 4.328-9.667 9.667 0 5.339 4.328 9.667 9.667 9.667zm0-1.334a8.333 8.333 0 1 1 0-16.666 8.333 8.333 0 0 1 0 16.666z"
                fillRule="nonzero"
              />
              <circle cx="12" cy="12" r="4.667" />
            </g>
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Record;
