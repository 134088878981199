import "./style.module.css";

import classnames from "classnames";
import Arrow from "components/Icons/Arrow";
import ArrowHeading from "components/Icons/Arrowheading";
import PadlockLock from "components/Icons/PadlockLock";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import ProgressBar from "components/ProgressBar";
import consts from "consts/consts";
import Flickity from "flickity";
import { getFromLocal } from "helpers/localStorage";
import debounce from "lodash.debounce";
import React, { Component, Fragment } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";
import { TucanoConsts } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

const THUMBNAIL_DIMENSIONS = {
  [ImageRatio.COVER]: {
    width: 125,
    height: 70,
  },
  [ImageRatio.POSTER]: {
    width: 89,
    height: 135,
  },
};

class SearchStrate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flickityReady: false,
      showLeftArrow: false,
      showRightArrow: false,
      options: {
        pageDots: false,
        prevNextButtons: false,
        contain: true,
        cellAlign: getFromLocal("layoutDirection") === "rtl" ? "right" : "left",
        rightToLeft: getFromLocal("layoutDirection") === "rtl",
        groupCells: true,
        selectedAttraction: 0.05,
        friction: 0.8,
      },
    };

    this.refreshFlickity = this.refreshFlickity.bind(this);
  }

  componentDidMount() {
    this.flickity = new Flickity(this.flickityNode, this.state.options || {});

    this.setState({
      flickityReady: true,
    });

    this.flickity.on("ready", () => this.checkIndex());
    this.flickity.on("select", () => this.checkIndex());

    window.addEventListener(
      "resize",
      debounce(this.refreshFlickity, 1000),
      false
    );
  }

  refreshFlickity() {
    this.checkIndex();
    this.flickity.reloadCells();
    this.flickity.resize();
    this.flickity.updateDraggable();
  }

  isLock(item) {
    const { accountStatus } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!consts.padlocks) return false;
    if (item.isCategory) return false;
    if (item.isAssetTVShow && !item.isAssetTVShowEpisode) return false;
    if (item.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !item.userHasAccess;
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      debounce(this.refreshFlickity, 1000),
      false
    );
    // this.flickity.destroy();
  }

  componentDidUpdate(prevProps, prevState, _snapshot) {
    const flickityDidBecomeActive =
      !prevState.flickityReady && this.state.flickityReady;

    if (flickityDidBecomeActive) {
      this.refreshFlickity();
    }

    if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
      debounce(() => this.flickity.resize(), 500)();
    }
  }

  checkIndex() {
    this.setState({
      showRightArrow:
        this.flickity.selectedIndex < this.flickity.slides.length - 1,
    });
    this.setState({ showLeftArrow: this.flickity.selectedIndex > 0 });
  }

  handleLeftArrow() {
    this.flickity.previous();
  }

  handleRightArrow() {
    this.flickity.next();
  }

  renderPortal() {
    if (!this.flickityNode) {
      return null;
    }
    const { accountStatus } = this.props;
    const mountNode = this.flickityNode.querySelector(".flickity-slider");

    if (mountNode) {
      const { items, layout, imageType } = this.props;
      return ReactDOM.createPortal(
        <Fragment>
          {items.map((item, index) => {
            let url, src;
            if (item instanceof TucanoModels.Asset) {
              url = consts.routes.movieAsset.url.replace(":id", item.getId());
              if (item.isTVShow() || item.isAssetTVShowEpisode === true) {
                url = consts.routes.tvshowAsset.url.replace(
                  ":id",
                  item.getParentId()
                );
              } else if (item.isCategory) {
                url = consts.routes.vod.detailUrl.replace(":id", item.getId());
              }
            } else if (item instanceof TucanoModels.EPG) {
              url = consts.routes.epgAsset.url.replace(":id", item.getEpgId());
            }
            if (imageType) {
              if (layout === ImageRatio.COVER) {
                src = `${consts.image_hostname}${routes.imgdata}?type=${
                  item.isAssetTVShow ? 550 : 512
                }&objectId=${item.getId()}&format_w=${
                  THUMBNAIL_DIMENSIONS[layout].width
                }&format_h=${
                  THUMBNAIL_DIMENSIONS[layout].height
                }&languageId=${getFromLocal("languageId")}`;
              } else if (layout === ImageRatio.POSTER) {
                src = `${consts.image_hostname}${routes.imgdata}?type=${
                  item.isSerie
                    ? TucanoConsts.ImageType.VOD_CATEGORY_THUMBNAIL
                    : TucanoConsts.ImageType.VOD_THUMBNAIL
                }&objectId=${item.getId()}&format_w=${
                  THUMBNAIL_DIMENSIONS[layout].width
                }&format_h=${
                  THUMBNAIL_DIMENSIONS[layout].height
                }&languageId=${getFromLocal("languageId")}`;
              }
            }
            return (
              <Link
                key={index}
                to={url}
                className={classnames({
                  [style.landscapeThumbnail]: layout === ImageRatio.COVER,
                  [style.portraitThumbnail]: layout === ImageRatio.POSTER,
                })}
              >
                {((!item.userHasAccess && !item.isAssetTVShow) ||
                  (accountStatus &&
                    accountStatus.toLowerCase() === "suspended")) && (
                  <div className={style.lockContainer}>
                    <div className={classnames(style.padlockContainer)}>
                      <div
                        className={classnames(style.padlockIconContainer, {})}
                      >
                        <PadlockLock className={style.padlockLockIcon} />
                      </div>
                    </div>
                  </div>
                )}
                <Image
                  key={`${index}`}
                  rootClassName={classnames({
                    [style.landscapeThumbnailImg]: layout === ImageRatio.COVER,
                    [style.portraitThumbnailImg]: layout === ImageRatio.POSTER,
                  })}
                  src={
                    src
                      ? src
                      : item.getImage(consts.image_hostname, {
                          width: THUMBNAIL_DIMENSIONS[layout].width,
                          height: THUMBNAIL_DIMENSIONS[layout].height,
                          languageId: getFromLocal("languageId"),
                        })
                  }
                  width={"100%"}
                  height={"auto"}
                  ratio={layout}
                  placeholder={PlaceholderType.PRIMARY}
                />
                {item instanceof TucanoModels.EPG && item.isLive() && (
                  <ProgressBar
                    position={item.getProgress()}
                    rootClassName={style.progressBar}
                  />
                )}
              </Link>
            );
          })}
        </Fragment>,
        mountNode
      );
    }
  }

  render() {
    const { title, items, link, t } = this.props;

    return (
      <Fragment>
        {items.length > 0 && (
          <div>
            <p className={style.header}>
              {title}
              <Link className={style.link} to={link}>
                {items.length} {t(`result${items.length > 1 ? "s" : ""}`)}{" "}
                <Arrow className={style.icon} />
              </Link>
            </p>
            <div className={style.thumbnailsContainer}>
              <div
                className={style.flickity}
                key="flickityBase"
                ref={(node) => (this.flickityNode = node)}
              />
              {this.renderPortal()}
              {this.state.showLeftArrow && (
                <div
                  className={style.leftArrow}
                  style={{ marginTop: this.props.offsetTop }}
                  onClick={this.handleLeftArrow.bind(this)}
                >
                  <ArrowHeading className={style.arrow} />
                </div>
              )}

              {this.state.showRightArrow && (
                <div
                  className={style.rightArrow}
                  style={{ marginTop: this.props.offsetTop }}
                  onClick={this.handleRightArrow.bind(this)}
                >
                  <ArrowHeading className={style.arrow} />
                </div>
              )}
            </div>
            <hr className={style.separator} />
          </div>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state, _props) => {
    return {
      accountStatus: state.account.user?.data?.status,
    };
  })
)(SearchStrate);
