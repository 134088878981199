export default class Thumbnail {
  constructor(item) {
    this.id = item.idItem;
    this.type = item.idType;
    //this.title = item.content.description
    //this.image = item.
  }

  getId() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getTitle() {
    return this.title;
  }

  getUrl() {
    return this.url;
  }
}
