import InlineSVG from "inline-svg-react";
import React from "react";

const EyeClosed = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/EyeClosed.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/EyeClosed.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M4.799 4l12.536 14.073-1.107.985-1.605-1.801a8.965 8.965 0 01-2.85.456c-4.233 0-7.137-2.776-9.773-6.118 1.225-1.554 2.51-2.981 3.958-4.067L3.692 4.985 4.799 4zm3.003 5.6a4.445 4.445 0 005.496 6.17l-1.107-1.242a2.963 2.963 0 01-3.284-3.687zm3.97-4.123c4.233 0 7.139 2.776 9.773 6.118-1.3 1.648-2.665 3.157-4.223 4.265l-1.754-1.962c.41-.672.65-1.46.65-2.303a4.444 4.444 0 00-4.445-4.444 4.42 4.42 0 00-1.865.413l-1.34-1.5a8.946 8.946 0 013.205-.587zm.001 3.155a2.963 2.963 0 012.963 2.963c0 .397-.081.775-.224 1.121l-3.54-3.961a2.95 2.95 0 01.801-.123z"
            fill="#8C8C8C"
            fillRule="nonzero"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default EyeClosed;
