import InlineSVG from "inline-svg-react";
import React from "react";

const Remote = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Remote.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Remote.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M9.727 14.727h.728a1 1 0 0 1 1 1v.182a1 1 0 0 1-1 1h-.728a1 1 0 0 1-1-1v-.182a1 1 0 0 1 1-1zm0 3.273h.728a1 1 0 0 1 1 1v.182a1 1 0 0 1-1 1h-.728a1 1 0 0 1-1-1V19a1 1 0 0 1 1-1zm3.818-3.273h.728a1 1 0 0 1 1 1v.182a1 1 0 0 1-1 1h-.728a1 1 0 0 1-1-1v-.182a1 1 0 0 1 1-1zm0 3.273h.728a1 1 0 0 1 1 1v.182a1 1 0 0 1-1 1h-.728a1 1 0 0 1-1-1V19a1 1 0 0 1 1-1zM12 7.636A1.091 1.091 0 1 1 12 9.818 1.091 1.091 0 0 1 12 7.636zm4.09-5.727H7.91a.56.56 0 0 0-.545.575v19.032a.56.56 0 0 0 .545.575h8.181a.56.56 0 0 0 .546-.575V2.484a.56.56 0 0 0-.546-.575zm0-1.636c1.21 0 2.183.993 2.183 2.211v19.032c0 1.218-.973 2.211-2.183 2.211H7.91c-1.21 0-2.182-.993-2.182-2.211V2.484C5.727 1.266 6.7.273 7.91.273h8.181zm-5.228 6.654l-.771-.771 1.028-1.029a1.273 1.273 0 0 1 1.8 0l1.029 1.029-.772.771L12.148 5.9a.182.182 0 0 0-.257 0l-1.029 1.028zm0 3.503l1.029 1.028a.182.182 0 0 0 .257 0l1.028-1.028.772.771-1.029 1.029a1.273 1.273 0 0 1-1.8 0L10.091 11.2l.771-.771zm2.887-2.895l.772-.771 1.028 1.028a1.273 1.273 0 0 1 0 1.8l-1.028 1.029-.772-.772 1.029-1.028a.182.182 0 0 0 0-.257l-1.029-1.029zm-3.495 0L9.226 8.564a.182.182 0 0 0 0 .257l1.028 1.028-.771.772-1.029-1.029a1.273 1.273 0 0 1 0-1.8l1.029-1.028.771.771z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Remote;
