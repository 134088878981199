export const ResponsiveType = {
  SMPHONE: "SMPHONE",
  PHONE: "PHONE",
  TABLET: "TABLET",
  DESKTOP: "DESKTOP",
  DESKTOPSM: "DESKTOPSM",
};

export default [
  {
    name: ResponsiveType.PHONE,
    min: 0,
    max: 767,
    poster: { width: 152, height: 230 },
    posterLarge: { width: 238, height: 357 },
    cover: { width: 260, height: 146 },
    coverSmall: { width: 200, height: 146 },
    coverLarge: { width: 300, height: 169 },
    circle: { width: 152, height: 152 },
    square: { width: 152, height: 152 },
    squareLarge: { width: 300, height: 300 },
    totem: { width: 238, height: 476 },
    banner: { width: 360, height: 93 },
    editorialImg: { width: "100%", height: 202 },
    editorialText: { width: 360, height: 200 },
    subscriptionItem: { width: "100%", height: "auto" },
  },
  {
    name: ResponsiveType.TABLET,
    min: 768,
    max: 1024,
    poster: { width: 152, height: 230 },
    posterLarge: { width: 238, height: 357 },
    cover: { width: 280, height: 157 },
    coverSmall: { width: 209, height: 118 },
    coverLarge: { width: 320, height: 180 },
    circle: { width: 152, height: 152 },
    square: { width: 152, height: 152 },
    squareLarge: { width: 324, height: 324 },
    totem: { width: 238, height: 476 },
    banner: { width: 768, height: 192 },
    editorialImg: { width: "100%", height: 432 },
    editorialText: { width: 768, height: 432 },
    subscriptionItem: { width: 124, height: 70 },
  },
  {
    name: ResponsiveType.DESKTOP,
    min: 1024,
    max: Infinity,
    poster: { width: 152, height: 230 },
    posterLarge: { width: 266, height: 399 },
    cover: { width: 340, height: 191 },
    coverSmall: { width: 209, height: 118 },
    coverLarge: { width: 764, height: 430 },
    circle: { width: 171, height: 171 },
    square: { width: 171, height: 171 },
    squareLarge: { width: 362, height: 362 },
    totem: { width: 266, height: 532 },
    banner: { width: 1024, height: 256 },
    editorialImg: { width: "100%", height: 700 },
    editorialText: { width: 566, height: 700 },
    subscriptionItem: { width: 190, height: 107 },
  },
];
