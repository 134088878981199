import classnames from "classnames";
import CloseIcon from "components/Icons/Close";
import PlayIcon from "components/Icons/Play";
import Image, { PlaceholderType } from "components/Image";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class PlayerBingeOverlay extends Component {
  state = {
    counterTimer: undefined,
    counter: 10,
  };

  handleCloseClick(event) {
    event.preventDefault();
    clearTimeout(this.counterTimer);
    this.props.onClickCloseIcon();
  }

  componentDidMount() {
    if (this.counterTimer === undefined) {
      this.counterTimer = setTimeout(() => {
        this.upgradeCounter();
      }, 1000);
    }
  }

  componentWillUnmount() {
    if (this.counterTimer === 1) {
      clearTimeout(this.counterTimer);
    }
  }

  upgradeCounter() {
    if (this.state.counter === 1) {
      this.props.goNextEpisode();
      return;
    }
    this.setState((prevState) => {
      return {
        counter: prevState.counter - 1,
      };
    });
    this.counterTimer = setTimeout(() => {
      this.upgradeCounter();
    }, 1000);
  }

  render() {
    const { asset } = this.props;
    const { counter } = this.state;
    return (
      <CSSTransition
        in={true}
        timeout={1000}
        classNames={{
          enter: style.overlayEnter,
          enterActive: style.overlayEnterActive,
          enterDone: style.overlayEnterDone,
          exit: style.overlayExit,
          exitActive: style.overlayExitActive,
          exitDone: style.overlayExitDone,
        }}
      >
        <Fragment>
          <Link
            to={`${consts.routes.player.url.replace(
              ":assetId",
              asset.getId()
            )}?restart=true`}
          >
            <div className={classnames(style.rootContainer, style.background)}>
              <div className={style.imageContainer}>
                <Image
                  rootClassName={style.image}
                  width={268}
                  height={151}
                  src={`${consts.image_hostname}${routes.imgdata}?type=${
                    consts.imageTypes.movie_episode_cover
                  }&objectId=${asset.getId()}&format_w=268&format_h=151&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  placeholder={PlaceholderType.PRIMARY}
                />
                <PlayIcon className={style.playIcon} />
              </div>
              <div className={style.infoContainer}>
                <p className={style.message}>
                  <Trans>Next episode starts in</Trans> {counter}{" "}
                  <Trans>seconds</Trans>
                </p>
                <p className={style.title}>{asset.getTitle()}</p>
                <p className={style.synopsis}>{asset.getSynopsis()}</p>
              </div>
              <CloseIcon
                className={style.closeIcon}
                onClick={this.handleCloseClick.bind(this)}
              />
            </div>
          </Link>
        </Fragment>
      </CSSTransition>
    );
  }
}

export default withRouter(PlayerBingeOverlay);
