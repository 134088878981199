import Button, { ButtonType } from "components/Button";
import Modal from "components/Modal";
import consts from "consts/consts";
import { sendAnalytics } from "helpers/analytics";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./style.module.css";

const MODAL_INFO = "MODAL_INFO";
const MODAL_SUBSCRIBE_STATUS_SUCCESS = "MODAL_SUBSCRIBE_STATUS_SUCCESS";
const MODAL_SUBSCRIBE_STATUS_ERROR = "MODAL_SUBSCRIBE_STATUS_ERROR";
const MODAL_UPDATE_PAYMENT_METHOD_SUCCESS =
  "MODAL_UPDATE_PAYMENT_METHOD_SUCCESS";
const MODAL_UPDATE_PAYMENT_METHOD_ERROR = "MODAL_UPDATE_PAYMENT_METHOD_ERROR";

class Transaction extends Component {
  state = {
    show: false,
  };

  componentDidMount() {
    const { parentProps } = this.props;

    let urlSearchParams = "";
    if (parentProps && parentProps.location) {
      urlSearchParams = new URLSearchParams(parentProps.location.search);
    }

    this.checkTransactionReturn(urlSearchParams);
  }

  //region Transaction

  setChangeBankInfoAccept() {
    this.showModal(MODAL_INFO, MODAL_UPDATE_PAYMENT_METHOD_SUCCESS);
  }

  setRegularizationAccept() {}

  setPaymentAccept(search) {
    const paymentParameters = {
      ORDERID: search.get("orderID") || search.get("ORDERID"),
    };
    if (search.get("PAYID")) {
      paymentParameters.PAYID = search.get("PAYID");
    }
    this.handleOnExecutePayment(paymentParameters);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8001",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
      })
    );
  }

  setChangeBankInfoDecline() {
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8002",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        failureReason: null,
      })
    );
  }

  setRegularizationDecline() {
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8002",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        failureReason: null,
      })
    );
  }

  setPaymentDecline() {
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8002",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        failureReason: null,
      })
    );
  }

  setChangeBankInfoException() {
    this.showModal(MODAL_INFO, MODAL_UPDATE_PAYMENT_METHOD_ERROR);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8003",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        notSettledForReason: null,
      })
    );
  }

  setRegularizationException() {
    this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_ERROR);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8003",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        notSettledForReason: null,
      })
    );
  }

  setPaymentException() {
    this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_ERROR);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8003",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        notSettledForReason: null,
      })
    );
  }

  setChangeBankInfoCancel() {
    this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_ERROR);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8004",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        notSettledForReason: null,
      })
    );
  }

  setRegularizationCancel() {
    this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_ERROR);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8004",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        notSettledForReason: null,
      })
    );
  }

  setPaymentCancel() {
    this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_ERROR);
    sendAnalytics(
      this.props.dispatch,
      "INFO",
      "8004",
      "PAYMENT",
      JSON.stringify({
        gatewayName: consts.paymentProviders[consts.paymentProvider],
        notSettledForReason: null,
      })
    );
  }

  //end Transaction

  checkTransactionReturn(search) {
    const { parentProps } = this.props;

    if (search && search.has("mv")) {
      switch (search.get("mv")) {
        case "transaction_accept":
          if (search.get("TRANSACTIONTYPE") === "60") {
            this.setChangeBankInfoAccept();
          } else if (search.get("TRANSACTIONTYPE") === "80") {
            this.setRegularizationAccept();
          } else {
            this.setPaymentAccept(search);
          }
          break;
        case "transaction_decline":
          if (search.get("TRANSACTIONTYPE") === "60") {
            this.setChangeBankInfoDecline();
          } else if (search.get("TRANSACTIONTYPE") === "80") {
            this.setRegularizationDecline();
          } else {
            this.setPaymentDecline();
          }
          break;
        case "transaction_exception":
          if (search.get("TRANSACTIONTYPE") === "60") {
            this.setChangeBankInfoException();
          } else if (search.get("TRANSACTIONTYPE") === "80") {
            this.setRegularizationException();
          } else {
            this.setPaymentException();
          }
          break;
        case "transaction_cancel":
          if (search.get("TRANSACTIONTYPE") === "60") {
            this.setChangeBankInfoCancel();
          } else if (search.get("TRANSACTIONTYPE") === "80") {
            this.setRegularizationCancel();
          } else {
            this.setPaymentCancel();
          }
          break;
        default:
          break;
      }

      if (parentProps && parentProps.location) {
        parentProps.history.push(parentProps.location.pathname);
      }
    }
  }

  handleOnExecutePayment(_paymentParameters) {
    /*let paymentData = {
      paymentProvider: consts.paymentProviders[consts.paymentProvider],
      paymentParameters: paymentParameters,
    };*/
    // this.props.dispatch(TucanoActions.callExecutePayment(JSON.stringify(paymentData))).then((result) => {
    //   if (!this.props.parentProps.location.pathname.includes(consts.routes.signup.url)) {
    //     if (
    //       (result && result.status) ||
    //       (result && result.code && result.code === -2112) ||
    //       (result && result.code && result.code === -500)
    //     ) {
    //       if ((result && result.code && result.code === -2112) || (result && result.code && result.code === -500)) {
    //         if (result && result.code && result.code === -500)
    //           this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_SUCCESS);
    //         else this.showModal(MODAL_INFO, MODAL_UPDATE_PAYMENT_METHOD_SUCCESS);
    //       } else {
    //         this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_SUCCESS);
    //       }
    //     } else {
    //       this.showModal(MODAL_INFO, MODAL_SUBSCRIBE_STATUS_ERROR);
    //     }
    //   }
    //   const productId = getFromLocal("productId") || consts.productId;
    //   this.props.dispatch(TucanoActions.getAvailableOffers(consts.languageId));
    //   this.props.dispatch(TucanoActions.getListOptions(consts.languageId));
    //   this.props.dispatch(TucanoActions.getOptionValidity(productId));
    //   this.props.dispatch(TucanoActions.getListInvoices(undefined, undefined, undefined, undefined, consts.languageId));
    //   this.props.dispatch(TucanoActions.getListPaymentMethods(consts.languageId));
    //   this.props.dispatch(TucanoActions.getChannels(undefined, undefined, consts.languageId));
    //   this.props.dispatch(
    //     TucanoActions.getActiveAssets({
    //       languageId: consts.languageId,
    //       allAssets: 0,
    //       commercialModel: "TVOD",
    //       contentTypeFilter: "movie+series",
    //     })
    //   );
    //   this.props.dispatch(
    //     TucanoActions.getActiveAssets({
    //       languageId: consts.languageId,
    //       allAssets: 1,
    //       count: 1000,
    //       watchingMax: 95,
    //       contentTypeFilter: "movie+series",
    //     })
    //   );
    // });
  }

  showModal(modalType, modalSubType) {
    this.setState({
      show: true,
      modalType: modalType,
      modalSubType: modalSubType,
    });
  }

  hideModal(modalType, modalSubType) {
    this.setState({
      show: false,
      modalType: modalType,
      modalSubType: modalSubType,
    });

    if (modalSubType === MODAL_SUBSCRIBE_STATUS_SUCCESS) {
      window.location.reload();
    }
  }

  render() {
    const { show, modalType, modalSubType } = this.state;

    return (
      <>
        {show && modalType === MODAL_INFO && (
          <Modal
            rightContent={{
              canDismiss: this.hideModal.bind(this, MODAL_INFO, modalSubType),
            }}
            rootClassName={style.modal}
            overlay={true}
            buttons={
              <Button
                rootClassName={style.confirmButton}
                type={ButtonType.NORMAL}
                onClick={this.hideModal.bind(this, MODAL_INFO, modalSubType)}
              >
                <Trans>Close</Trans>
              </Button>
            }
          >
            {modalSubType === MODAL_SUBSCRIBE_STATUS_SUCCESS && (
              <>
                <h1 className={style.infoTitle}>
                  <Trans>Awesome</Trans> !
                </h1>

                <span className={style.contentMessage}>
                  <Trans>You can now enjoy all the package content</Trans>
                </span>
              </>
            )}

            {modalSubType === MODAL_SUBSCRIBE_STATUS_ERROR && (
              <>
                <h1 className={style.infoTitle}>
                  <Trans>Something went wrong !</Trans>
                </h1>

                <span className={style.contentMessage}>
                  <Trans>
                    A problem occurred when subscribing,Please try again
                  </Trans>
                </span>
              </>
            )}

            {modalSubType === MODAL_UPDATE_PAYMENT_METHOD_SUCCESS && (
              <>
                <h1 className={style.infoTitle}>
                  <Trans>Congrats</Trans>
                </h1>

                <span className={style.contentMessage}>
                  <Trans>
                    Your payment method has been successfully updated
                  </Trans>
                </span>
              </>
            )}

            {modalSubType === MODAL_UPDATE_PAYMENT_METHOD_ERROR && (
              <>
                <h1 className={style.infoTitle}>
                  <Trans>Something went wrong !</Trans>
                </h1>

                <span className={style.contentMessage}>
                  <Trans>
                    A problem occurred when changing payment method,Please try
                    again
                  </Trans>
                </span>
              </>
            )}
          </Modal>
        )}
      </>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoadingExecutePayment: state.subscription.executePayment.loading,
      dataOnExecutePayment: state.subscription.executePayment.data || undefined,
    };
  }),
  withTranslation()
)(Transaction);
