import InlineSVG from "inline-svg-react";
import React from "react";

const Arrow = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Arrow.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Arrow.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path d="M17.224 11.48l-4.57-4.57.707-.708 5.778 5.778-5.777 5.776-.707-.707 4.57-4.57H4v-1h13.224z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Arrow;
