import classnames from "classnames";
import ArrowHeading from "components/Icons/Arrowheading";
import consts from "consts/consts";
import React, { Component } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";

// Implementation
{
  /* <Dropdown
  // iconOnClick={<Filter />}
  title={"EPG Cat"}
  selfClosing={true}
  label={selectValue.label}
  dropdownIcon={true}
  handleClick={this.onclickHandle}
  subDataHandleClick={this.func}
  removeBackgroundColor={true}
  disableActiveElement={true}
  autoWidth={true}
  data={[
    { label: "Category 2", subData: [ {label:"Test"}, {label:"Hello"}, {label:"Final destination"} ]},
    { label: "Drive", value: 3, icon: <Filter/>},
    { label: "Goodfellas"},
  ]}
/> */
}

export default class Dropdown extends Component {
  state = {
    open: false,
    data: [],
    subTitle: "",
    subData: [],
    isSubCategory: false,
    activeElement: "",
    subActiveElement: "",
  };

  constructor(props) {
    super(props);
    this.wrapperRef = React.createRef();
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  handleClick(data, e) {
    e.preventDefault();
    e.stopPropagation();

    const { isSubCategory } = this.state;
    const { disableActiveElement } = this.props;

    if (data.subData && !isSubCategory) {
      this.setState({
        subTitle: data.label,
        subData: data.subData,
        isSubCategory: true,
      });
    } else if (isSubCategory) {
      this.props.subDataHandleClick(data.label);
      this.setState({
        subActiveElement: !disableActiveElement ? data.label : "",
      });
    } else {
      this.props.handleClick(data);
      this.setState({ activeElement: !disableActiveElement ? data.label : "" });
    }
    if (!this.props.stayOpenOnSelect) {
      this.setState({ open: !this.state.open });
    }
  }

  toggleDropDown(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.props.handleShowSubDropdownLang) {
      this.props.handleShowSubDropdownLang();
    }
    this.setState({ open: !this.state.open });
  }

  previousCategory(e) {
    e.preventDefault();
    e.stopPropagation();
    const { isSubCategory } = this.state;

    if (isSubCategory) {
      this.setState({
        subTitle: "",
        subData: [],
        isSubCategory: false,
      });
    }
  }

  componentDidMount() {
    const { data, label, disableActiveElement } = this.props;
    this.setState({
      data: data,
      activeElement: !disableActiveElement
        ? label
          ? label
          : data[0]?.label
        : "",
    });
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
      this.setState({ open: false });
    }
  }

  // To manage dynamic change of the label
  // (i.e: on page EPG when you click on the Next Day, we should change the label on the dropdown component)
  componentDidUpdate(prevProps, _prevState) {
    const { disableActiveElement, label, searchFilter, searchSort } =
      this.props;

    if (
      (prevProps.label !== label ||
        prevProps.searchFilter !== searchFilter ||
        prevProps.searchSort != searchSort) &&
      !disableActiveElement
    ) {
      this.setState((prevState) => {
        return {
          activeElement: searchFilter ? searchFilter : label,
          subActiveElement: searchSort
            ? searchSort
            : prevState.subActiveElement,
        };
      });
    }
  }

  render() {
    const {
      label,
      dropdownIcon,
      iconOnClick,
      title,
      removeBackgroundColor,
      data,
      addMargin,
      autoWidth,
      showContextNPVR,
      typePage,
    } = this.props;
    const { open, subTitle, subData, activeElement, subActiveElement } =
      this.state;
    let finalData = subData.length > 0 ? subData : data;

    return (
      <fieldset
        className={classnames(
          !label && iconOnClick ? style.fieldsetIcon : null,
          {
            [style.autoWidth]: autoWidth,
            [style.fixedWidth]: !autoWidth,
          }
        )}
        ref={this.wrapperRef}
      >
        <details
          className={classnames(
            !label && iconOnClick ? style.detailsIcon : null
          )}
          open={open}
          onClick={this.toggleDropDown.bind(this)}
        >
          <summary
            className={removeBackgroundColor && style.removeBackgroundColor}
          >
            {label && !iconOnClick && (
              <>
                <span className={style.summaryText}>{label}</span>
                {dropdownIcon && (
                  <ArrowHeading
                    className={open ? style.upArrow : style.downArrow}
                  />
                )}
              </>
            )}
            {label && iconOnClick && (
              <>
                <div className={style.boxLang}>
                  <span
                    className={style.leftIcon}
                    style={{ margin: addMargin ? "8px 8px 0 8px" : "8px" }}
                    onClick={this.toggleDropDown.bind(this)}
                  >
                    <div>{iconOnClick}</div>
                  </span>
                </div>
                <span className={style.summaryText}>{label}</span>
              </>
            )}
            {!label && iconOnClick && (
              <div
                className={classnames(
                  showContextNPVR &&
                    consts.routes.library.url !== location.pathname &&
                    typePage !== "playerLive"
                    ? style.iconNPVR
                    : typePage === "playerLive"
                    ? style.fixShowIconRecodingFROMPLAYER
                    : style.icon
                )}
                onClick={this.toggleDropDown.bind(this)}
              >
                {iconOnClick}
              </div>
            )}
          </summary>
          <div
            className={classnames(
              showContextNPVR && finalData.length > 1
                ? style.showDropdownNPVR
                : finalData.length === 1
                ? style.fixShowDropdownWithSingleLabelNPVR
                : null
            )}
          >
            {subTitle && (
              <>
                <label
                  className={style.category}
                  onClick={this.previousCategory.bind(this)}
                >
                  <span className={style.categoryLabel}>
                    <ArrowHeading className={style.leftArrow} />
                  </span>
                  {/* <span>{subTitle}</span> */}
                  <span className={style.subDataLabel}>{title}</span>
                </label>
                {/* <label className={style.disabled}>
                  <span className={style.subDataLabel}>{subTitle}</span>
                </label> */}
              </>
            )}
            {finalData.map((element, index) => {
              // let titleSpan = (index == 0 && !element.subData) ? <><span>{title}</span> : <></>;
              if (index == 0 && element.subData) {
                return (
                  <React.Fragment key={index}>
                    <label
                      className={style.category}
                      onClick={this.handleClick.bind(this, element)}
                    >
                      <span className={style.subDataLabel}>
                        <Trans>{element.label}</Trans>
                        {subActiveElement ? ": " + subActiveElement : ""}
                      </span>
                      {element.value > 0 && (
                        <span className={style.subDataNbr}>
                          {element.value}
                        </span>
                      )}
                      {element.subData && (
                        <span className={style.categoryLabel}>
                          <ArrowHeading className={style.rightArrow} />
                        </span>
                      )}
                    </label>
                    {/* <label className={style.disabled}>
                      <span>{title}</span>
                    </label> */}
                  </React.Fragment>
                );
              } else {
                return (
                  <label
                    key={index}
                    className={classnames(
                      style.label,
                      element.label === activeElement ||
                        element.label === subActiveElement
                        ? style.active
                        : ""
                    )}
                    onClick={this.handleClick.bind(this, element)}
                  >
                    {element.icon ? (
                      <span className={style.containerLabel}>
                        <span className={style.subDataIcon}>
                          {element.icon}
                        </span>
                        <span className={style.subDataLabel}>
                          <Trans>{element.label}</Trans>
                        </span>
                      </span>
                    ) : (
                      <span className={style.subDataLabel}>
                        <Trans>{element.label}</Trans>
                      </span>
                    )}
                    {element.value > 0 && (
                      <span className={style.subDataNbr}>{element.value}</span>
                    )}
                    {element.subData && (
                      <span className={style.categoryLabel}>
                        <ArrowHeading className={style.rightArrow} />
                      </span>
                    )}
                  </label>
                );
              }
            })}
          </div>
        </details>
      </fieldset>
    );
  }
}
