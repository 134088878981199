import InlineSVG from "inline-svg-react";
import React from "react";

const EyeOpened = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/EyeOpened.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/EyeOpened.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            d="M11.773 5.49c4.232 0 7.138 2.776 9.772 6.118-2.634 3.343-5.54 6.118-9.772 6.118-4.233 0-7.138-2.775-9.773-6.118 2.635-3.342 5.54-6.117 9.773-6.117zm0 1.674a4.445 4.445 0 10.001 8.89 4.445 4.445 0 00-.001-8.89zm0 1.481a2.963 2.963 0 110 5.927 2.963 2.963 0 010-5.927z"
            fill="#8C8C8C"
            fillRule="nonzero"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default EyeOpened;
