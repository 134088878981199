import actionTypes from "consts/actionTypes";

let id = 0;

export function setApplicationReady(status) {
  return {
    payload: status,
    type: actionTypes.SET_APPLICATION_READY,
  };
}

export function setApplicationViewport(viewport) {
  return {
    type: actionTypes.SET_APPLICATION_VIEWPORT,
    payload: viewport,
  };
}

export function setProfileFormVisibility(status) {
  return {
    type: actionTypes.SET_PROFILE_FORM_VISIBILITY,
    payload: status,
  };
}

export function setApplicationPreviousRoute(route) {
  return {
    type: actionTypes.SET_APPLICATION_PREVIOUS_ROUTE,
    payload: route,
  };
}

export function setApplicationPreviousStableRoute(route) {
  return {
    type: actionTypes.SET_APPLICATION_PREVIOUS_STABLE_ROUTE,
    payload: route,
  };
}

export function setEPGDate(date) {
  return {
    type: actionTypes.SET_EPG_DATE,
    payload: date,
  };
}

export function resetEPGDate() {
  return {
    type: actionTypes.RESET_EPG_DATE,
  };
}

export function addToast(options = {}) {
  return {
    payload: {
      ...options,
      id: id++,
    },
    type: actionTypes.ADD_TOAST,
  };
}

export function removeToast(id) {
  return {
    payload: id,
    type: actionTypes.REMOVE_TOAST,
  };
}

export function removeAllToast() {
  return {
    type: actionTypes.REMOVE_ALL_TOAST,
  };
}

export function setMenuOpen(status) {
  return {
    payload: status,
    type: actionTypes.SET_MENU_STATUS,
  };
}
export function setLoginShow(status) {
  return {
    payload: status,
    type: actionTypes.SET_LOGIN_STATUS,
  };
}
export function setErrorShow(status) {
  return {
    payload: status,
    type: actionTypes.SET_ERROR_SHOW,
  };
}
export function setNewPosition(position) {
  return {
    payload: position,
    type: actionTypes.SET_NEW_POSITION,
  };
}
export function setPausePosition(position) {
  return {
    payload: position,
    type: actionTypes.SET_PAUSE_POSITION,
  };
}
export function setLivePosition(position) {
  return {
    payload: position,
    type: actionTypes.SET_LIVE_POSITION,
  };
}
export function setAudioPreference(audio) {
  return {
    payload: audio,
    type: actionTypes.SET_AUDIO_PREFERENCE,
  };
}
export function setSubPreference(sub) {
  return {
    payload: sub,
    type: actionTypes.SET_SUBTITLE_PREFERENCE,
  };
}
export function setChannelFilter(filter, connected) {
  return {
    payload: filter,
    connected: connected,
    type: actionTypes.SET_CHANNEL_FILTER,
  };
}
export function setChannelSort(criteria, connected) {
  return {
    payload: criteria,
    connected: connected,
    type: actionTypes.SET_CHANNEL_SORT,
  };
}
export function setDirectArrayForFavorites(array) {
  return {
    payload: array,
    type: actionTypes.SET_FAVORITES_DIRECT_ARRAY,
  };
}
export function setTerm(term) {
  return {
    payload: term,
    type: actionTypes.SET_SEARCH_TERM,
  };
}

export function resetChannelFilter() {
  return {
    type: actionTypes.RESET_CHANNEL_FILTER,
  };
}

export function ssoCodeRecieved(code) {
  return {
    type: actionTypes.SSO_GRNT_CODE_RECEIVED,
    code,
  };
}

export function setHistoryLink(link) {
  return {
    type: actionTypes.SET_APP_HISTORY_LINK,
    link,
  };
}

export function resetHistoryLink() {
  return {
    type: actionTypes.RESET_APP_HISTORY_LINK,
  };
}

export function setModalNPVRInfo(data) {
  return {
    payload: data,
    type: actionTypes.SET_MODAL_NPVR_INFO,
  };
}

export function setSuspensionShow() {
  return {
    payload: status,
    type: actionTypes.SET_SUSPENSION_ERROR,
  };
}

export function resetSuspensionError() {
  return {
    type: actionTypes.RESET_SUSPENSION_ERROR,
  };
}
