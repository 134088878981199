import { generateImageUrl, generatePosterImageUrl } from "../helpers/imageHelper";
import { formatFullDateAndTime } from "../helpers/timeHelper";

export const AssetDesign = {
  FULLSCREEN: "fullscreen",
  SQUARE: "square",
  SQUARE_FULL: "square_full",
  POSTER: "poster",
  POSTER_LARGE: "poster_large",
  COVER: "cover",
  COVER_FULL: "cover_full",
  CIRCLE: "circle",
  TOTEM: "totem",
  CONTINUE_WATCHING: "continue_watching",
};

export default class EPGEventAsset {
  constructor(item) {
    //console.log("EPGEventAsset input", item);
    this.baseId = item.baseId;
    this.id = item.idItem;
    this.type = item.idType;

    if (item.tags.designMode[0]) {
      this.designMode = item.tags.designMode[0];
    } else {
      this.designMode = AssetDesign.POSTER;
    }

    if (item.content.title) {
      this.title = item.content.title;
    }
    if (item.content.id_channel) {
      this.channelId = item.content.id_channel;
    }
    if (item.content.startutc) {
      this.startTime = item.content.startutc;
    }
    if (item.content.endutc) {
      this.endTime = item.content.endutc;
    }
    if (item.content.duration) {
      this.duration = item.content.duration;
    }
    if (item.content.id_epg) {
      this.idEpg = item.content.id_epg;
    }

    this.epgEventDirectMetadata = {};
    if (item.content.direct_metadata && typeof item.content.direct_metadata === "string") {
      this.epgEventDirectMetadata = JSON.parse(item.content.direct_metadata);
      this.imageId = this.epgEventDirectMetadata.AD_IMAGEID;
    }

    this.subscribed = item.isFta;
    this.fta = item.isSubscribed;
  }

  getId() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getImageId() {
    return this.imageId;
  }

  getChannelId() {
    return this.channelId;
  }

  getEpgId() {
    return this.idEpg;
  }

  getTitle() {
    return this.title;
  }

  getDuration() {
    return this.duration;
  }

  getImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getPosterImage(domain = "/", options) {
    return `${domain}${generatePosterImageUrl(this, options)}`;
  }

  getStartTime() {
    const startTime = new Date(this.startTime * 1000);
    return startTime;
  }

  getBroadcastTime() {
    const startTime = new Date(this.startTime * 1000);
    return formatFullDateAndTime(startTime);
  }

  getEndTime() {
    const endTime = new Date(this.endTime * 1000);
    return endTime;
  }

  isLive() {
    const now = new Date().getTime();
    return this.getStartTime().getTime() < now && now < this.getEndTime().getTime();
  }

  getEpgProgress() {
    const now = new Date().getTime();
    const progress = ((now - this.getStartTime().getTime()) / this.getDuration()) * 100;
    if (progress >= 0 && progress <= 100) {
      return progress;
    } else {
      return undefined;
    }
  }

  getDesignMode() {
    return this.designMode;
  }

  isSubscribed() {
    return this.subscribed;
  }

  isFta() {
    return this.fta;
  }

  setSubscribed(value) {
    this.subscribed = value;
  }

  setFta(value) {
    this.fta = value;
  }

  get userHasAccess() {
    if (this.fta === true) return true;
    if (this.subscribed !== undefined && this.subscribed !== null && this.subscribed !== 0) return true;

    return false;
  }
}
