import consts from "../consts/consts";
import { findObjectInArrayBy } from "../web-api/helpers/dataHelper";

const facebookConf = findObjectInArrayBy(consts.SSOSocials, "name", "facebook");

export function initFacebookSdk() {
  window.fbAsyncInit = function () {
    window?.FB?.init({
      appId: facebookConf?.clientID,
      cookie: true,
      xfbml: true,
      version: "v8.0",
    });
  };
}

export function testAPI() {
  FB?.api("/me", (_response) => {
    // console.log("[FacebookLoginButton] Successful login for: ", response);
  });
}

export function doLogin() {
  return new Promise((resolve, reject) => {
    FB?.login(
      function (response) {
        response.status === "connected" ? resolve(response) : reject(response);
      },
      { scope: facebookConf?.scope, auth_type: "rerequest" }
    );
  });
}
export function logout() {
  return new Promise((resolve, reject) => {
    FB?.logout((response) => {
      response.authResponse ? resolve(response) : reject(response);
    });
  });
}

export function fetchUserInfo() {
  return new Promise((resolve, reject) => {
    FB?.api("/me", { fields: "email, first_name,last_name" }, (response) => {
      if (response.error) {
        reject(response);
      } else {
        resolve(response);
      }
    });
  });
}
