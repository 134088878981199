import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import Loader from "components/Loader";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PagePrivacy extends Component {
  componentDidMount() {
    const { t } = this.props;

    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }

    if (consts.appModules.privacy !== true) {
      this.props.history.replace("/");
    }
    const languageId = getFromLocal("languageId");

    this.props.dispatch(TucanoActions.getFaq(languageId));
  }

  render() {
    const { isLoading, privacy } = this.props;

    return (
      <Fragment>
        <ContentWrapper>
          {isLoading && <Loader centered={true} />}
          {!isLoading && (
            <div className={style.root}>
              {privacy && (
                <div className={style.cguContainer}>
                  <Header rootClassName={style.header}>
                    {privacy.getQuestion()}
                  </Header>
                  <div
                    dangerouslySetInnerHTML={{ __html: privacy.getResponse() }}
                  />
                </div>
              )}
            </div>
          )}
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.reference.faq.loading,
      privacy: TucanoSelectors.getPrivacyContent(state),
    };
  }),
  withTranslation()
)(PagePrivacy);
