import consts from "consts/consts";
import React, { Component, Fragment } from "react";
import { generateFlyImage } from "web-api/helpers/imageHelper";

export default class LogoContent extends Component {
  constructor(props) {
    super();
    this.state = {
      url: generateFlyImage(props.type),
    };
  }
  render() {
    return (
      <Fragment>
        <img
          src={this.state.url}
          className={this.props.className}
          alt={`${consts.name}'s logo`}
        />
      </Fragment>
    );
  }
}
