import InlineSVG from "inline-svg-react";
import React from "react";

const Menu = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Menu.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Menu.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} {...props}>
          <path d="M2.25 6h19.5a.75.75 0 1 1 0 1.5H2.25a.75.75 0 0 1 0-1.5zm0 5.25h19.5a.75.75 0 1 1 0 1.5H2.25a.75.75 0 1 1 0-1.5zm0 5.17h13.5a.75.75 0 1 1 0 1.5H2.25a.75.75 0 1 1 0-1.5z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Menu;
