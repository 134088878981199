import { setProfileFormVisibility } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import Dropdown from "components/Dropdown";
import Grid from "components/Grid";
import About from "components/Icons/About";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import Input, { InputType } from "components/Input";
import consts from "consts/consts";
/*import SelectCustom from "components/SelectCustom";
import BaseSelect from "react-select";*/
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";
import styles from "./style.module.css";

/*const Select = (props) => (
  <SelectCustom {...props} SelectComponent={BaseSelect} />
);*/

class FormProfile extends Component {
  state = {
    data: {
      name: "",
      kidProfile: false,
      maximumMoralityLevel: 6,
      language: getFromLocal("languageId"),
      avatarId: undefined,
      imageId: undefined,
      default: undefined,
      picture: undefined,
    },
    required: {
      name: true,
      kidProfile: false,
      maximumMoralityLevel: false,
    },
    oldData: {},
    parentalControl: false,
    parentalControlDisabled: false,
    edited: false,
    langEdited: false,
    selectAvatar: undefined,
    languageIdMapping: consts.languageIdMapping,
    optionsLanguage: consts.enum.languageId || [],
  };

  componentDidMount() {
    if (this.props.data) {
      this.loadData();
      this.props.dispatch(setProfileFormVisibility(true));
    }
  }

  loadData() {
    const { data } = this.props;

    this.setState((prevState) => ({
      ...prevState,
      data: Object.assign(
        {},
        { ...data, language: data.language || getFromLocal("languageId") }
      ),
      oldData: Object.assign({}, data),
    }));

    if (data.id) {
      this.setState({ selectAvatar: false });
    } else {
      this.setState({ selectAvatar: true });
    }

    if (data.kidProfile === true) {
      this.setState({ parentalControl: true });
      this.setState({ parentalControlDisabled: true });
    } else {
      this.setState({ parentalControl: false });
      this.setState({ parentalControlDisabled: false });
    }
    if (data.maximumMoralityLevel) {
      this.setState({ parentalControl: true });
    } else {
      this.setState({ parentalControl: false });
    }
  }

  handleChange(inputId, value) {
    const newDataState = this.state.data;
    if (newDataState[inputId] !== value) {
      newDataState[inputId] = value;

      this.setState({
        edited: true,
      });
    }

    this.setState((prevState) => ({
      ...prevState,
      data: Object.assign({}, newDataState),
    }));
  }

  handleSubmit() {
    const { activeProfile, data } = this.props;
    if (this.props.onSubmitClick) {
      if (activeProfile && +activeProfile.id === +data.id) {
        saveInLocal("languageId", this.state.data.language);
        this.props.i18n.changeLanguage(
          this.state.languageIdMapping[this.state.data.language]
        );
        this.setState({ langEdited: true });
      }
      this.props.onSubmitClick(this.state.data);
      this.setState((prevState) => ({
        ...prevState,
        edited: false,
      }));
      if (this.state.langEdited) window.location.reload();
    }
  }

  handleDelete() {
    if (this.props.onDeleteClick) {
      this.props.onDeleteClick(this.state.data.id);
    }
  }

  handleCancel() {
    this.loadData();
    this.setState((prevState) => ({
      ...prevState,
      edited: false,
    }));
  }

  fieldValid() {
    if (!this.state.data.name) {
      return false;
    }

    if (
      this.state.parentalControl === true &&
      (this.state.data.maximumMoralityLevel === "" ||
        this.state.data.maximumMoralityLevel <= 0 ||
        this.state.data.maximumMoralityLevel > 21)
    ) {
      return false;
    }

    return true;
  }

  selectAvatar(imageId, avatarId) {
    this.handleChange("imageId", imageId);
    this.handleChange("avatarId", avatarId);
    this.setState({ selectAvatar: false });
  }

  showAvatar() {
    this.setState({ selectAvatar: true });
  }

  componentWillUnmount() {
    this.props.dispatch(setProfileFormVisibility(false));
  }

  render() {
    const sizeImg = 80;
    const { t, avatars } = this.props;
    const { optionsLanguage, data } = this.state;
    const translatedLangOpts = optionsLanguage.reduce((initArray, current) => {
      initArray.push({ value: current.value, label: t(current.label) });
      return initArray;
    }, []);
    const selectedLang =
      Object.values(translatedLangOpts).filter((item) => {
        return item.value === data.language;
      })[0]?.label || translatedLangOpts[0]?.label;
    return (
      <div className={style.root} id="formProfile">
        {this.state.selectAvatar === true && avatars && (
          <div className={style.formGrid}>
            <Grid rootClassName={style.grid}>
              {Object.values(avatars).map((img, index) => {
                return (
                  <div className={style.avatar} key={index}>
                    <Image
                      src={img.getPosterImage(consts.image_hostname, {
                        width: 80,
                        height: 80,
                        type: "profile",
                        languageId: getFromLocal("languageId"),
                      })}
                      onClick={this.selectAvatar.bind(
                        this,
                        img.imageId,
                        img.id
                      )}
                      alt={img.name}
                      key={img.imageId}
                      width={sizeImg}
                      height={sizeImg}
                      placeholder={PlaceholderType.PROFILE}
                      ratio={ImageRatio.CIRCLE}
                    />
                  </div>
                );
              })}
            </Grid>
          </div>
        )}
        {(this.state.selectAvatar === false || !avatars) && (
          <div className={style.form}>
            <div
              className={classnames(style.headerProfil, {
                [style.headerProfilEdit]: this.state.data.id,
              })}
              onClick={this.showAvatar.bind(this)}
            >
              <div className={style.profilImageContainer}>
                {this.state.data.imageId === null ? (
                  <div className={style.profilImage} />
                ) : (
                  <Image
                    width={sizeImg}
                    height={sizeImg}
                    src={`${consts.image_hostname}${
                      routes.imgdata
                    }?type=profile&objectId=${
                      this.state.data.imageId
                    }&format_w=${80}&format_h=${80}&languageId=${getFromLocal(
                      "languageId"
                    )}`}
                    ratio={ImageRatio.CIRCLE}
                    placeholder={PlaceholderType.PROFILE}
                  />
                )}
                {this.state.data.id && this.state.data.default === true && (
                  <div className={styles.mainProfile}>
                    <span>
                      <Trans>Main</Trans>
                    </span>
                  </div>
                )}
              </div>
              {this.state.data.id && (
                <div>
                  <label className={style.label}>
                    <Trans>Profile</Trans>
                  </label>
                  <div className={style.username}>{this.state.data.name}</div>
                </div>
              )}
            </div>
            <div
              className={classnames(style.section, {
                [style.sectionUsername]: this.state.data.id,
              })}
            >
              <label className={style.label}>
                <Trans>UsernameProfile</Trans>
              </label>
              <Input
                rootClassName={style.value}
                type={InputType.TEXT}
                onChange={(value) => {
                  this.handleChange("name", value);
                }}
                value={this.state.data.name}
                autoComplete={"username"}
                required={this.state.required.name}
              />
            </div>
            <span className={style.label}>
              <Trans>Parental control</Trans>
            </span>
            <div className={classnames(style.section, style.sectionSwitch)}>
              <Checkbox
                onChange={(value) => {
                  this.handleChange("kidProfile", value);
                  if (value === true) {
                    this.setState({
                      parentalControl: true,
                      parentalControlDisabled: true,
                    });
                    this.handleChange(
                      "maximumMoralityLevel",
                      this.state?.data?.maximumMoralityLevel || 6
                    );
                  } else {
                    this.setState({ parentalControlDisabled: false });
                  }
                }}
                checked={this.state.data.kidProfile}
                type={"SWITCH"}
                label={<Trans>Kid profile</Trans>}
                rootClassName={style.switch}
                required={this.state.required.kidProfile}
              />
              <Checkbox
                onChange={(value) => {
                  this.setState({ parentalControl: value });
                  if (value === true) {
                    this.handleChange(
                      "maximumMoralityLevel",
                      this.state?.data?.maximumMoralityLevel || 6
                    );
                  } else {
                    this.handleChange("maximumMoralityLevel", 0);
                  }
                }}
                checked={this.state.parentalControl}
                disabled={this.state.parentalControlDisabled}
                type={"SWITCH"}
                label={<Trans>Parental control</Trans>}
                rootClassName={style.switch}
              />
              {this.state.parentalControl === true && (
                <Fragment>
                  <div className={style.moralityLevel}>
                    <label className={style.moralityLevelLabel}>
                      <Trans>Morality level</Trans>
                    </label>
                    <Input
                      rootClassName={style.inputNumber}
                      type={InputType.NUMBER}
                      onChange={(value) => {
                        this.handleChange("maximumMoralityLevel", value);
                      }}
                      value={this.state.data.maximumMoralityLevel}
                      required={this.state.required.maximumMoralityLevel}
                      min={1}
                      max={21}
                    />
                  </div>
                  {(!this.state.data.maximumMoralityLevel ||
                    this.state.data.maximumMoralityLevel <= 0 ||
                    this.state.data.maximumMoralityLevel > 21) && (
                    <div className={style.error}>
                      <Trans>The morality level must be between 1 and 21</Trans>
                    </div>
                  )}
                </Fragment>
              )}
            </div>
            <div className={style.section}>
              <label className={style.label}>
                <Trans>Preferred language</Trans>{" "}
              </label>
              <div className={style.languages}>
                <Dropdown
                  selfClosing={true}
                  removeBackgroundColor={true}
                  label={selectedLang}
                  dropdownIcon={true}
                  handleClick={(lang) =>
                    this.handleChange("language", lang.value)
                  }
                  data={translatedLangOpts}
                />
              </div>
            </div>
            <div className={style.section}>
              <table className={style.about}>
                <tbody>
                  <tr>
                    <td className={style.sized1}>
                      <About className={style.aboutIcon} />
                    </td>
                    <td className={style.sized2}>
                      <div className={style.rectangle}></div>
                    </td>
                    <td className={style.sized3}>
                      <Trans>
                        You can change the parental control in your account
                        space
                      </Trans>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            {!this.state.data.id && (
              <Button
                rootClassName={style.addProfileButton}
                type={
                  this.fieldValid() ? ButtonType.NORMAL : ButtonType.DISABLED
                }
                onClick={this.handleSubmit.bind(this)}
              >
                <Trans>Add this profile</Trans>
              </Button>
            )}
            {this.state.data.id && this.state.data.default !== true && (
              <div className={style.buttonContainer}>
                <Button
                  rootClassName={style.deleteButton}
                  type={ButtonType.BORDERED}
                  onClick={this.handleDelete.bind(this)}
                >
                  <Trans>Delete profile</Trans>
                </Button>
              </div>
            )}
            {this.state.data.id && this.state.edited === true && (
              <div className={style.fixedButton}>
                <Button
                  rootClassName={style.cancelButton}
                  type={ButtonType.GHOST}
                  onClick={this.handleCancel.bind(this)}
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  rootClassName={style.submitButton}
                  type={ButtonType.TEXT}
                  onClick={this.handleSubmit.bind(this)}
                >
                  <Trans>Save change</Trans>
                </Button>
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

FormProfile.defaultProps = {
  data: {},
  required: {
    name: true,
    kidProfile: false,
    maximumMoralityLevel: false,
  },
};

FormProfile.propTypes = {
  data: PropTypes.object,
  required: PropTypes.object,
};
export default compose(
  connect((state) => {
    return {
      isProfileFormExist: state.ui.isProfileFormExist,
    };
  }),
  withTranslation()
)(FormProfile);
