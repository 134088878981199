import { TucanoActionTypes } from "web-api/main";

export default Object.assign(TucanoActionTypes, {
  SET_APPLICATION_READY: "SET_APPLICATION_READY",
  SET_APPLICATION_VIEWPORT: "SET_APPLICATION_VIEWPORT",
  SET_PROFILE_FORM_VISIBILITY: "SET_PROFILE_FORM_VISIBILITY",
  SET_EPG_DATE: "SET_EPG_DATE",
  RESET_EPG_DATE: "RESET_EPG_DATE",
  SET_MENU_STATUS: "SET_MENU_STATUS",
  SET_LOGIN_STATUS: "SET_LOGIN_STATUS",
  SET_ERROR_SHOW: "SET_ERROR_SHOW",
  SET_NEW_POSITION: "SET_NEW_POSITION",
  SET_PAUSE_POSITION: "SET_PAUSE_POSITION",
  SET_LIVE_POSITION: "SET_LIVE_POSITION",
  SET_AUDIO_PREFERENCE: "SET_AUDIO_PREFERENCE",
  SET_SUBTITLE_PREFERENCE: "SET_SUBTITLE_PREFERENCE",
  SET_CHANNEL_FILTER: "SET_CHANNEL_FILTER",
  SET_CHANNEL_SORT: "SET_CHANNEL_SORT",
  SET_SEARCH_TERM: "SET_SEARCH_TERM",
  SET_FAVORITES_DIRECT_ARRAY: "SET_FAVORITES_DIRECT_ARRAY",
  SET_APPLICATION_PREVIOUS_ROUTE: "SET_APPLICATION_PREVIOUS_ROUTE",
  SET_APPLICATION_PREVIOUS_STABLE_ROUTE:
    "SET_APPLICATION_PREVIOUS_STABLE_ROUTE",
  RESET_CHANNEL_FILTER: "RESET_CHANNEL_FILTER",

  //NPVR
  SET_MODAL_NPVR_INFO: "SET_MODAL_NPVR_INFO",

  // SESSION
  SET_AUTH_TOKENS: "SET_AUTH_TOKENS",
  SET_CUSTOMER_AUTH_TOKEN: "SET_CUSTOMER_AUTH_TOKEN",
  SET_DEVICE_AUTH_TOKEN: "SET_DEVICE_AUTH_TOKEN",
  SET_DEVICE_LIMIT_ERROR: "SET_DEVICE_LIMIT_ERROR",
  SET_PROFILE_TOKEN: "SET_PROFILE_TOKEN",
  SET_DISCOVER_MODE: "SET_DISCOVER_MODE",

  // TOAST
  ADD_TOAST: "ADD_TOAST",
  REMOVE_TOAST: "REMOVE_TOAST",
  REMOVE_ALL_TOAST: "REMOVE_ALL_TOAST",
  SET_SUSPENSION_ERROR: "SET_SUSPENSION_ERROR",
  RESET_SUSPENSION_ERROR: "RESET_SUSPENSION_ERROR",
});
