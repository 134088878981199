import InlineSVG from "inline-svg-react";
import React from "react";

const Home = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Home.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Home.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M10.922 0a.91.91 0 0 0-.686.31L.229 11.688a.914.914 0 0 0 .688 1.518h2.87v6.76c0 1.15.938 2.09 2.088 2.09h3.85v-4.51a.92.92 0 0 1 .918-.916.92.92 0 0 1 .916.917v4.51h3.889c1.148 0 2.089-.94 2.089-2.09v-6.761h3.39a.914.914 0 0 0 .688-1.518L11.609.31A.91.91 0 0 0 10.922 0m0 2.306l7.974 9.065h-3.193v8.594c0 .137-.12.258-.255.258h-2.055v-2.677a2.752 2.752 0 0 0-2.75-2.75 2.753 2.753 0 0 0-2.75 2.75v2.677H5.875a.263.263 0 0 1-.255-.257V11.37H2.948l7.974-9.065" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default Home;
