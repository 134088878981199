import { createSelector } from "reselect";

import Avatar from "../models/Avatar";
import Device from "../models/Device";
import Invoice from "../models/Invoice";
import Profile from "../models/Profile";
import User from "../models/User";

const selectDevices = (state) => {
  if (!state.account.devices.data) {
    return undefined;
  }
  return state.account.devices.data;
};

export const getDevices = createSelector(selectDevices, (data) => {
  if (data) {
    return data
      .sort((itemA, itemB) => {
        return itemB.lastLoginDate - itemA.lastLoginDate;
      })
      .map((item) => {
        return new Device(item);
      });
  }
});

const selectDeviceCounter = (state) => {
  if (!state.account.devices.count) {
    return undefined;
  }
  return state.account.devices.count;
};

export const getDeviceCounter = createSelector(selectDeviceCounter, (data) => {
  return data;
});

const selectParentalCode = (state) => {
  if (!state.account.parentalCode.data) {
    return undefined;
  }
  return state.account.parentalCode.data;
};

export const getParentalCode = createSelector(selectParentalCode, (data) => {
  if (data) {
    return data;
  }
});

const selectUserInfo = (state) => {
  if (!state.account.user.data) {
    return undefined;
  }
  return state.account.user.data;
};

export const getUserInfo = createSelector(selectUserInfo, (data) => {
  if (data) {
    return new User(data);
  }
});

const selectInvoices = (state) => {
  if (!state.account.invoices.data) {
    return undefined;
  }
  return state.account.invoices.data;
};

export const getListInvoices = createSelector(selectInvoices, (data) => {
  if (!data || !Array.isArray(data)) {
    return undefined;
  }

  const options = data.map((option) => {
    return new Invoice(option, "listInvoices");
  });

  return options;
});

const selectProfile = (state) => {
  if (!state.account.profile.data) {
    return undefined;
  }
  return state.account.profile.data;
};

export const getProfile = createSelector(selectProfile, (data) => {
  if (data) {
    return data.map((item) => {
      return new Profile(item);
    });
  }
});
const selectAvatars = (state) => {
  if (!state.account.avatars.data) {
    return undefined;
  }
  return state.account.avatars.data;
};
export const getAvatars = createSelector(selectAvatars, (data) => {
  if (data) {
    return data.map((item) => {
      return new Avatar(item);
    });
  }
});

const selectActiveProfile = (state) => {
  if (!state.account.getActiveProfile.data) {
    return undefined;
  }
  return state.account.getActiveProfile.data;
};

export const getActiveProfile = createSelector(selectActiveProfile, (data) => {
  if (data) {
    return new Profile(data);
  }
});

const passwordPolicy = (state) => {
  if (!state.account.getPasswordPolicy.data || typeof state.account.getPasswordPolicy.data === "undefined") {
    return {};
  }
  return state.account.getPasswordPolicy.data;
};

const PolicyMessage = {
  minimumCharacters: "Minimum {{value}} characters",
  upperCaseEnabled: "At least one upper case",
  specialCharacterEnabled: "Should contains special characters [[:value]]",
  numbersEnabled: "At least one number (0-9)",
};

export const getPasswordPolicy = createSelector(passwordPolicy, (data) => {
  if (data) {
    let policies = [];
    for (var i = 0; i < Object.keys(data).length; i++) {
      let name = Object.keys(data)[i];
      let policy = { policy: name };
      policy.value = Object.values(data)[i];
      if (name === "specialCharacterEnabled" && policy.value) {
        policy.value = data.specialCharacters;
      }
      policy.message =
        PolicyMessage.hasOwnProperty(name) && typeof policy.value !== Boolean
          ? // PolicyMessage[name]?.replace("[:value]", policy.value)
          PolicyMessage[name]
          : "";
      policies.push(policy);
    }
    return policies;
  }
});
