export default class Profile {
  constructor(item) {
    //console.log("Profile input", item);
    // Profile
    this.id = item.id;
    this.name = item.name;
    this.language = item.language;
    this.picture = item.picture;
    this.avatarId = item.avatarId;
    this.maximumMoralityLevel = item.maximumMoralityLevel;
    this.kidProfile = item.kidProfile;
    this.password = item.password;
    this.default = item.default;
  }

  getId() {
    return this.id;
  }

  getName() {
    return this.name;
  }

  getLanguage() {
    return this.language;
  }

  getPicture() {
    return this.picture;
  }

  getAvatarId() {
    return this.avatarId;
  }

  getMaximumMoralityLevel() {
    return this.maximumMoralityLevel;
  }

  getKidProfile() {
    return this.kidProfile;
  }

  getPassword() {
    return this.password;
  }

  isDefault() {
    return this.default;
  }
}
