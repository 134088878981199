import moment from "moment";

export const STRATE_LAYOUT_GRID = "grid";
export const STRATE_LAYOUT_LINE = "line";

export default class Strate {
  constructor(data = {}, layout) {
    let item = data;
    if (data.content) {
      item = data.content;
    }
    this.layout = layout || STRATE_LAYOUT_LINE;
    this.id = item.idItem || item.idAsset || item.idCatalog || "";
    this.type = item.idType || "";
    if (item.description) {
      this.title = item.description;
    } else if (item.name) {
      this.title = item.name;
    } else if (item.title) {
      this.title = item.title;
    }
    if (item.url) {
      this.url = item.url;
    } else {
      this.url = item?.idItem;
    }
    if (item.catchupDate && !Number.isNaN(Number(item.title))) {
      //NOTE: this is to convert the titles in timestamp to DD/MM/YYYY in order to use it in CatchupDetail Page
      this.title = moment(item.title * 1000).format("DD/MM/YYYY");
    }
    this.items = [];
  }

  setTitle(title) {
    this.title = title;
  }

  pushItem(newItem) {
    this.items.push(newItem);
  }

  getLayout() {
    return this.layout;
  }

  getId() {
    return this.id;
  }

  getType() {
    return this.type;
  }

  getTitle() {
    return this.title;
  }

  getUrl(baseUrl) {
    if (this.url) {
      if (baseUrl && baseUrl !== "/") {
        return baseUrl + "/" + this.url;
      } else {
        return this.url;
      }
    } else {
      return baseUrl + "/" + this.id;
    }
  }

  setItems(items) {
    this.items = items;
  }

  getItems() {
    return this.items;
  }
}
