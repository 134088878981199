import InlineSVG from "inline-svg-react";
import React from "react";

const ContinueWatching = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/ContinueWatching.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/ContinueWatching.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M12 1C5.925 1 1 5.924 1 12c0 6.075 4.925 11 11 11s11-4.925 11-11c0-6.076-4.925-11-11-11m0 1.833c5.055 0 9.167 4.112 9.167 9.167S17.054 21.167 12 21.167c-5.054 0-9.167-4.113-9.167-9.167 0-5.055 4.112-9.167 9.167-9.167zm-1.833 12.824l6.431-4.12-6.431-4.12v8.24z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default ContinueWatching;
