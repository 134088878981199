import consts from "consts/consts";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import style from "./style.module.css";

class Signup extends Component {
  state = {
    show: false,
  };

  hideModal() {
    this.setState({ show: false });
  }

  showModal() {
    this.setState({ show: true });
  }

  render() {
    // const { show } = this.state;
    const { t, Translate } = this.props;

    return (
      <div className={style.signUpButton}>
        <span className={style.text}>
          <Translate t={t}>New to beemix</Translate> ?
        </span>
        {consts.externalSignupLink ? (
          <a
            className={style.link}
            target="_blank"
            rel="noopener noreferrer"
            href={consts.externalSignupLink}
          >
            <Translate t={t}>Sign up</Translate>
          </a>
        ) : (
          <Link to={consts.routes.signup.url} className={style.link}>
            <Translate t={t}>Sign up</Translate>
          </Link>
        )}

        {/* { !consts.signupWithCredentialsAsSingleButton &&
          <Link to={consts.routes.signup.url} className={style.link}>
            <Trans>Sign up</Trans>
          </Link>
        } */}
        {/* { consts.signupWithCredentialsAsSingleButton && !show ?
          <a onClick={this.showModal.bind(this)} className={style.link}>
            <Trans>Sign up</Trans>
          </a>
          :
          <Modal
            size={"lg"}
            rootClassName={style.modal}
            leftContent={{ logo: true }}
            rightContent={{ canDismiss: this.hideModal.bind(this) }}
            overlay={true}
          >
            <PageSignUp isPopup={true} />
          </Modal>
        } */}
      </div>
    );
  }
}

export default withTranslation()(Signup);
