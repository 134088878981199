import { TucanoActions } from "web-api/main";

import consts from "../consts/consts";
import { getFromLocal, saveInLocal } from "../helpers/localStorage";
import { loginWithApple } from "./Apple";
import { doLogin } from "./Facebook";
import { loginWithGoogle } from "./Google";

async function handleBackendValidation(dispatch, backendResult) {
  dispatch(TucanoActions.setAuthToken(backendResult.newAuthToken));
  saveInLocal("authMode", "ssosocialauth");
  let updatedLocalStorage = getFromLocal(consts.storageKeys.tokens);
  if (!updatedLocalStorage) {
    updatedLocalStorage = {};
  }
  updatedLocalStorage = {};
  updatedLocalStorage.customerAuthToken = backendResult.newAuthToken;

  saveInLocal(consts.storageKeys.tokens, updatedLocalStorage);
  await dispatch(
    TucanoActions.getActiveProducts(backendResult.newAuthToken)
  ).then(async (data) => {
    if (data && data.productIds && data.productIds.length > 0) {
      saveInLocal("productId", data.productIds[0]);
    }
  });
  if (!consts?.appModules?.profiles) window.location.reload();
}

export function authWithFacebook(dispatch) {
  doLogin()
    .then((result) => {
      dispatch(TucanoActions.authenticateByFacebook(result)).then((data) => {
        handleBackendValidation(dispatch, data);
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
}

export function authWithGoogle(dispatch) {
  loginWithGoogle()
    .then((result) => {
      dispatch(TucanoActions.authenticateByGoogle({ code: result })).then(
        (data) => {
          handleBackendValidation(dispatch, data);
        }
      );
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error("authWithGoogle error", error);
    });
}

export function authWithApple(dispatch) {
  loginWithApple()
    .then((result) => {
      dispatch(TucanoActions.authenticateByApple(result)).then((data) => {
        handleBackendValidation(dispatch, data);
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.error(error);
    });
}
