import InlineSVG from "inline-svg-react";
import React from "react";

const Arrowheading = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Arrowheading.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Arrowheading.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 15 20" {...props}>
          <path d="M 5,5 10,10 5,15 " fill="none" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default Arrowheading;
