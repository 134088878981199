import actionTypes from "consts/actionTypes";

const initialState = {
  customerAuthToken: undefined,
  deviceAuthToken: undefined,
  deviceLimitError: false,
  profileToken: undefined,
  discoverMode: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_TOKENS:
      return {
        ...state,
        customerAuthToken: action.payload.customerAuthToken,
        deviceAuthToken: action.payload.deviceAuthToken,
        profileToken: action.payload.profileToken,
      };
    case actionTypes.SET_CUSTOMER_AUTH_TOKEN:
      return {
        ...state,
        customerAuthToken: action.payload,
      };
    case actionTypes.SET_DEVICE_AUTH_TOKEN:
      return {
        ...state,
        deviceAuthToken: action.payload,
      };
    case actionTypes.SET_DEVICE_LIMIT_ERROR:
      return {
        ...state,
        deviceLimitError: action.payload,
      };
    case actionTypes.SET_PROFILE_TOKEN:
      return {
        ...state,
        profileToken: action.payload,
      };
    case actionTypes.SET_DISCOVER_MODE:
      return {
        ...state,
        discoverMode: action.payload,
      };
    default:
      return state;
  }
};
