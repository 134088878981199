import classnames from "classnames";
import Pipe from "components/Icons/Pipe";
import ProgressBar from "components/ProgressBar";
import consts from "consts/consts";
import { format } from "date-fns";
import moment from "moment";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { TucanoModels } from "web-api/main";

import style from "./style.module.css";

class AssetCircleContext extends Component {
  state = {
    metaItem: null,
  };

  async componentDidMount() {
    if (consts.displayVodData) {
      const { model } = this.props;
      let metadata;
      if (model instanceof TucanoModels.Asset && model.getVodData()) {
        metadata = model.getVodData();
      }
      await this.setState({ metaItem: metadata });
    }
  }
  getModelType() {
    const { model, t } = this.props;

    if (model instanceof TucanoModels.Asset && model.isCatchupAsset) {
      return t("Catch-up");
    }

    if (model.isAssetTVShow || model.isAssetTVShowEpisode) {
      return t("Series");
    }

    if (
      model.getType() === "link" ||
      model.getType() === "EPGBroadcast" ||
      this.isChannel()
    ) {
      return "";
    }

    if (
      model.isCategory === true &&
      model.isSerie === false &&
      model.isAssetTVShow === false
    ) {
      return "";
    }

    return t("Movie");
  }

  getModelDuration() {
    const { model, t } = this.props;

    if (model.isAssetTVShowEpisode) {
      return (
        t("S") + model.seasonNumber + " - " + t("EP") + model.episodeNumber
      );
    }

    if (model.isAssetTVShow) {
      return t("Season", { count: model.seasonCount });
    }

    return model.getHumanDuration();
  }

  isChannel() {
    const { model } = this.props;
    if (model instanceof TucanoModels.Channel) {
      return model.constructor === TucanoModels.Channel;
    }
  }

  render() {
    const { model, rootClassName, t } = this.props;
    let type;

    if (model.getType() === "link") {
      type = "link";
    }

    if (model.getType() === "EPGBroadcast") {
      type = "epgEvent";
    }

    if (model.getType() === "asset_vod-poster") {
      type = "movie";
    }
    if (
      model.isCategory === true &&
      model.isSerie === false &&
      model.isAssetTVShow === false
    ) {
      type = "category";
    }
    if (type === "link") {
      return null;
    }
    return (
      <div className={classnames(style.root, rootClassName)}>
        {type !== "link" && (
          <>
            <div className={style.title}>
              {this.isChannel() ? model.getName() : model.getTitle()}
            </div>
            {type !== "category" && (
              <>
                {model instanceof TucanoModels.EPGEventAsset && (
                  <>
                    {model.isLive() ? (
                      <div className={style.subtitle}>
                        {format(model.getStartTime(), "HH:mm")} -{" "}
                        {format(model.getEndTime(), "HH:mm")}
                      </div>
                    ) : (
                      <div className={style.subtitle}>
                        {model.getBroadcastTime()}
                      </div>
                    )}
                  </>
                )}
                {model instanceof TucanoModels.Asset && !model.isCatchupAsset && (
                  <div className={style.subtitle}>
                    {t(this.getModelType())}
                    <Pipe className={style.pipeIcon} />
                    {t(this.getModelDuration())}
                  </div>
                )}
                {model instanceof TucanoModels.Asset && model.isCatchupAsset && (
                  <div className={style.subtitle}>
                    {t(this.getModelType())}
                    <Pipe className={style.pipeIcon} />
                    {`${t(
                      moment(model.getValidityStartDate(), "DD/MM/YYYY").format(
                        "DD MMM"
                      )
                    )},
                    ${moment(
                      model.getStartTime(),
                      "YYYY-MM-DD hh:mm:ss"
                    ).format("hh:mm")}`}
                  </div>
                )}
                {model instanceof TucanoModels.Asset &&
                  !!model.getSeekTime() && (
                    <ProgressBar
                      position={model.getProgress()}
                      rootClassName={style.progressBar}
                    />
                  )}
                {model instanceof TucanoModels.EPGEventAsset &&
                  model.isLive() &&
                  model.getEpgProgress() && (
                    <ProgressBar
                      position={model.getEpgProgress()}
                      rootClassName={style.progressBar}
                    />
                  )}
              </>
            )}
            {type === "category" && model.getAbsoluteAssetNumber() && (
              <div className={style.subtitle}>
                {model.getAbsoluteAssetNumber()} <Trans>videos</Trans>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(AssetCircleContext);
