import InlineSVG from "inline-svg-react";
import React from "react";

const StarOutline = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/StarOutline.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/StarOutline.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M9.245 8.703l-6.172.862 4.439 4.375-1.088 6.136 5.533-2.87 5.5 2.931-1.02-6.149 4.487-4.325-6.163-.93-2.726-5.603-2.79 5.573zm-.659-.918L12.047.87l3.383 6.953 7.646 1.154-5.566 5.366 1.265 7.63-6.824-3.636-6.865 3.56 1.35-7.614-5.51-5.428 7.66-1.07z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default StarOutline;
