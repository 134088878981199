import { generateImageUrl, generatePosterImageUrl } from "../helpers/imageHelper";

export default class Season {
  constructor(item) {
    this.name = item.name;
    this.id = item.idCatalog;
    this.synopsis = item.synopsis;
    this.seasonNumber = item.order;
    this.assets = [];
  }

  getName() {
    return this.name;
  }

  getId() {
    return this.id;
  }

  getSynopsis() {
    return this.synopsis;
  }

  addAsset(asset) {
    this.assets.push(asset);
  }

  getAssets() {
    return this.assets;
  }

  getImage(domain = "/", options) {
    return `${domain}${generateImageUrl(this, options)}`;
  }

  getPosterImage(domain = "/", options) {
    return `${domain}${generatePosterImageUrl(this, options)}`;
  }
}
