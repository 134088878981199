import InlineSVG from "inline-svg-react";
import React from "react";

const FavoritesFilled = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/FavoritesFilled.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/FavoritesFilled.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 30 30" {...props}>
          <path
            d="M23.6,0c-3.4,0-6.3,2.7-7.6,5.6C14.7,2.7,11.8,0,8.4,0C3.8,0,0,3.8,0,8.4c0,9.4,9.5,11.9,16,21.2
            c6.1-9.3,16-12.1,16-21.2C32,3.8,28.2,0,23.6,0z"
          />{" "}
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default FavoritesFilled;
