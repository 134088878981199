import InlineSVG from "inline-svg-react";
import React from "react";

const Pipe = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Pipe.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Pipe.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 14.707 14.707" {...props}>
          <rect x="6.275" y="0" width="2.158" height="14.707" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Pipe;
