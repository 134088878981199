import { createSelector } from "reselect";

const selectPlayerInfos = (state) => {
  if (!state.player || !state.player.data) {
    return {};
  }
  return state.player.data;
};

export const getPlayerInfos = createSelector(selectPlayerInfos, (playerInfos) => {
  if (!playerInfos) {
    return undefined;
  }
  return playerInfos;
});

export const getIsTrailerStarted = createSelector(selectPlayerInfos, (playerInfos) => {
  if (!playerInfos) {
    return false;
  }
  return playerInfos.isTrailerStarted;
});
