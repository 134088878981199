import InlineSVG from "inline-svg-react";
import React from "react";

const Epg = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Epg.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Epg.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path
            style={{ fillRule: "evenodd" }}
            d="M22.29 10.824v7.5c0 .969-.79 1.753-1.765 1.753H3.765A1.758 1.758 0 0 1 2 18.325V7.8c0-1.088.79-1.969 1.765-1.969H5.53v1.192c0 1.242.987 2.25 2.205 2.25S9.94 8.265 9.94 7.023V5.83h4.412v1.192c0 1.242.987 2.25 2.205 2.25 1.217 0 2.204-1.008 2.204-2.25V5.83h1.764c.975 0 1.765.88 1.765 1.969v3.024zm-5.16-1.515v1.827c1.523 0 2.589.001 3.334.003v7.112H3.827v-7.095c2.97-.007 8.951-.02 13.303-.02V9.309zM7.71 3h.006c.445 0 .806.36.806.803v2.82c0 .443-.36.803-.806.803H7.71a.804.804 0 0 1-.806-.803v-2.82c0-.443.36-.803.806-.803zm8.861 0h.006c.445 0 .806.36.806.803v2.82c0 .443-.36.803-.806.803h-.006a.804.804 0 0 1-.806-.803v-2.82c0-.443.36-.803.806-.803z"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Epg;
