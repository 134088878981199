import InlineSVG from "inline-svg-react";
import React from "react";

const Recorded = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Recorded.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Recorded.svg");
    } catch (err) {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g fill="none" fillRule="evenodd">
            <g fillRule="nonzero">
              <path
                d="M15.25 11.5v1.687c0 .437-.22.75-.412.75h-6.3c-.194 0-.413-.313-.413-.75V11.5h-.75v1.687c0 .806.486 1.5 1.162 1.5h6.3c.677 0 1.163-.694 1.163-1.5V11.5h-.75z"
                fill="#FFF"
              />
              <path
                fill="#67C401"
                d="m10.266 9.517-.532.528 2.074 2.091 2.278-4.594-.672-.334-1.812 3.656z"
              />
              <path
                d="M11.667 21.333c5.338 0 9.666-4.328 9.666-9.666 0-5.34-4.328-9.667-9.666-9.667C6.327 2 2 6.328 2 11.667c0 5.338 4.328 9.666 9.667 9.666zm0-1.333a8.333 8.333 0 1 1 0-16.667 8.333 8.333 0 0 1 0 16.667z"
                fill="#FFF"
              />
            </g>
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Recorded;
