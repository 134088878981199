import InlineSVG from "inline-svg-react";
import React from "react";

const PlayerOpenFullscreen = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/PlayerOpenFullscreen.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/PlayerOpenFullscreen.svg");
    } catch (err) {
      return (
        <svg width="24" height="24" {...props}>
          <path d="M2.95 9.657H1V4.063h7.594v1.95H2.95v3.644zm18.1 4.686H23v5.595h-7.594v-1.95h5.644v-3.645zM15.343 5.95V4h7.594v5.594h-1.95V5.95h-5.644zm-6.686 12.1V20H1.062v-5.594h1.95v3.644h5.645z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default PlayerOpenFullscreen;
