import Accordion from "components/Accordion";
import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import Loader from "components/Loader";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageFAQ extends Component {
  componentDidMount() {
    const { t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }

    if (consts.appModules.faq !== true) {
      this.props.history.replace("/");
    }
    const languageId = getFromLocal("languageId");
    this.props.dispatch(TucanoActions.getFaq(languageId));
  }

  render() {
    const { faqs, isLoading } = this.props;
    return (
      <Fragment>
        <ContentWrapper>
          {isLoading && <Loader centered={true} />}
          {!isLoading && (
            <div className={style.root}>
              <Header rootClassName={style.header}>
                <Trans>{this.props.route.title}</Trans>
              </Header>
              {faqs &&
                faqs.map((faq, index) => (
                  <div key={index} className={style.faqContainer}>
                    <h3 className={style.categoryTitle}>{faq.getName()}</h3>
                    {faq.getResponse() && (
                      <Accordion
                        title={
                          <p className={style.question}>{faq.getQuestion()}</p>
                        }
                      >
                        <div className={style.text}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html: faq.getResponse(),
                            }}
                          />
                        </div>
                      </Accordion>
                    )}
                  </div>
                ))}
            </div>
          )}
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.reference.faq.loading,
      faqs: TucanoSelectors.getFaqContent(state),
    };
  }),
  withTranslation()
)(PageFAQ);
