import actionTypes from "../consts/actionTypes";

const initialState = {
  hierarchy: {},
  menuorder: {
    loading: true,
    data: null,
  },
  channels: {
    loading: true,
    data: null,
  },
  faq: {
    loading: true,
    data: [],
  },
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.HIERARCHY_PENDING: {
      const oldHierarchy = state.hierarchy;
      return {
        ...state,
        hierarchy: {
          ...oldHierarchy,
          loading: true,
        },
      };
    }
    case actionTypes.HIERARCHY_SUCCESS: {
      return {
        ...state,
        hierarchy: {
          loading: false,
          data: action.payload.hierarchy,
        },
      };
    }
    case actionTypes.HIERARCHY_ERROR: {
      return {
        ...state,
        hierarchy: {
          error: action.meta,
        },
      };
    }
    case actionTypes.MENUORDER_PENDING: {
      const oldMenuorder = state.menuorder;
      return {
        ...state,
        menuorder: {
          ...oldMenuorder,
          loading: true,
        },
      };
    }
    case actionTypes.MENUORDER_SUCCESS: {
      return {
        ...state,
        menuorder: {
          loading: false,
          data: action.payload.items,
        },
      };
    }
    case actionTypes.MENUORDER_ERROR: {
      return {
        ...state,
        menuorder: {
          error: action.meta,
        },
      };
    }
    case actionTypes.LISTCHANNELS_PENDING:
      return {
        ...state,
        channels: {
          loading: true,
        },
      };
    case actionTypes.LISTCHANNELS_SUCCESS:
      return {
        ...state,
        channels: {
          loading: false,
          data: action.payload.channels,
        },
      };
    case actionTypes.LISTCHANNELS_ERROR:
      return {
        ...state,
        channels: {
          error: action.meta,
        },
      };
    case actionTypes.FAQ_PENDING:
      return {
        ...state,
        faq: {
          loading: true,
        },
      };
    case actionTypes.FAQ_SUCCESS:
      return {
        ...state,
        faq: {
          loading: false,
          data: action.payload,
        },
      };
    case actionTypes.FAQ_ERROR:
      return {
        ...state,
        faq: {
          error: action.meta,
        },
      };
    case actionTypes.RESET_GLOBAL_STATE:
      return initialState;
    default:
      return state;
  }
};
