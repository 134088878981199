import classnames from "classnames";
import ClockIcon from "components/Icons/Clock";
import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";

import style from "./style.module.css";

class AssetInfos extends Component {
  render() {
    const { asset, rootClassName } = this.props;
    return (
      <div className={classnames(style.root, rootClassName)}>
        <p className={style.title}>{asset.parentTitle || asset.getTitle()}</p>
        {/* <p className={style.synopsis}>{asset.getSynopsis()}</p> */}
        <p className={style.type}>
          {asset.isTVShowEpisode() && !asset.getCatchupId() && (
            <Fragment>
              <span>
                {this.props.t("S")} {asset.getSeasonNumber()} -{" "}
                {this.props.t("EP")} {asset.getEpisodeNumber()}
              </span>
              <span> | </span>
            </Fragment>
          )}
          <ClockIcon className={style.clock} /> {asset.getHumanDuration()}
        </p>
      </div>
    );
  }
}

export default withRouter(
  compose(
    connect((state, _props) => {
      return {
        hierarchy: state.reference.hierarchy.data,
      };
    }),
    withTranslation()
  )(AssetInfos)
);
