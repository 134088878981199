import ContentWrapper from "components/ContentWrapper";
import ErrorMessage from "components/ErrorMessage";
import Grid from "components/Grid";
import Header from "components/Header";
import Loader from "components/Loader";
import ThumbnailFavorite from "components/ThumbnailFavorite";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal } from "helpers/localStorage";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageFavorites extends PageAbstract {
  static fetch(dispatch, isConnected) {
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    if (isConnected) {
      dispatch(TucanoActions.getFavorites(languageId));
    }
  }

  handleOnDelete(index) {
    const favorites = this.props.favorites;
    const languageId = getFromLocal("languageId") ?? consts.languageId;

    this.props
      .dispatch(
        TucanoActions.deleteFavorite(
          favorites[index].getId(),
          favorites[index].isTVShow()
        )
      )
      .then(() => {
        this.props.dispatch(TucanoActions.getFavorites(languageId));
      });
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (this.props.isConnected) {
      PageFavorites.fetch(this.props.dispatch, this.props.isConnected);
    } else {
      this.props.history.push("?login=1");
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { favorites, isLoading, viewport, t } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && (
          <ContentWrapper>
            <div className={style.root}>
              <Header>
                <Trans>{this.props.route.title}</Trans>
              </Header>
              {favorites && favorites.length > 0 ? (
                <Grid
                  rootClassName={
                    consts.designMode === 169 ? style.gridCover : style.grid
                  }
                >
                  {favorites &&
                    favorites.map((asset, index) => {
                      return (
                        <ThumbnailFavorite
                          key={index}
                          asset={asset}
                          thumbnailSize={thumbnailSize}
                          handleOnDelete={this.handleOnDelete.bind(this, index)}
                          subscriptionModal={this.subscriptionModal.bind(this)}
                        />
                      );
                    })}
                </Grid>
              ) : (
                <ErrorMessage
                  rootClassName={style.errorMessage}
                  message={t("No favorites")}
                />
              )}
            </div>
          </ContentWrapper>
        )}
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.content.favorites.loading,
      viewport: state.ui.viewport,
      customerAuthToken: state.session.customerAuthToken,
      favorites: TucanoSelectors.getFavorites(state),
    };
  }),
  withTranslation()
)(PageFavorites);
