import React, { useState } from "react";
import style from "./style.module.css";
import classnames from "classnames";
import Modal from "components/Modal";
import { Trans, withTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { authWithApple, authWithFacebook, authWithGoogle } from "helpers/SSOSocialAuth";
import { CapitalizeFirstLetter } from "helpers/utility";

function LinkedSocialAccount({ socialIcon, socialNetwork, emailUsed, used, t }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const manageAddRemove = () => {
    if (used) {
      setShow(!show);
    } else {
      switch (socialNetwork.toLowerCase()) {
        case "facebook":
          authWithFacebook(dispatch);
          break;
        case "apple":
          authWithApple(dispatch);
          break;
        case "google":
          authWithGoogle(dispatch);
          break;
        default:
          console.log(socialNetwork + " not managed");
      }
    }
  };

  return (
    <>
      <div className={style.linkedAccountContainer}>
        <div className={style.linkedAccountContainerInfo}>
          <img
            src={`assets/socials/logo_sso_${socialIcon.toLowerCase()}.png`}
            alt={socialIcon}
            className={style.linkedAccountImg}
          />
          <div className={classnames(style.linkedAccountInfo, { [style.linkedAccountIsActive]: used })}>
            <p className={style.linkedAccountSocialType}>{CapitalizeFirstLetter(socialNetwork)}</p>
            {used && <p className={style.linkedAccountEmail}>{emailUsed}</p>}
          </div>
        </div>
        <button className={style.linkedAccountActionButton} onClick={manageAddRemove}>
          {used ? "Remove" : "Add"}
        </button>
      </div>
      {show && (
        <Modal
          size={"md"}
          rootClassName={style.modal}
          leftContent={{ title: t(`Remove your ${socialIcon}  account`) }}
          rightContent={{ canDismiss: () => setShow(!show) }}
          overlay={true}
          buttons={
            <div className={style.modalButton}>
              <span onClick={() => console.log("Cancel")} className={style.cancelButton}>
                <Trans>CANCEL</Trans>
              </span>
              <span onClick={() => console.log("Remove Device")} className={style.confirmButton}>
                <Trans>Unlink</Trans>
              </span>
            </div>
          }
        >
          <p className={style.linkedSocialModalDescription}>
            You are about to unlink {socialIcon} from this account. Do you want to continue ?
          </p>
        </Modal>
      )}
    </>
  );
}

export default withTranslation()(LinkedSocialAccount);
