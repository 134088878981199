import InlineSVG from "inline-svg-react";
import React from "react";

const Scroll = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Scroll.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Scroll.svg");
    } catch (err) {
      return (
        <svg {...props}>
          <g fillRule="nonzero">
            <path d="M12,7.4 L18.2085329,14.1778276 C18.4820574,14.4764332 18.4598684,14.9385482 18.1589722,15.2099905 C17.8580761,15.4814328 17.3924161,15.4594126 17.1188915,15.1608071 L12.7,10.336 L12.7,20.3 C12.7,20.6865993 12.3865993,21 12,21 C11.6134007,21 11.3,20.6865993 11.3,20.3 L11.3,10.336 L6.88110848,15.1608071 C6.60758393,15.4594126 6.14192395,15.4814328 5.84102779,15.2099905 C5.54013163,14.9385482 5.51794256,14.4764332 5.7914671,14.1778276 L12,7.4 Z M19.5,4 C19.7761424,4 20,4.22385763 20,4.5 L20,4.7 C20,4.97614237 19.7761424,5.2 19.5,5.2 L4.5,5.2 C4.22385763,5.2 4,4.97614237 4,4.7 L4,4.5 C4,4.22385763 4.22385763,4 4.5,4 L19.5,4 Z"></path>
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Scroll;
