import classnames from "classnames";
import FreeToAir from "components/Icons/FreeToAir";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

export default class ThumbnailCover extends Component {
  state = {
    hover: false,
    item: null,
  };
  async componentDidMount() {
    if (consts.displayVodData) {
      const { asset } = this.props;
      let metadata;
      if (asset instanceof TucanoModels.Asset && asset.getVodData()) {
        metadata = asset.getVodData();
      }
      await this.setState({ item: metadata });
    }
  }
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const {
      asset,
      rootClassName,
      grid,
      onlyPlay,
      size,
      large,
      fta,
      isConnected,
      imageType,
      activeTrailer,
      usePosterImage,
      showMoralityLevel,
      showIconNPVRRecord,
      record,
      channel,
    } = this.props;
    const { hover } = this.state;
    /*let catalog;
    if (asset instanceof TucanoModels.TVShow) {
      catalog = asset.getCatalogue();
    }
    if (asset.catalogs) {
      catalog = asset.catalogs;
    }*/
    let url, type, src;

    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }

    if (asset.getType() !== "EPGBroadcast" && !asset.isCatchupAsset) {
      if (asset.isSerie || asset.isAssetTVShow) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      } else if (asset.isCategory) {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }

    if (
      asset.getType() === "channel_logo" ||
      asset instanceof TucanoModels.Channel
    ) {
      url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
    }

    if (
      asset.getType() === "EPGBroadcast" ||
      asset instanceof TucanoModels.EPG
    ) {
      type = "epgEvent";
      url = consts.routes.epgAsset.url.replace(
        ":id",
        asset.getEpgId() !== undefined ? asset.getEpgId() : asset.getIdEvent()
      );
      // if (asset.isLive()) {
      //   url = consts.routes.playerlive.url.replace(
      //     ":channelId",
      //     asset.getChannelId()
      //   );
      // } else {
      // }
    }

    if (asset.getType() === "link") {
      url = getRealSlug(asset.getUrl());
    }
    if (imageType) {
      src = `${consts.image_hostname}${
        routes.imgdata
      }?type=${imageType}&objectId=${asset.getId()}&format_w=${
        large ? size.coverLarge.width : size.cover.width
      }&format_h=${
        large ? size.coverLarge.height : size.cover.height
      }&languageId=${getFromLocal("languageId")}`;
    }

    // apply border radius && shadow
    const borderRadiusStyle =
      (consts.thumbnailBorderRadius &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[0][consts.thumbnailBorderRadius.toLowerCase()]
        ]) ||
      "";
    const shadowStyle =
      (consts.thumbnailShadow &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[1][consts.thumbnailShadow.toLowerCase()]
        ]) ||
      "";

    return (
      <LinkDuo
        to={url}
        className={classnames(rootClassName, style.container, {
          [style.grid]: grid,
          [borderRadiusStyle]: true,
          [shadowStyle]: true,
          [style.large]: large,
          [style.container_hover]: hover,
        })}
        onMouseEnter={() =>
          this.setState({ hover: this.props.showInfosOverlay })
        }
        onMouseLeave={() => this.setState({ hover: false })}
        style={{
          minWidth: large ? size.coverLarge.width : size.cover.width,
          minHeight: large ? size.coverLarge.height : size.cover.height,
        }}
      >
        <div className={style.content}>
          <Image
            src={
              src
                ? src
                : asset.getImage(consts.image_hostname, {
                    usePosterImage: usePosterImage,
                    width: 764,
                    height: 430,
                    languageId: getFromLocal("languageId"),
                  })
            }
            width={large ? size.coverLarge.width : size.cover.width}
            height={large ? size.coverLarge.height : size.cover.height}
            ratio={ImageRatio.COVER}
            placeholder={PlaceholderType.PRIMARY}
          />
          {type === "epgEvent" && (
            <Image
              rootClassName={style.channel}
              height={40}
              width={40}
              effect={"opacity"}
              src={`${consts.image_hostname}${routes.imgdata}?type=${
                consts.imageTypes.tvChannel
              }&objectId=${asset.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                "languageId"
              )}`}
              placeholder={PlaceholderType.TRANSPARENT}
            />
          )}
          {type !== "epgEvent" &&
            !!asset.seekTime &&
            (large ? (
              <ProgressBar
                position={asset.getProgress()}
                rootClassName={style.progressBarForXL}
              />
            ) : (
              <ProgressBar
                position={asset.getProgress()}
                rootClassName={style.progressBar}
              />
            ))}
          {type === "epgEvent" &&
            asset instanceof TucanoModels.EPGEventAsset &&
            asset.isLive() &&
            asset.getEpgProgress() &&
            (large ? (
              <ProgressBar
                position={asset.getEpgProgress()}
                rootClassName={style.progressBarForXL}
              />
            ) : (
              <ProgressBar
                position={asset.getEpgProgress()}
                rootClassName={style.progressBar}
              />
            ))}

          {asset && asset.flyImage && (
            <div className={classnames(style.VODLogo)}>
              <LogoVod
                className={classnames(style.vodData)}
                type={asset.flyImage}
                size={40}
              />
            </div>
          )}
          {fta && !isConnected && (
            <div className={style.ftaIconContainer}>
              <FreeToAir className={style.fta} />
            </div>
          )}
        </div>
        <ThumbnailInfos
          showSmallThumbnail={false}
          isTrailerActive={
            consts.trailerOnFocus &&
            activeTrailer &&
            (asset.type === "asset_vod-poster" ||
              asset.type === "asset_vod-background")
          }
          show={hover}
          model={asset}
          onlyPlay={onlyPlay}
          subscriptionModal={this.subscriptionModal.bind(this)}
          url={url}
          showMoralityLevel={showMoralityLevel}
          statusNPVRRecord={record && record.getStatus()}
          showIconNPVRRecord={showIconNPVRRecord}
          channel={channel}
        />
      </LinkDuo>
    );
  }
}

ThumbnailCover.defaultProps = {
  asset: {},
  rootClassName: "",
  size: {},
  showInfosOverlay: true,
  onlyPlay: false,
  large: false,
  grid: false,
  showIconNPVRRecord: false,
};

ThumbnailCover.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
  showInfosOverlay: PropTypes.bool,
  onlyPlay: PropTypes.bool,
  large: PropTypes.bool,
  grid: PropTypes.bool,
};
