import InlineSVG from "inline-svg-react";
import React from "react";

const FavoriteRemove = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/FavoriteRemove.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/FavoriteRemove.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 36 36" {...props}>
          <path d="M11.3681581,6.11764706 C14.0878827,6.11764706 16.0354028,7.46800146 17.6406704,9.81496699 C19.2321098,7.45223631 21.1414942,6.11764706 23.8700268,6.11764706 C27.8498876,6.11764706 30.5882353,9.29466524 30.5882353,13.4638009 C30.5882353,14.0515439 30.525333,14.6606613 30.4028869,15.288423 L28.4398799,14.9055349 C28.538504,14.3999043 28.5882353,13.9183293 28.5882353,13.4638009 C28.5882353,10.3386978 26.6738587,8.11764706 23.8700268,8.11764706 C21.5520025,8.11764706 19.9820821,9.53797851 18.5252265,12.2196767 L17.6513849,13.8281952 L16.7700513,12.2237694 C15.310808,9.56728666 13.6869268,8.11764706 11.3681581,8.11764706 C8.52727031,8.11764706 6.58823529,10.3422687 6.58823529,13.4638009 C6.58823529,16.1700414 8.39020699,19.759059 11.2282116,23.1027583 C13.6329082,25.9359399 16.3833547,28.0326523 17.5208403,28.1151253 C17.5361053,28.1126307 17.5527055,28.1099269 17.5708021,28.1069794 L17.728509,28.1074753 C17.7442372,28.1101587 17.7588373,28.1126497 17.7724118,28.1149705 C18.9846557,28.024286 21.9788228,25.6396264 24.447706,22.5509078 L26.0099595,23.7996526 C23.1279018,27.4052771 19.6906098,30.1176471 17.7030924,30.1176471 C17.6828282,30.1154404 17.6640551,30.1133961 17.6466295,30.1114891 C17.6286941,30.1133887 17.6092869,30.1154313 17.5882353,30.1176471 C13.3599352,30.1176471 4.58823529,19.7829467 4.58823529,13.4638009 C4.58823529,9.29412674 7.35693321,6.11764706 11.3681581,6.11764706 Z M33.5882353,18.1176471 L33.5882353,20.1176471 L21.5882353,20.1176471 L21.5882353,18.1176471 L33.5882353,18.1176471 Z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default FavoriteRemove;
