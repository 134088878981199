import consts from "consts/consts";

const prefix = consts.storageKeys.prefix;

export function saveInLocal(key, value) {
  if (typeof value !== "undefined")
    window.localStorage.setItem(`${prefix}_${key}`, JSON.stringify(value));
}

export function getFromLocal(key) {
  return JSON.parse(window.localStorage.getItem(`${prefix}_${key}`));
}

export function removeFromLocal(key) {
  window.localStorage.removeItem(`${prefix}_${key}`);
}
