import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import Button, { ButtonType } from "components/Button";
import CheckIcon from "components/Icons/Check";
import Input, { InputType } from "components/Input";
import Logo from "components/LogoLogin";
import Overlay from "components/Overlay";
import consts from "consts/consts";
import { checkPolicy, isValidPolicy } from "helpers/Password";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageResetPassword extends Component {
  state = {
    email: "",
    token: "",
    newPassword: "",
    confirmNewPassword: "",
    validPasswordPolicies: [],
    error: "",
  };

  errorAPI = {
    "-10": "Password too short",
    "-11": "Passwords mismatch",
    "-12": "Invalid character",
    "-30": "URL is invalid",
    "-31": "URL is invalid",
    "-32": "URL is invalid",
    "-157": "Email is not valid",
    "-2362": "Missing parameter idHash",
    "-2363": "Missing parameter token",
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    const { t, isConnected } = this.props;

    if (isConnected) {
      this.props.history.push(consts.routes.home.url);
    }
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    // Force scroll to reset password overlay position => on top of window
    window.scrollTo(0, 0);
    disableBodyScroll();
    document.addEventListener("keydown", this.handleKeyPressed, false);

    let search = new URLSearchParams(this.props.location.search);

    const email = search.get("email") || "";
    const token = search.get("token") || "";
    this.setState({ email: email, token: token });

    if (email === "" || token === "") {
      // this.setState({ error: "URL is invalid" });
      this.props.history.push(consts?.routes?.signIn?.url || "/sign-in");
    }
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      this.resetPassword();
    }
  }

  handleOnFocus(key) {
    let validated = [];
    if (key === "newPassword") {
      validated = checkPolicy(
        this.props.passwordPolicy,
        this.state.newPassword
      );
    }
    this.setState({ validPasswordPolicies: validated });
  }

  handlePasswordChange(key, value) {
    if (this.props.errorOnResetPassword) {
      this.props.dispatch(TucanoActions.resetResetPassword());
    }
    const newState = {};
    if (key === "newPassword") {
      let validated = checkPolicy(this.props.passwordPolicy, value);
      newState.validPasswordPolicies = validated;
    }

    newState[key] = value;

    this.setState((prevState) => ({
      ...prevState,
      ...newState,
    }));
  }

  resetPassword() {
    if (
      this.state.error === "" &&
      this.state.email !== "" &&
      this.state.token !== "" &&
      this.state.newPassword !== "" &&
      this.state.confirmNewPassword !== "" &&
      this.state.newPassword === this.state.confirmNewPassword
    ) {
      this.props
        .dispatch(
          TucanoActions.resetPassword(
            this.state.email,
            this.state.email,
            this.state.token,
            this.state.newPassword,
            this.state.confirmNewPassword
          )
        )
        .then((_data) => {});
    }
  }

  getErrorAPI(code) {
    if (this.errorAPI[code]) {
      return this.errorAPI[code];
    } else {
      return "An error occured,Please retry later";
    }
  }

  handleLogin() {
    this.props.history.push(consts?.routes?.signIn?.url || "/sign-in");
  }

  render() {
    const { isLoading, errorOnResetPassword, t, passwordPolicy } = this.props;
    const { validPasswordPolicies } = this.state;
    const buttonType = isLoading ? ButtonType.LOADING : ButtonType.NORMAL;

    return (
      <Overlay>
        <div className={style.root}>
          <div className={style.container}>
            <div className={style.form}>
              <Logo className={style.logo} />
              {errorOnResetPassword !== null && (
                <div>
                  <h1 className={style.mainTitle}>
                    <Trans>Reset your password</Trans>
                  </h1>
                  <div className={style.inputs}>
                    <p>
                      <Input
                        type={InputType.PASSWORD}
                        placeholder={t("Enter your new password")}
                        onFocus={() => {
                          this.handleOnFocus("newPassword");
                        }}
                        onChange={(value) =>
                          this.handlePasswordChange("newPassword", value)
                        }
                        value={this.state.newPassword}
                      />
                    </p>
                    <p>
                      <Input
                        type={InputType.PASSWORD}
                        placeholder={t("Confirm new password")}
                        onFocus={() => {
                          this.handleOnFocus("confirmNewPassword");
                        }}
                        onChange={(value) =>
                          this.handlePasswordChange("confirmNewPassword", value)
                        }
                        value={this.state.confirmNewPassword}
                      />
                    </p>
                    {this.state.error !== "" && (
                      <div className={style.errorMessage}>
                        {this.state.error}
                      </div>
                    )}
                    {this.state.newPassword !==
                      this.state.confirmNewPassword && (
                      <div className={style.errorMessage}>
                        <Trans>The passwords are different</Trans>
                      </div>
                    )}
                    <div className={style.passwordPolicyContainer}>
                      {passwordPolicy && passwordPolicy.length > 0 && (
                        <ul className={style.passwordPolicyList}>
                          {passwordPolicy.map((item, index) => {
                            return (
                              item.message !== "" &&
                              item.value !== false && (
                                <li
                                  key={index}
                                  className={isValidPolicy(
                                    validPasswordPolicies,
                                    item.policy,
                                    style.isValidPolicy
                                  )}
                                >
                                  <Trans>{item.message}</Trans>
                                </li>
                              )
                            );
                          })}
                        </ul>
                      )}
                    </div>
                    {errorOnResetPassword && errorOnResetPassword.message && (
                      <div className={style.errorMessage}>
                        {t(this.getErrorAPI(errorOnResetPassword.code))}
                      </div>
                    )}
                  </div>
                  <div className={style.actionButton}>
                    <Button
                      type={
                        this.state.newPassword === "" ||
                        this.state.confirmNewPassword === "" ||
                        this.state.newPassword !== this.state.confirmNewPassword
                          ? ButtonType.DISABLED
                          : buttonType
                      }
                      onClick={this.resetPassword.bind(this)}
                    >
                      <Trans>RESET_PASSWORD_BUTTON</Trans>
                    </Button>
                  </div>
                </div>
              )}
              {errorOnResetPassword === null && (
                <div>
                  <div className={style.successMessage}>
                    <CheckIcon className={style.checkIcon} />
                    <span>
                      <Trans>Your password has been successfully changed</Trans>
                    </span>
                  </div>
                  <div className={style.actionButton}>
                    <Button
                      rootClassName={style.loginButton}
                      type={buttonType}
                      onClick={this.handleLogin.bind(this)}
                    >
                      <Trans>Log in</Trans>
                    </Button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Overlay>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.account.resetPassword.loading,
      errorOnResetPassword: state.account.resetPassword.error,
      passwordPolicy: TucanoSelectors.getPasswordPolicy(state),
    };
  }),
  withTranslation()
)(PageResetPassword);
