import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import SwipeableViews from "react-swipeable-views";
import { autoPlay, virtualize } from "react-swipeable-views-utils";

import style from "./style.module.css";

const VirtualizedSwipeableViews = virtualize(autoPlay(SwipeableViews));

export default class SlideShow extends Component {
  state = {
    activeIndex: 0,
  };

  slideModulo(index) {
    const slideCount = this.props.children.length;
    const result = index % slideCount;
    return result < 0 ? result + slideCount : result;
  }

  updateActiveIndex(index) {
    const { onChangeIndex } = this.props;

    this.setState({ activeIndex: index });
    onChangeIndex && onChangeIndex(this.slideModulo(index));
  }

  slideRenderer(params) {
    const { index } = params;
    const slideIndex = this.slideModulo(index);
    return <div key={index}>{this.props.children[slideIndex]}</div>;
  }

  render() {
    const { autoplay, interval, pagination } = this.props;
    const { activeIndex } = this.state;
    return (
      <div className={style.container}>
        <VirtualizedSwipeableViews
          // enableMouseEvents
          resistance
          autoplay={autoplay}
          interval={interval}
          index={activeIndex}
          onChangeIndex={this.updateActiveIndex.bind(this)}
          slideRenderer={this.slideRenderer.bind(this)}
          slideClassName={style.slide}
          //overscanSlideAfter={0}
          //overscanSlideBefore={0}
        />
        {pagination && (
          <div className={style.pagination}>
            {[...Array(this.props.children.length)].map((e, index) => {
              const finalDotClassName = classnames(style.paginationDot, {
                [style.paginationDotActive]:
                  index === this.slideModulo(this.state.activeIndex),
              });
              return (
                <button
                  type="button"
                  onClick={this.updateActiveIndex.bind(this, index)}
                  className={style.paginationItem}
                  key={index}
                >
                  <div className={finalDotClassName} />
                </button>
              );
            })}
          </div>
        )}
      </div>
    );
  }
}

SlideShow.defaultProps = {
  autoplay: false,
  interval: 10000,
  pagination: false,
  clickablePagination: false,
};

SlideShow.propTypes = {
  autoplay: PropTypes.bool,
  interval: PropTypes.number,
  pagination: PropTypes.bool,
  clickabledPagination: PropTypes.bool,
};
