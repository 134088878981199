import InlineSVG from "inline-svg-react";
import React from "react";

const About = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/About.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/About.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 22 22" {...props}>
          <path d="M11 0C4.925 0 0 4.924 0 11c0 6.075 4.925 11 11 11s11-4.925 11-11c0-6.076-4.925-11-11-11m0 1.833c5.055 0 9.167 4.112 9.167 9.167S16.054 20.167 11 20.167c-5.054 0-9.167-4.113-9.167-9.167 0-5.055 4.112-9.167 9.167-9.167M11 9a1 1 0 0 1 1 1v7a1 1 0 0 1-2 0v-7a1 1 0 0 1 1-1zm0-1a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default About;
