import classnames from "classnames";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Overlay extends Component {
  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleKeyPressed(event) {
    if (this.props.canDismiss === true && event.key === "Escape") {
      this.props.handleClose();
    }
  }

  render() {
    const { children } = this.props;
    const rootClassName = classnames(this.props.rootClassName);
    const finalClassName = classnames(rootClassName);
    return (
      <div className={style.root}>
        <div className={finalClassName}>{children}</div>
      </div>
    );
  }
}
