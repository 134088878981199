import classnames from "classnames";
import ArrowHeading from "components/Icons/Arrowheading";
import CloseIcon from "components/Icons/Close";
import Logo, { LogoTypes } from "components/LogoNav";
import SwitchLanguage from "components/SwitchLanguage";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";

import style from "./style.module.css";
/**
 * This component has been designed to be configurable through the props variable
 *
 * props that could be passed:
 *
 * size: String => "lg" / "md" / "sm" ( Max-width : 640px / 490px / 330px)
 *
 * leftContent:
 * ---- title       : String
 * ---- logo        : true/false
 * ---- backBtn     : callBack Func ()
 *
 * rightContent:
 * ---- langDropDown: true/false
 * ---- canDismiss  : callBack Func ()
 *
 * overlay: true/false
 *
 * buttons: jsx
 */
class Modal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
    };

    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    if (this.props.overlay) {
      document.body.style.top = "0";
      document.body.style.bottom = "0";
      document.body.style.left = "0";
      document.body.style.right = "0";
      // document.body.style.overflow = "hidden";
    }

    document.addEventListener("keydown", this.handleKeyPressed, false);

    setTimeout(() => {
      this.setState({ show: true });
    }, 5);
  }

  componentWillUnmount() {
    if (this.props.overlay) {
      document.body.style.position = null;
      document.body.style.top = null;
      document.body.style.bottom = null;
      document.body.style.left = null;
      document.body.style.right = null;
    }

    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleOverlayClick() {
    const { canDismiss } = this.props.rightContent;
    if (canDismiss === true) {
      this.closeModal();
    }
  }

  handleKeyPressed(event) {
    const { canDismiss } = this.props.rightContent;

    if (canDismiss === true && event.key === "Escape") {
      this.closeModal();
    }
  }

  closeModal() {
    const { canDismiss } = this.props.rightContent;

    this.setState({ show: false });
    setTimeout(() => {
      canDismiss();
    }, 5);
  }

  render() {
    const {
      children,
      overlay,
      buttons,
      rootClassName,
      size,
      leftContent,
      rightContent,
      modalType,
    } = this.props;
    const { logo, title, backBtn } = leftContent || {};
    const { canDismiss, langDropDown } = rightContent || {};
    const { show } = this.state;
    // const layoutDirection = getFromLocal("layoutDirection");

    const languages = consts.enum.languageId;
    /*const selectedLang = Object.values(languages).filter((item) => {
      return item.value === getFromLocal("languageId");
    });*/
    const finalClassName = classnames(rootClassName, style.modalMain, {
      [style.modalMainSm]: size === "sm",
      [style.modalMainMd]: size === "md",
      [style.modalMainLg]: size === "lg",
    });

    const direction = getFromLocal("layoutDirection");
    return (
      <div
        className={classnames(style.root, {
          [style.withOverlay]: overlay && show,
        })}
      >
        <div className={classnames(style.modal, { [style.animation]: show })}>
          <div className={finalClassName}>
            {(leftContent !== "" || rightContent !== "") && (
              <div
                className={classnames(style.header, {
                  [style.removeCenterparentalControls]:
                    modalType === "MODAL_PARENTAL_CONTROLS",
                  [style.headerRLT]: direction.toLowerCase() === "rtl",
                })}
              >
                {leftContent && (
                  <div
                    className={classnames(
                      style.leftContent,
                      {
                        [style.leftContentRTL]:
                          direction.toLowerCase() === "rtl",
                      },
                      {
                        [style.leftContentArabRTL]:
                          direction.toLowerCase() === "rtl" &&
                          modalType === "MODAL_PROFILE_SELECTION",
                      },
                      {
                        [style.parentalControls]:
                          direction.toLowerCase() !== "rtl" &&
                          modalType === "MODAL_PARENTAL_CONTROLS",
                      }
                    )}
                  >
                    {title && <h3 className={style.title}>{title}</h3>}
                    {logo && (
                      <Logo className={style.logo} type={LogoTypes.RECTANGLE} />
                    )}
                    {backBtn && (
                      <a onClick={backBtn} className={style.backBtn}>
                        <ArrowHeading className={style.iconBack} />
                        <span>
                          <Trans>Back</Trans>
                        </span>
                      </a>
                    )}
                  </div>
                )}
                {rightContent && (
                  <div className={style.rightContent}>
                    {canDismiss && (
                      <CloseIcon
                        className={style.iconClose}
                        onClick={this.closeModal.bind(this)}
                      />
                    )}
                    {langDropDown && (
                      <div
                        className={classnames(style.changeLanguage, {
                          [style.changeLanguageRTL]:
                            direction.toLowerCase() === "rtl",
                        })}
                      >
                        {languages.length > 1 && (
                          <SwitchLanguage shouldAddMargin />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <div
              className={classnames(style.content, {
                [style.withHeader]: title !== "",
              })}
            >
              {children}
            </div>
            {buttons && (
              <div className={style.actionButtons}>
                <div className={style.buttons}>{buttons}</div>
              </div>
            )}
          </div>
          <div
            className={style.overlay}
            onClick={this.handleOverlayClick.bind(this)}
          />
        </div>
      </div>
    );
  }
}

export default withTranslation()(Modal);

Modal.defaultProps = {
  overlay: false,
  isSubscription: false,
  size: "md",
};

Modal.propTypes = {
  overlay: PropTypes.bool,
  isSubscription: PropTypes.bool,
};
