import actionTypes from "consts/actionTypes";
import { saveInLocal } from "helpers/localStorage";

/* This is used to import every auth tokens from localstorage on application startup */
export function setAuthTokens(tokens) {
  return {
    type: actionTypes.SET_AUTH_TOKENS,
    payload: tokens,
  };
}

export function setCustomerAuthToken(token) {
  return {
    type: actionTypes.SET_CUSTOMER_AUTH_TOKEN,
    payload: token,
  };
}

export function setDeviceAuthToken(token) {
  return {
    type: actionTypes.SET_DEVICE_AUTH_TOKEN,
    payload: token,
  };
}

export function setProfileToken(token) {
  return {
    type: actionTypes.SET_PROFILE_TOKEN,
    payload: token,
  };
}

export function setDeviceLimitError(value = true) {
  return {
    type: actionTypes.SET_DEVICE_LIMIT_ERROR,
    payload: value,
  };
}

export function setDiscoverMode(state) {
  saveInLocal("isDiscoverMode", state);
  return {
    type: actionTypes.SET_DISCOVER_MODE,
    payload: state,
  };
}
