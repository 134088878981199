import consts from "consts/consts";
import React, { Component } from "react";
import { Trans } from "react-i18next";

import rectangleLogo from "../../../public/assets/logo_horizontal_content.png";
import style from "./style.module.css";

export default class Footer extends Component {
  render() {
    return (
      <div className={style.root}>
        <div className={style.container}>
          <span className={style.text}>
            <Trans>Powered by</Trans>
          </span>
          <img
            src={rectangleLogo}
            alt={`${consts.name}'s logo`}
            className={style.logo}
          />
        </div>
      </div>
    );
  }
}
