import InlineSVG from "inline-svg-react";
import React from "react";

const Support = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Support.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Support.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M6.394 7.808L4.968 6.382A8.962 8.962 0 0 0 3 12c0 2.125.736 4.078 1.968 5.618l1.426-1.426A6.969 6.969 0 0 1 5 12c0-1.572.518-3.024 1.394-4.192zm9.798 9.798A6.969 6.969 0 0 1 12 19a6.969 6.969 0 0 1-4.192-1.394l-1.426 1.426A8.962 8.962 0 0 0 12 21a8.962 8.962 0 0 0 5.618-1.968l-1.426-1.426zM7.808 6.394A6.969 6.969 0 0 1 12 5c1.572 0 3.024.518 4.192 1.394l1.426-1.426A8.962 8.962 0 0 0 12 3a8.962 8.962 0 0 0-5.618 1.968l1.426 1.426zm9.798 9.798l1.426 1.426A8.962 8.962 0 0 0 21 12a8.962 8.962 0 0 0-1.968-5.618l-1.426 1.426A6.969 6.969 0 0 1 19 12a6.969 6.969 0 0 1-1.394 4.192zM12 23C5.925 23 1 18.075 1 12S5.925 1 12 1s11 4.925 11 11-4.925 11-11 11zm0-6a5 5 0 1 0 0-10 5 5 0 0 0 0 10z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Support;
