import InlineSVG from "inline-svg-react";
import React from "react";

const Pause = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Pause.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Pause.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 60 60" {...props}>
          <circle cx="30" cy="30" r="28" />
          <rect x="21" width="6" height="20" y="20" />
          <rect x="33" width="6" height="20" y="20" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Pause;
