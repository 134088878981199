import InlineSVG from "inline-svg-react";
import React from "react";

const SeekForward = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/SeekForward.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/SeekForward.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M19.928 17.536c.186-.314.354-.639.502-.973l.446.197a9.619 9.619 0 01-.53 1.025l-.418-.249zm.89-2.018c.107-.35.193-.705.258-1.066l.48.087c-.069.379-.16.753-.271 1.12l-.466-.141zm.388-2.144c.023-.364.025-.73.005-1.096l.487-.026c.02.384.019.77-.005 1.153l-.487-.03zm-.12-2.202a9.464 9.464 0 00-.249-1.068l.468-.138c.11.37.196.745.262 1.123l-.48.083zm-.63-2.123a9.563 9.563 0 00-.489-.982l.423-.242c.192.335.364.68.515 1.032l-.448.192zm-1.085-1.898a9.702 9.702 0 00-.698-.848l.358-.33c.26.28.504.577.733.89l-.393.288zm-1.49-1.612a9.484 9.484 0 00-.872-.665l.272-.404c.319.214.625.448.917.7l-.317.369zm-3.889-2.007a9.406 9.406 0 00-1.078-.202l.062-.484c.381.049.76.12 1.133.212l-.117.474zm-2.072-.34c-5.103 0-9.241 4.217-9.241 9.423 0 5.205 4.138 9.423 9.24 9.423 2.867 0 5.517-1.34 7.26-3.591l.385.298c-1.834 2.37-4.625 3.78-7.644 3.78-5.375 0-9.729-4.438-9.729-9.91 0-5.473 4.354-9.91 9.729-9.91v.487z" />
          <path d="M15.068 2.948L11.7 4.928V.969z" />
          <text x="7" y="14" style={{ fontSize: "5px", fontFamily: "arial" }}>
            +15s
          </text>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default SeekForward;
