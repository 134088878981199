import consts from "consts/consts";

import { findObjectInArrayBy } from "../web-api/helpers/dataHelper";

const googleConf = findObjectInArrayBy(consts?.SSOSocials, "name", "Google");

export function initGoogleApi() {
  const script = document.createElement("script");
  script.src = "https://apis.google.com/js/api.js";
  script.onload = () => {
    window.gapi.load("client:auth2");
  };
  document.createElement("script");
  document.body.appendChild(script);
}

let windowObjectReference = null;
let previousUrl = null;

const receiveMessage = (event, resolve, _reject) => {
  const { data } = event;
  if (data?.type === "ssoScial:auth:done") {
    resolve(data.payload);
    if (windowObjectReference && !windowObjectReference.closed) {
      windowObjectReference.close();
    }
  }
};

const openSignInWindow = (url, name, resolve, reject) => {
  window.removeEventListener("message", receiveMessage);
  const strWindowFeatures =
    "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
  if (windowObjectReference === null || windowObjectReference.closed) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
  } else if (previousUrl !== url) {
    windowObjectReference = window.open(url, name, strWindowFeatures);
    windowObjectReference.focus();
  } else {
    windowObjectReference.focus();
  }

  // add the listener for receiving a message from the popup
  window.addEventListener(
    "message",
    (event) => receiveMessage(event, resolve, reject),
    false
  );
  // assign the previous URL
  previousUrl = url;
};

export function loginWithGoogle() {
  let url = "https://accounts.google.com/o/oauth2/v2/auth?";
  url = `${url}client_id=${googleConf?.clientID}&`;
  url = `${url}scope=${googleConf?.scope}&`;
  url = `${url}include_granted_scopes=true&response_type=code&state=ssoScial:auth:done&`;
  url = `${url}redirect_uri=${googleConf?.callbackAPI}`;

  return new Promise((resolve, reject) => {
    openSignInWindow(url, "Google SSO", resolve, reject);
  });
}
export function revokeAccess() {
  window.GoogleAuth.disconnect();
}
