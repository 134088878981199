import { PureComponent } from "react";

class PageAbstract extends PureComponent {
  static fetch(_dispatch, _isConnected, _params) {
    // eslint-disable-next-line no-console
    console.error("You must implement fetch method !");
  }

  componentDidUpdate(prevProps) {
    // Refresh Page data on successful login
    if (!prevProps.isConnected && this.props.isConnected) {
      this.constructor.fetch(
        this.props.dispatch,
        this.props.isConnected,
        this.props.match.params,
        prevProps.isConnected // the previous state of isConnected is added to check if the loader in the home page should be displayed or not
      );
    }
  }
}

export default PageAbstract;
