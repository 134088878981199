import InlineSVG from "inline-svg-react";
import React from "react";

const Clock = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Clock.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Clock.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 15 15" {...props}>
          <path d="M10.049 7.297H7.275V3.6a.66.66 0 1 0-1.32 0v5.018h4.094a.66.66 0 1 0 0-1.32m-3.05 5.965a5.686 5.686 0 0 1-5.678-5.68A5.685 5.685 0 0 1 7 1.905a5.685 5.685 0 0 1 5.68 5.68A5.686 5.686 0 0 1 7 13.263M7 .583c-3.86 0-7 3.14-7 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Clock;
