import classnames from "classnames";
import FavoriteAdd from "components/Icons/FavoriteAdd";
import FavoriteRemove from "components/Icons/FavoriteRemove";
import React, { Component } from "react";

import style from "./style.module.css";

export default class FavoriteToggle extends Component {
  setFavoriteStatus(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.onFavoriteClick) {
      const previousState = this.props.isAssetFavorite;
      this.props.onFavoriteClick(e, !previousState);
    }
  }

  render() {
    const { rootClassName } = this.props;

    return (
      <div
        className={classnames(style.root, rootClassName)}
        onClick={this.setFavoriteStatus.bind(this)}
      >
        {this.props.isAssetFavorite === true ? (
          <FavoriteRemove className={style.removeFavIcon} />
        ) : (
          <FavoriteAdd className={style.addFavIcon} />
        )}
      </div>
    );
  }
}
