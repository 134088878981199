import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Input, { InputType } from "components/Input";
import Modal from "components/Modal";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

const ADULT_CODE_REGEXP = /^[0-9]*$/;

class ModalValidateParentalCode extends Component {
  state = {
    data: {
      parentalCode: "",
    },
    error: {
      parentalCode: "",
    },
    focus: {
      parentalCode: false,
    },
    digitNumberLength: 4,
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      if (
        this.state.focus.parentalCode &&
        this.state.error.parentalCode === ""
      ) {
        this.handleSubmit();
      }
    }
  }

  handleClose() {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  handleCancel() {
    if (this.props.onCloseClick) {
      this.props.onCancelClick();
    }
  }

  handleSubmit() {
    this.props
      .dispatch(TucanoActions.validateAdultCode(this.state.data.parentalCode))
      .then((result) => {
        if (result && result.newAuthToken && this.props.onConfirmClick) {
          this.props.onConfirmClick();
        } else {
          const { t } = this.props;
          const updatedState = this.state;
          updatedState["error"]["parentalCode"] = t("Parental code not OK");
          this.setState(updatedState);
        }
      });
  }

  handleValidateParentalControl(key, value) {
    const updatedState = this.state;
    const { t } = this.props;
    updatedState["data"][key] = value;

    if (
      ADULT_CODE_REGEXP.test(value) &&
      value.length === this.state.digitNumberLength
    ) {
      updatedState["error"][key] = "";
    } else {
      updatedState["error"][key] = t("Parental code is a 4 digit number");
    }
    updatedState["focus"][key] = true;
    this.setState(updatedState);
  }

  render() {
    const { rootClassName, isLoading, t } = this.props;
    const finalClassName = classnames(rootClassName, style.root);

    return (
      <Modal
        modalType="MODAL_PARENTAL_CONTROLS"
        size={"sm"}
        leftContent={{ title: t("Parental code") }}
        rightContent={{ canDismiss: this.handleClose.bind(this) }}
        rootClassName={finalClassName}
        overlay={true}
        buttons={
          <>
            {isLoading && (
              <Button
                rootClassName={style.submitButton}
                type={ButtonType.LOADING}
              >
                <Trans>Parental code</Trans>
              </Button>
            )}

            {!isLoading && (
              <Button
                rootClassName={style.submitButton}
                type={
                  !this.state.focus.parentalCode ||
                  this.state.error.parentalCode !== ""
                    ? ButtonType.DISABLED
                    : ButtonType.NORMAL
                }
                onClick={this.handleSubmit.bind(this)}
              >
                <Trans>OK</Trans>
              </Button>
            )}
          </>
        }
      >
        <div className={style.form}>
          <div className={style.container}>
            <label className={style.title}>
              <Trans>Enter parental code</Trans>
            </label>
            <Input
              type={InputType.PASSWORD}
              onChange={(value) =>
                this.handleValidateParentalControl("parentalCode", value)
              }
              value={this.state.data.parentalCode}
            />
            {this.state.error.parentalCode && (
              <p className={style.error}>
                <Trans>{this.state.error.parentalCode}</Trans>
              </p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.account.validateParentalCode.loading,
    };
  }),
  withTranslation()
)(ModalValidateParentalCode);
