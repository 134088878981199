import InlineSVG from "inline-svg-react";
import React from "react";

const Search = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Search.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Search.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M16.96 14.627L24 20.525l-1.304 1.556-7.026-5.886a9.303 9.303 0 0 1-6.49 2.604C4.119 18.8 0 14.823 0 9.9 0 4.977 4.118 1 9.18 1c5.063 0 9.181 3.976 9.181 8.9a8.658 8.658 0 0 1-1.4 4.727zm-7.78 2.142c3.957 0 7.15-3.084 7.15-6.87 0-3.785-3.193-6.869-7.15-6.869-3.956 0-7.15 3.084-7.15 6.87 0 3.785 3.194 6.869 7.15 6.869z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default Search;
