import Button, { ButtonType } from "components/Button";
import FavoriteRemove from "components/Icons/FavoriteRemove";
import ThumbnailCoverFavorite from "components/ThumbnailCoverFavorite";
import ThumbnailPoster from "components/ThumbnailPoster";
import consts from "consts/consts";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import style from "./style.module.css";

class ThumbnailFavorite extends Component {
  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  render() {
    const { asset, thumbnailSize, handleOnDelete, t } = this.props;
    let url;

    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }

    if (!asset.isCatchup()) {
      if (asset.isTVShow() && !asset.getCatchupId()) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
      }
    }

    const favorite = this.props.asset;

    return (
      <div
        className={
          consts.designMode === 169 ? style.containerCover : style.container
        }
      >
        {consts.designMode === 169 ? (
          <ThumbnailCoverFavorite
            asset={favorite}
            imageType={favorite.isAssetTVShow ? 550 : 512}
            rootClassName={style.thumbnailCover}
            size={thumbnailSize}
            onlyPlay={true}
            subscriptionModal={this.subscriptionModal.bind(this)}
          />
        ) : (
          <ThumbnailPoster
            asset={favorite}
            rootClassName={style.thumbnail}
            size={thumbnailSize}
            onlyPlay={true}
            subscriptionModal={this.subscriptionModal.bind(this)}
            withoutProgress={true}
          />
        )}
        <div className={style.description}>
          <Link to={url} className={style.title}>
            {favorite.getTitle()}
          </Link>
          {favorite.isTVShow() ? (
            <p className={style.subtitle}>
              {t("Season", { count: asset.getSeasonCount() })}
            </p>
          ) : (
            <p className={style.subtitle}>
              {favorite.getReleaseYear()}{" "}
              {favorite.getReleaseYear() && favorite.getGenre() && "|"}{" "}
              {favorite.getGenre()}
            </p>
          )}
          {consts.designMode !== 169 && (
            <p className={style.synopsis}>{favorite.getSynopsis()}</p>
          )}
        </div>
        <Button
          rootClassName={
            consts.designMode === 169 ? style.buttonCover : style.button
          }
          type={ButtonType.BORDERED}
          onClick={handleOnDelete}
          icon={
            consts.designMode !== 169 ? (
              <FavoriteRemove className={style.icon} />
            ) : null
          }
        >
          <Trans>Delete</Trans>
        </Button>
      </div>
    );
  }
}

ThumbnailFavorite.defaultProps = {
  asset: {},
  rootClassName: "",
  thumbnailSize: {},
};

ThumbnailFavorite.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  thumbnailSize: PropTypes.object,
};

export default withTranslation()(ThumbnailFavorite);
