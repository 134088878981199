export default class Faq {
  constructor(data = {}) {
    let item = data;
    if (item.name) {
      this.name = item.name;
    }
    if (item.question) {
      this.question = item.question;
    }
    if (item.response) {
      this.response = item.response;
    }
  }

  getName() {
    return this.name;
  }

  getQuestion() {
    return this.question;
  }

  getResponse() {
    return this.response;
  }
}
