const WEEKDAYS = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export function formatMinutesInHumanDuration(minutes) {
  if (!minutes) {
    return "";
  }
  const hour = Math.floor(minutes / 60);
  const minute = Math.floor(minutes % 60);
  let formatted = `${minute}MN`;
  if (hour > 0 && minute <= 9) {
    formatted = `0${formatted}`;
  }
  if (hour > 0) {
    formatted = `${hour}H${formatted}`;
  }
  if (hour > 0 && minute === 0) {
    formatted = `${hour}H`;
  }
  return formatted;
}

export function formatSecondsInHumanDuration(seconds) {
  return formatMinutesInHumanDuration(seconds / 60);
}

export function formatDate(date) {
  if (!date) {
    return "";
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const dayName = WEEKDAYS[date.getDay()];
  return `${dayName} ${day}/${month}`;
}

export function formatFullDate(date) {
  if (!date) {
    return "";
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

export function formatDateCatchDetail(date) {
  if (!date) {
    return "";
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

export function formatFullDateAndTime(date) {
  if (!date) {
    return "";
  }
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  const hours = date.getHours();
  let minutes = date.getMinutes();
  if (minutes < 10) {
    minutes = `0${minutes}`.slice(-2);
  }
  return `${day}/${month}/${year}, ${hours}:${minutes}`;
}

export function isSameDay(date1, date2) {
  if (!date1 || !date2) {
    return false;
  }
  date1.setHours(0, 0, 0, 0);
  date2.setHours(0, 0, 0, 0);
  return date1.getTime() === date2.getTime();
}
