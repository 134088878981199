import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import PadlockLock from "components/Icons/PadlockLock";
import PlayIcon from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { format } from "date-fns";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class ThumbnailSearchEPG extends Component {
  state = {
    hover: false,
    itemMetadata: null,
  };

  async componentDidMount() {
    if (consts.displayVodData) {
      const { item } = this.props;
      /*let metadata;
      if (item instanceof TucanoModels.Asset && item.getVodData()) {
        metadata = item.getVodData();
      }*/

      if (item && item.flyImage) {
        await this.setState({ itemMetadata: item.flyImage });
      }
    }
  }
  unlock(e) {
    e.stopPropagation();
    e.preventDefault();

    const { accountStatus, dispatch } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(this.props.model);
    }
  }

  isLock() {
    const { item, accountStatus } = this.props;

    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!consts.padlocks) return false;
    if (item.isCategory) return false;
    if (item.isAssetTVShow && !item.isAssetTVShowEpisode) return false;
    if (item.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !item.userHasAccess;
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { item, ratio, size, imageType } = this.props;
    const { hover, itemMetadata } = this.state;
    /*let catalog;
    if (item instanceof TucanoModels.TVShow) {
      catalog = item.getCatalogue();
    }
    if (item.catalogs) {
      catalog = item.catalogs;
    }*/
    let url, src;

    if (item instanceof TucanoModels.Asset) {
      url = consts.routes.movieAsset.url.replace(":id", item.getId());
      if (item.isTVShow() || item.isAssetTVShowEpisode === true) {
        url = consts.routes.tvshowAsset.url.replace(":id", item.getParentId());
      } else if (item.isCategory) {
        url = consts.routes.vod.detailUrl.replace(":id", item.getId());
      }
    } else if (item instanceof TucanoModels.EPG) {
      if (typeof item.isLive === "function" && item.isLive()) {
        url = consts.routes.playerlive.url.replace(
          ":channelId",
          item.getChannelId()
        );
      } else {
        url = consts.routes.epgAsset.url.replace(":id", item.getEpgId());
      }
    }

    // TODO check if this part is redundant because of "item.getImage" function
    if (imageType) {
      src = `${consts.image_hostname}${
        routes.imgdata
      }?type=${imageType}&objectId=${item.getId()}&format_w=${
        ratio === ImageRatio.POSTER ? size.poster.width : size.cover.width
      }&format_h=${
        ratio === ImageRatio.POSTER ? size.poster.height : size.cover.height
      }`;
    }
    return (
      <Link
        to={url}
        className={classnames(style.root, style.container)}
        onMouseEnter={() => this.setState({ hover: true })}
        onMouseLeave={() => this.setState({ hover: false })}
      >
        <Image
          src={
            src
              ? src
              : item.getImage(consts.image_hostname, {
                  width:
                    ratio === ImageRatio.POSTER
                      ? size.poster.width
                      : size.cover.width,
                  height:
                    ratio === ImageRatio.POSTER
                      ? size.poster.height
                      : size.cover.height,
                  languageId: getFromLocal("languageId"),
                })
          }
          width={
            ratio === ImageRatio.POSTER ? size.poster.width : size.cover.width
          }
          height={
            ratio === ImageRatio.POSTER ? size.poster.height : size.cover.height
          }
          ratio={ratio}
          placeholder={PlaceholderType.PRIMARY}
        />
        {ratio === ImageRatio.COVER ? (
          <div className={style.containerLandscape}>
            {/* BACKGROUND OVERLAY */}
            <div
              className={classnames(style.background, {
                [style.background_show]: hover,
              })}
            />
            {item instanceof TucanoModels.Asset && (
              <>
                {/* <Image
                  rootClassName={classnames(style.logo, { [style.hidden]: hover })}
                  height={45}
                  width={45}
                  src={`${consts.image_hostname}${routes.imgdata}?type=${consts.imageTypes.tvChannel}&objectId=${item.providerId}&languageId=${consts.languageId}`}
                  placeholder={PlaceholderType.TRANSPARENT}
                /> */}
              </>
            )}
            {item instanceof TucanoModels.EPG && (
              <>
                <Image
                  rootClassName={classnames(style.logo, {
                    [style.hidden]: hover,
                  })}
                  height={45}
                  width={45}
                  src={`${consts.image_hostname}${routes.imgdata}?type=${
                    consts.imageTypes.tvChannel
                  }&objectId=${
                    item.channelId
                  }&format_w=90&format_h=90&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  placeholder={PlaceholderType.TRANSPARENT}
                />
              </>
            )}
            {item instanceof TucanoModels.Channel && (
              <Image
                rootClassName={classnames(style.logo, {
                  [style.hidden]: hover,
                })}
                height={45}
                width={45}
                src={`${consts.image_hostname}${routes.imgdata}?type=${
                  consts.imageTypes.catchupCover
                }&objectId=${
                  item.channelId
                }&format_w=90&format_h=90&languageId=${getFromLocal(
                  "languageId"
                )}`}
                placeholder={PlaceholderType.TRANSPARENT}
              />
            )}
            {this.isLock() && (
              <div className={style.lockContainer}>
                <div
                  className={classnames(style.padlockContainer, {
                    [style.hidden]: hover,
                  })}
                >
                  <div className={style.padlockIconContainer}>
                    <PadlockLock className={style.padlockLockIcon} />
                  </div>
                </div>
              </div>
            )}

            <div className={classnames({ [style.hidden]: !hover })}>
              {this.isLock() ? (
                <div
                  className={style.lockContainer}
                  onClick={this.unlock.bind(this)}
                >
                  <PlayLock
                    className={classnames(style.playIcon, style.playLockIcon, {
                      [style.playIcon_show]: hover,
                    })}
                  />
                  <PlayUnlock
                    className={classnames(
                      style.playIcon,
                      style.playUnlockIcon,
                      { [style.playIcon_show]: hover }
                    )}
                  />
                </div>
              ) : (
                ((typeof item.isLive === "function" && item.isLive()) ||
                  typeof item.isLive !== "function") && (
                  <Link to={url}>
                    <PlayIcon
                      className={classnames(style.playIcon, {
                        [style.playIcon_show]: hover,
                      })}
                    />
                  </Link>
                )
              )}
            </div>

            <p className={classnames(style.title, { [style.hidden]: !hover })}>
              {item.getTitle()}
            </p>

            {item.getProgress() ? (
              <Fragment>
                <p
                  className={classnames(style.duration, {
                    [style.hidden]: !hover,
                  })}
                >
                  {format(item.getStartTime(), "HH:mm")} -{" "}
                  {format(item.getEndTime(), "HH:mm")}
                </p>
                <ProgressBar
                  rootClassName={style.progress}
                  position={item.getProgress()}
                />
              </Fragment>
            ) : (
              !!item.getStartTime() && (
                <p
                  className={classnames(style.duration, {
                    [style.hidden]: !hover,
                  })}
                >
                  {format(item.getStartTime(), "DD/MM/YYYY")}
                </p>
              )
            )}
          </div>
        ) : (
          <ThumbnailInfos
            displayFavoriteToggle={this.props.dispalyFavorite}
            show={this.state.hover}
            model={this.props.item}
            subscriptionModal={this.subscriptionModal.bind(this)}
          />
        )}
        {itemMetadata && (
          <div className={classnames(style.VODLogo)}>
            <LogoVod
              className={classnames(style.vodData)}
              type={itemMetadata}
              size={40}
            />
          </div>
        )}
      </Link>
    );
  }
}

ThumbnailSearchEPG.defaultProps = {
  asset: {},
  size: PropTypes.object,
  isConnected: false,
  dispalyFavorite: false,
};

ThumbnailSearchEPG.propTypes = {
  asset: PropTypes.object,
  size: PropTypes.object,
  isConnected: PropTypes.bool,
  dispalyFavorite: PropTypes.bool,
};

export default compose(
  connect((state, _props) => {
    return {
      accountStatus: state.account.user?.data?.status,
    };
  })
)(ThumbnailSearchEPG);
