import classnames from "classnames";
import ContentWrapper from "components/ContentWrapper";
import Grid from "components/Grid";
import Header from "components/Header";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import Slide from "components/Slide";
import SlideShow from "components/SlideShow";
import Strate from "components/Strate";
import ThumbnailCover from "components/ThumbnailCover";
import ThumbnailPoster from "components/ThumbnailPoster";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { ImageType } from "web-api/consts/tucano";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageVODDetail extends Component {
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.fetchData();
    }
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetVOD());
  }

  fetchData() {
    const requestedId = parseInt(this.props.match.params.id);
    const languageId = getFromLocal("languageId") ?? consts.languageId;

    this.props.dispatch(
      TucanoActions.getVODContentDetail(requestedId, languageId)
    );

    if (this.props.isConnected) {
      this.props.dispatch(
        TucanoActions.getActiveAssets({
          languageId: languageId,
          allAssets: 1,
          count: 1000,
        })
      );
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { strates, isLoading, category, slideshow, viewport } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    const direction = getFromLocal("layoutDirection");

    return (
      <Fragment>
        {isLoading && <Loader centered={true} />}
        {!isLoading && category && (
          <div className={style.root}>
            {slideshow && (
              <Fragment>
                <LinkBack
                  rootClassName={classnames(
                    direction.toLocaleLowerCase() === "rtl"
                      ? style.linkBackRTL
                      : style.slideBreadcrumbContainer
                  )}
                />
                <SlideShow
                  pagination={slideshow.length > 1 ? true : false}
                  autoplay={slideshow.length > 1 ? true : false}
                  interval={5000}
                >
                  {slideshow.map((slide, index) => (
                    <Slide
                      key={index}
                      asset={slide}
                      isPlayable={true}
                      subscriptionModal={this.subscriptionModal.bind(this)}
                    />
                  ))}
                </SlideShow>
              </Fragment>
            )}
            <ContentWrapper>
              {!slideshow && (
                <Fragment>
                  <LinkBack rootClassName={style.breadcrumbContainer} />
                  <Header>{category?.name}</Header>
                </Fragment>
              )}
              <Fragment>
                {strates.map((strate, index) => {
                  return (
                    <Fragment key={index}>
                      {strate.getLayout() === "grid" &&
                        consts.designMode === 69 && (
                          <Grid item={strate} rootClassName={style.grid}>
                            {strate
                              .getItems()
                              .filter((obj) => obj.title !== "Promoted")
                              .map((item, i) => {
                                return (
                                  <ThumbnailPoster
                                    key={i}
                                    asset={item}
                                    size={thumbnailSize}
                                    grid={true}
                                    subscriptionModal={this.subscriptionModal.bind(
                                      this
                                    )}
                                  />
                                );
                              })}
                          </Grid>
                        )}
                      {strate.getLayout() === "line" && (
                        <Strate item={strate} baseUrl={consts.routes.vod.url}>
                          {strate
                            .getItems()
                            .filter((obj) => obj.title !== "Promoted")
                            .map((item, item_index) => {
                              // Multiple itemId exists for Assets
                              if (consts.designMode === 169) {
                                return (
                                  <ThumbnailCover
                                    key={item_index}
                                    asset={item}
                                    imageType={
                                      item.isAssetTVShow || item.isCategory
                                        ? ImageType.VOD_CATEGORY_BACKGROUND
                                        : ImageType.VOD_BACKGROUND
                                    }
                                    size={thumbnailSize}
                                    layout={"STRATE"}
                                    subscriptionModal={this.subscriptionModal.bind(
                                      this
                                    )}
                                  />
                                );
                              }
                              return (
                                <ThumbnailPoster
                                  key={item_index}
                                  asset={item}
                                  size={thumbnailSize}
                                  layout={"STRATE"}
                                  subscriptionModal={this.subscriptionModal.bind(
                                    this
                                  )}
                                />
                              );
                            })}
                        </Strate>
                      )}
                      {strate.getLayout() === "grid" &&
                        consts.designMode === 169 && (
                          <Grid item={strate} rootClassName={style.gridCover}>
                            {strate
                              .getItems()
                              .filter((obj) => obj.title !== "Promoted")
                              .map((item, i) => {
                                return (
                                  <ThumbnailCover
                                    key={i}
                                    asset={item}
                                    imageType={
                                      item.isAssetTVShow
                                        ? ImageType.VOD_CATEGORY_BACKGROUND
                                        : ImageType.VOD_BACKGROUND
                                    }
                                    size={thumbnailSize}
                                    grid={true}
                                    subscriptionModal={this.subscriptionModal.bind(
                                      this
                                    )}
                                  />
                                );
                              })}
                          </Grid>
                        )}
                    </Fragment>
                  );
                })}
              </Fragment>
            </ContentWrapper>
          </div>
        )}
      </Fragment>
    );
  }
}

export default connect((state, props) => {
  const catalog = TucanoSelectors.getCatalog(state, 2);
  const id = props.match.params.id;
  return {
    isLoading: state.content.vod.loading,
    viewport: state.ui.viewport,
    slideshow: TucanoSelectors.getVODSlideshow(state, false),
    strates: TucanoSelectors.getVODStrates(state, false),
    breadcrumb: TucanoSelectors.getCategoryBreadcrumb(state, catalog, id),
    category: TucanoSelectors.getCategoryById(state, id),
  };
})(PageVODDetail);
