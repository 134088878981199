import classnames from "classnames";
import Arrow from "components/Icons/Arrow";
import CloseIcon from "components/Icons/Close";
import Sad from "components/Icons/Sad";
import SearchIcon from "components/Icons/Search";
import { ImageRatio } from "components/Image";
import Loader from "components/Loader";
import ScrollButton from "components/ScrollButton";
import SearchStrate from "components/SearchStrate";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { removeSpace } from "helpers/url";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class Search extends Component {
  state = {
    inputValue: "",
    inputFocus: false,
    shouldDisplayPopup: true,
    direction: "",
    lastScrollPos: 0,
  };

  constructor(args) {
    super(args);
    this.container = React.createRef();
    this.searchInputRef = React.createRef();
    this.searchTimer = undefined;
    this.handleScroll = this.handleScroll.bind(this);
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
  }

  handleClickOnClose() {
    if (this.props.viewport.type !== "DESKTOP" && this.props.onClick) {
      // if ( this.props.onClick) {
      this.props.onClick();
    }
    this.setState({
      inputValue: "",
      inputFocus: false,
    });
  }

  handleOutsideClick(event) {
    if (
      this.container.current &&
      !this.container.current.contains(event.target)
    ) {
      if (!this.props.location.pathname.includes("search")) {
        if (this.props.viewport.type !== "DESKTOP" && this.props.onClick) {
          this.props.onClick();
        }
        this.setState({
          inputValue: "",
          inputFocus: false,
        });
      }
    }
  }

  handleInputOnChange(event) {
    const term = event.target.value;
    this.setState({ inputValue: encodeURIComponent(term) });
    if (this.props.location.pathname.indexOf("search") !== -1) {
      this.props.history.replace("/search/" + encodeURIComponent(term));
    }
    if (term.length >= 3) {
      // if (term.length > 0) {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer);
      }
      this.searchTimer = setTimeout(() => {
        this.props.dispatch(
          TucanoActions.search(getFromLocal("languageId"), term)
        );
      }, 500);
    } else {
      clearTimeout(this.searchTimer);
      this.props.dispatch(TucanoActions.resetSearchResults());
    }
  }

  handleInputKeyUp(event) {
    const term = event.target.value;
    if (term.length >= 3 && event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      this.props.history.replace("/search/" + encodeURI(term));
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isVisible === false && this.props.isVisible === true) {
      if (this.searchInputRef && this.searchInputRef.current) {
        this.searchInputRef.current.focus();
      }
    }
    if (prevProps.isVisible === true && this.props.isVisible === false) {
      this.setState({
        inputValue: "",
        inputFocus: false,
      });
    }
    if (
      !prevProps.location.pathname.includes("search") &&
      this.props.location.pathname.includes("search")
    ) {
      this.setState({ shouldDisplayPopup: false });
    }
    if (
      prevProps.location.pathname.includes("search") &&
      !this.props.location.pathname.includes("search")
    ) {
      this.setState({ shouldDisplayPopup: true });
    }
    if (
      !this.props.location.pathname.includes("search") &&
      prevProps.location.pathname !== this.props.location.pathname
    ) {
      this.handleClickOnClose();
    }
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this), false);
    document.addEventListener("mousedown", this.handleOutsideClick, false);

    if (this.props.location.pathname.includes("/search/")) {
      this.setState({ shouldDisplayPopup: false });
      const term = window.location.pathname.replace("/search/", "");
      this.setState({ inputValue: encodeURI(removeSpace(term)) });
      this.handleClickSearchIcon();
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this), false);
    document.removeEventListener("mousedown", this.handleOutsideClick, false);

    if (this.searchTimer) {
      clearTimeout(this.searchTimer);
    }
  }

  handleClickSearchIcon() {
    this.searchInputRef.current.focus();
    if (this.props.viewport.type !== "DESKTOP" && this.props.onClick) {
      this.props.onClick();
      this.setState({
        inputValue: "",
        inputFocus: true,
      });
    } else if (
      this.props.viewport.type === "DESKTOP" &&
      this.state.inputValue.length > 3
    ) {
      this.props.history.replace("/search/" + encodeURI(this.state.inputValue));
    }
  }

  handleScroll(_event) {
    if (this.state.lastScrollPos > window.scrollY) {
      this.setState({
        direction: "top",
        lastScrollPos: window.scrollY,
      });
    } else if (this.state.lastScrollPos < window.scrollY) {
      this.setState({
        direction: "bottom",
        lastScrollPos: window.scrollY,
      });
    }
  }

  render() {
    const {
      inputValue,
      inputFocus,
      shouldDisplayPopup,
      lastScrollPos,
      direction,
    } = this.state;
    const {
      isVisible,
      searchResults,
      totalSearchResults,
      loading,
      viewport,
      t,
      isMenuOpen,
    } = this.props;
    const layoutDirection = getFromLocal("layoutDirection");

    return (
      <div
        ref={this.container}
        className={classnames(this.props.className, {
          [style.searchFocus]: inputFocus === true,
          [style.searchBackground]: lastScrollPos > 0,
          [style.searchHidden]:
            viewport.type === "DESKTOP" && direction === "bottom",
        })}
      >
        {lastScrollPos > 0 && (
          <div
            className={classnames(
              !isMenuOpen ? style.backToTop : style.backToTopMenuOpen,
              {
                [style.hideButton]: lastScrollPos <= 0,
                [style[layoutDirection?.toUpperCase()]]: true,
              }
            )}
          >
            <ScrollButton />
          </div>
        )}
        <div
          className={classnames(style.searchContainer, {
            [style[layoutDirection.toUpperCase()]]: true,
            [style.searchContainerFocus]: inputFocus === true,
          })}
        >
          <div
            className={classnames(style.iconSearch, {
              [style[layoutDirection.toUpperCase()]]: true,
              [style.iconFocus]: inputFocus === true,
            })}
            onClick={this.handleClickSearchIcon.bind(this)}
          >
            <SearchIcon className={style.icon} />
          </div>
          <input
            onKeyUp={this.handleInputKeyUp.bind(this)}
            className={classnames(style.input, {
              [style[layoutDirection.toUpperCase()]]: true,
              [style.inputFocus]: inputFocus === true,
            })}
            type="text"
            onChange={this.handleInputOnChange.bind(this)}
            value={decodeURIComponent(inputValue)}
            placeholder={t("Search")}
            ref={this.searchInputRef}
          />
          {shouldDisplayPopup &&
            ((viewport.type === "DESKTOP" && inputValue.length > 0) ||
              (inputFocus === true && viewport.type !== "DESKTOP")) && (
              <div
                className={classnames(
                  style.iconClose,
                  style[`iconClose-${layoutDirection.toUpperCase()}`],
                  {
                    [style.iconFocus]: inputFocus === true,
                  }
                )}
                onClick={this.handleClickOnClose.bind(this)}
              >
                <CloseIcon className={style.icon} />
              </div>
            )}
        </div>
        {!isVisible && (
          <Fragment>
            {shouldDisplayPopup && inputValue && inputValue.length >= 3 && (
              // {shouldDisplayPopup &&inputValue && inputValue.length > 0 && (
              <div
                className={classnames(style.searchResults, {
                  [style[
                    (!isMenuOpen ? "" : "open-") + layoutDirection.toUpperCase()
                  ]]: layoutDirection.toUpperCase() === "RTL",
                })}
              >
                {loading ? (
                  <div className={style.loaderContainer}>
                    <Loader />
                  </div>
                ) : (
                  <Fragment>
                    {searchResults && (
                      <div className={style.searchResultsContainer}>
                        {totalSearchResults === 0 ? (
                          <div className={style.noResults}>
                            <Sad className={style.noResultsIcon} />
                            <p>
                              <Trans>No results</Trans>
                            </p>
                          </div>
                        ) : (
                          <>
                            <Link
                              to={consts.routes.search.url.replace(
                                ":term",
                                encodeURI(inputValue)
                              )}
                              className={style.link}
                            >
                              <span>
                                {t("result_found_in_total", {
                                  count: totalSearchResults,
                                })}
                              </span>
                              <Arrow className={style.linkIcon} />
                            </Link>
                            <hr className={style.separator} />
                          </>
                        )}
                        {searchResults.live &&
                          searchResults.live.length > 0 && (
                            <SearchStrate
                              title={t("On now")}
                              items={searchResults.live}
                              link={
                                consts.routes.search.url.replace(
                                  ":term",
                                  inputValue
                                ) + "#now"
                              }
                              layout={ImageRatio.COVER}
                              t={t}
                            />
                          )}
                        {searchResults.epg && searchResults.epg.length > 0 && (
                          <SearchStrate
                            title={t("Programs")}
                            items={searchResults.epg}
                            link={
                              consts.routes.search.url.replace(
                                ":term",
                                inputValue
                              ) + "#epg"
                            }
                            layout={ImageRatio.COVER}
                            t={t}
                          />
                        )}
                        {searchResults.vod && searchResults.vod.length > 0 && (
                          <SearchStrate
                            title={t("On demand")}
                            items={searchResults.vod}
                            link={
                              consts.routes.search.url.replace(
                                ":term",
                                inputValue
                              ) + "#vod"
                            }
                            layout={
                              consts.designMode === 169
                                ? ImageRatio.COVER
                                : ImageRatio.POSTER
                            }
                            imageType
                            t={t}
                          />
                        )}
                        {searchResults.catchup &&
                          searchResults.catchup.length > 0 && (
                            <SearchStrate
                              title={t("Catchup")}
                              items={searchResults.catchup}
                              link={
                                consts.routes.search.url.replace(
                                  ":term",
                                  inputValue
                                ) + "#catchup"
                              }
                              layout={ImageRatio.COVER}
                              t={t}
                            />
                          )}
                      </div>
                    )}
                  </Fragment>
                )}
              </div>
            )}
          </Fragment>
        )}
      </div>
    );
  }
}

Search.defaultProps = {};

Search.propTypes = {};

export default withRouter(
  compose(
    connect((state) => {
      return {
        loading: state.search.loading,
        totalSearchResults: TucanoSelectors.getTotalSearchResults(state),
        searchResults: TucanoSelectors.getSearchResults(state),
      };
    }),
    withTranslation()
  )(Search)
);
