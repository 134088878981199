import InlineSVG from "inline-svg-react";
import React from "react";

const Attention = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Attention.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Attention.svg");
    } catch (err) {
      return (
        <svg height="71" viewBox="0 0 81 71" width="81" {...props}>
          <g fill="none" transform="translate(-1.5 -6)">
            <path
              d="m44.7055926 7.5520732 37.2986723 64.9540153c.8250645 1.436814.3291436 3.2704289-1.1076704 4.0954934-.4546071.2610499-.9696838.3984181-1.4939115.3984181h-74.24490824c-1.65685425 0-3-1.3431458-3-3 0-.520068.135198-1.0312033.39232977-1.4832586l36.94623597-64.95401526c.8191811-1.4401765 2.6507523-1.94359275 4.0909288-1.12441166.4655025.26478064.851641.64934303 1.1183233 1.11375872z"
              fill="#ffca30"
            />
            <path
              d="m44.1303748 56.6030273 1.5688477-32.5766601h-6.3676758l1.5688477 32.5766601zm-1.6611328 13.2890625c1.1689453 0 2.081543-.3640136 2.737793-1.092041.65625-.7280273.984375-1.748291.984375-3.060791 0-1.3535156-.322998-2.3891601-.9689941-3.1069336-.6459961-.7177734-1.5637207-1.0766601-2.7531739-1.0766601-2.4609375 0-3.6914062 1.3945312-3.6914062 4.1835937 0 1.4765625.3486328 2.5378418 1.0458984 3.1838379s1.5791016.9689941 2.6455078.9689941z"
              fill="#000"
            />
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default Attention;
