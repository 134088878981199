import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import PlayIcon from "components/Icons/Play";
import PlayLock from "components/Icons/PlayLock";
import PlayUnlock from "components/Icons/PlayUnlock";
import Image, { PlaceholderType } from "components/Image";
import ProgressBar from "components/ProgressBar";
import SeasonSelection from "components/SeasonSelection";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class PlayerTVShowNavigator extends Component {
  constructor(args) {
    super(args);
    const activeSeason = args.seasons.findIndex((season, _index) => {
      return season
        .getAssets()
        .some((episode) => episode.getId() === args.assetId);
    });
    this.state = {
      activeSeason: activeSeason === -1 ? 0 : activeSeason,
    };
    this.activeEpisodeRef = React.createRef();
    this.episodeContainerRef = React.createRef();
  }

  componentDidMount() {
    if (this.activeEpisodeRef && this.activeEpisodeRef.current) {
      this.episodeContainerRef.current.scrollTo(
        0,
        this.activeEpisodeRef.current.offsetTop
      );
    }
  }

  unlock(episode, e) {
    e.stopPropagation();
    e.preventDefault();

    const { accountStatus, dispatch } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(episode);
    }
  }

  isLock(episode) {
    const { accountStatus } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!consts.padlocks) return false;
    if (episode.isCategory) return false;
    if (episode.isAssetTVShow && !episode.isAssetTVShowEpisode) return false;
    if (episode.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !episode.userHasAccess;
  }

  render() {
    const { seasons, isFullscreen, match } = this.props;
    const { activeSeason } = this.state;
    const direction = getFromLocal("layoutDirection");
    return (
      <div>
        {seasons && (
          <Fragment>
            <SeasonSelection
              rootClassName={style.select}
              seasons={seasons}
              activeItem={activeSeason}
              onChange={(index) => {
                this.setState({ activeSeason: index });
              }}
            />
            <div className={style.container} ref={this.episodeContainerRef}>
              {seasons
                .filter((season, index) => index === activeSeason)
                .map((season) => {
                  return season
                    .getAssets()
                    .filter(Boolean)
                    .map((episode, index) => {
                      if (episode.getId() === Number(match.params.assetId)) {
                        return (
                          <div
                            key={index}
                            className={classnames(
                              style.episode,
                              style.activeEpisode
                            )}
                            ref={this.activeEpisodeRef}
                          >
                            <div className={style.imageContainer}>
                              <Image
                                rootClassName={style.image}
                                width={160}
                                height={90}
                                src={`${consts.image_hostname}${
                                  routes.imgdata
                                }?type=${
                                  consts.imageTypes.movie_episode_cover
                                }&objectId=${episode.getId()}&format_w=160&format_h=90&languageId=${getFromLocal(
                                  "languageId"
                                )}`}
                                placeholder={PlaceholderType.PRIMARY}
                              />
                              {!!episode.getSeekTime() && (
                                <ProgressBar
                                  position={episode.getProgress()}
                                  rootClassName={style.progressBar}
                                />
                              )}
                            </div>
                            <div
                              className={classnames(style.wrapper, {
                                [style.wrapperRTL]: direction === "rtl",
                              })}
                            >
                              <p className={style.metadata}>
                                <Trans>Season</Trans>
                                {episode.getSeasonNumber()} -{" "}
                                <Trans>Episode</Trans>{" "}
                                {episode.getEpisodeNumber()}
                              </p>
                              <p className={style.title}>
                                {episode.getTitle()}
                              </p>
                            </div>
                          </div>
                        );
                      }
                      return (
                        <Link
                          key={index}
                          to={
                            !this.isLock(episode) && {
                              pathname: `/player/${episode.getId()}`,
                              state: { fullScreen: isFullscreen },
                            }
                          }
                          className={style.episode}
                          replace
                        >
                          <div className={style.imageContainer}>
                            <Image
                              rootClassName={style.image}
                              width={160}
                              height={90}
                              src={`${consts.image_hostname}${
                                routes.imgdata
                              }?type=${
                                consts.imageTypes.movie_episode_cover
                              }&objectId=${episode.getId()}&format_w=160&format_h=90&languageId=${getFromLocal(
                                "languageId"
                              )}`}
                              placeholder={PlaceholderType.PRIMARY}
                            />
                            {!!episode.getSeekTime() && (
                              <ProgressBar
                                position={episode.getProgress()}
                                rootClassName={style.progressBar}
                              />
                            )}
                          </div>
                          <div
                            className={classnames(style.wrapper, {
                              [style.wrapperRTL]: direction === "rtl",
                            })}
                          >
                            <p className={style.metadata}>
                              <Trans>Season</Trans> {episode.getSeasonNumber()}{" "}
                              - <Trans>Episode</Trans>{" "}
                              {episode.getEpisodeNumber()}
                            </p>
                            <p className={style.title}>{episode.getTitle()}</p>
                          </div>
                          <div className={style.hoverContainer}>
                            {this.isLock(episode) ? (
                              <div
                                className={style.lockContainer}
                                onClick={this.unlock.bind(this, episode)}
                              >
                                <PlayLock
                                  className={classnames(
                                    style.playIcons,
                                    style.playLockIcon
                                  )}
                                />
                                <PlayUnlock
                                  className={classnames(
                                    style.playIcons,
                                    style.playUnlockIcon
                                  )}
                                />
                              </div>
                            ) : (
                              <PlayIcon className={style.playIcons} />
                            )}
                          </div>
                        </Link>
                      );
                    })
                    .filter(Boolean);
                })}
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

PlayerTVShowNavigator.defaultProps = {
  seasons: [],
  activeSeason: 0,
};

PlayerTVShowNavigator.propTypes = {
  seasons: PropTypes.array,
};

export default withTranslation()(
  withRouter(
    connect((state, _props) => ({
      accountStatus: state.account.user?.data?.status,
    }))(PlayerTVShowNavigator)
  )
);
