import * as Sentry from "@sentry/react";
import {
  setCustomerAuthToken,
  setDeviceAuthToken,
  setDeviceLimitError,
  setProfileToken,
} from "actions/session";
import consts from "consts/consts";
import {
  getFromLocal,
  removeFromLocal,
  saveInLocal,
} from "helpers/localStorage";
import { isIOS } from "helpers/utility";
import get from "lodash.get";
import { applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import routes from "web-api/consts/routes";
import { TucanoActions, TucanoMiddlewares } from "web-api/main";

function compareVersion(version, range) {
  const string = range + "";
  const n = +(string.match(/\d+/) || NaN);
  const op = string.match(/^[<>]=?|/)[0];
  // eslint-disable-next-line
  return comparator[op] ? comparator[op](version, n) : version === n || n !== n;
}
const comparator = {
  "<": function (a, b) {
    return a < b;
  },
  "<=": function (a, b) {
    return a <= b;
  },
  ">": function (a, b) {
    return a > b;
  },
  ">=": function (a, b) {
    return a >= b;
  },
};
let key = consts.apiKey;
const userAgent = ((navigator && navigator.userAgent) || "").toLowerCase();
const match = userAgent.match(/version\/(\d+).+?safari/);
const isSafari = (match !== null && compareVersion(match[1], "")) || isIOS();
saveInLocal("isSafari", isSafari);
if (isSafari) {
  if (consts.safariApiKey && consts.safariApiKey !== null) {
    key = consts.safariApiKey;
  }
}
const requestsConfiguration = {
  base: consts.baseUrl,
  defaultHeaders: {
    Accept: "application/json",
    "Content-type": "application/x-www-form-urlencoded",
    "X-AN-WebService-Version": 2,
    "X-AN-WebService-IdentityKey": key,
  },
  hooks: {
    onRequest: (store, action, request) => {
      store.dispatch(TucanoActions.resetServerError());
      //this was used in case we send content-type in the api request
      // if (action.request.headers) {
      //   request.headers = { ...action.request.headers, ...{ "X-AN-WebService-IdentityKey": consts.apiKey } };
      // }
      const session = store.getState().session;
      const requestRequireCAT = action.request.requireCAT;

      if (session.customerAuthToken && requestRequireCAT !== false) {
        request.headers["X-AN-WebService-CustomerAuthToken"] =
          session.customerAuthToken;
      }

      if (session.deviceAuthToken) {
        request.headers["X-AN-WebService-DeviceAuthToken"] =
          session.deviceAuthToken;
      }

      if (session.profileToken) {
        request.headers["X-AN-WebService-ProfileToken"] = session.profileToken;
      }

      if (
        request.url === routes.graphQlLogin &&
        consts.graphBaseUrl &&
        consts.graphQLGrantType &&
        consts.graphQLGrantType !== "password"
      ) {
        request.base = consts.graphBaseUrl;
      }
    },

    onComplete: async (store, action, request, response) => {
      const session = store.getState().session;
      let newCustomerAuthToken = get(response, "body.result.newAuthToken");
      if (!newCustomerAuthToken) {
        newCustomerAuthToken = get(response, "body.access_token");
      }
      if (
        newCustomerAuthToken &&
        session.customerAuthToken !== newCustomerAuthToken &&
        (action.request.url === routes.login ||
          action.request.url === routes.graphQlLogin ||
          action.request.url === routes.casAddDevice ||
          action.request.url === routes.syncFromTve ||
          action.request.url === routes.googleAuth ||
          action.request.url === routes.facebookAuth ||
          action.request.url === routes.appleAuth ||
          action.request.url === routes.casAuth)
      ) {
        store.dispatch(setCustomerAuthToken(newCustomerAuthToken));
        let updatedLocalStorage = getFromLocal(consts.storageKeys.tokens);
        if (!updatedLocalStorage) {
          updatedLocalStorage = {};
        }
        updatedLocalStorage.customerAuthToken = newCustomerAuthToken;
        saveInLocal(consts.storageKeys.tokens, updatedLocalStorage);
      }

      const deviceAuthToken = get(response, "body.result.deviceAuthToken");

      if (deviceAuthToken && session.deviceAuthToken !== deviceAuthToken) {
        store.dispatch(setDeviceAuthToken(deviceAuthToken));
        let updatedLocalStorage = getFromLocal(consts.storageKeys.tokens);
        if (!updatedLocalStorage) {
          updatedLocalStorage = {};
        }
        updatedLocalStorage.deviceAuthToken = deviceAuthToken;
        saveInLocal(consts.storageKeys.tokens, updatedLocalStorage);
      }

      const profileToken = get(response, "body.result.profileToken");
      if (profileToken && session.profileToken !== profileToken) {
        store.dispatch(setProfileToken(profileToken));
        let updatedLocalStorage = getFromLocal(consts.storageKeys.tokens);
        if (!updatedLocalStorage) {
          updatedLocalStorage = {};
        }
        updatedLocalStorage.profileToken = profileToken;
        saveInLocal(consts.storageKeys.tokens, updatedLocalStorage);
      }
    },

    onError: (store, action, request, response) => {
      // eslint-disable-next-line no-console
      console.error(
        "Action Request Error",
        response,
        action,
        get(response, "body.error.code"),
        get(response, "body.code")
      );

      // Catch Add device Limit
      handleCasAddDeviceLimitError(store, action, request, response);
      // Catch An Authentication Error (AuthToken Not Valid)
      handleAuthenticationErrors(store, action, request, response);
    },
  },
};

const handleCasAddDeviceLimitError = (store, action, request, response) => {
  const url = get(action, "request.url");

  if (
    url === "/proxy/casAddDevice" &&
    get(response, "body.error.code") === -11
  ) {
    store.dispatch(setDeviceLimitError());
    store.dispatch(TucanoActions.getDevices());
  }
};

const handleAuthenticationErrors = (store, action, request, response) => {
  const url = get(action, "request.url");
  if (
    get(response, "body.error.code") === -1 &&
    get(response, "body.error.details") === "Invalid API key"
  ) {
    store.dispatch(TucanoActions.setServerError());
    return;
  }

  if (
    get(response, "body.error.code") === -1 &&
    get(response, "body.error.message") === "Invalid auth" &&
    !["/proxy/loginDevice", "/oauth/token", "/proxy/login"].includes(url)
  ) {
    store.dispatch(TucanoActions.refreshToken()).then((response) => {
      if (response && response?.newAuthToken) {
        store.dispatch(setCustomerAuthToken(response.newAuthToken));
        let updatedLocalStorage = getFromLocal(consts.storageKeys.tokens);
        updatedLocalStorage.customerAuthToken = response.newAuthToken;
        saveInLocal(consts.storageKeys.tokens, updatedLocalStorage);
        window.location.href = window.location;
      }
    });
    return;
  }

  if (
    url === "/proxy/login" ||
    url === "/oauth/token" ||
    url === "/proxy/askResetPassword" ||
    url === "/proxy/resetPassword" ||
    url === "/proxy/hierarchy" ||
    url === "/proxy/menuOrder" ||
    url === "/proxy/homeContent" ||
    url === "/proxy/validateAccount" ||
    get(response, "body.error.code") !== -1
  )
    return;

  store.dispatch(TucanoActions.logout()).then(() => {
    removeFromLocal(consts.storageKeys.tokens);
    removeFromLocal("productId");
    removeFromLocal("TucanoOrderId");
    window.location = "/";
  });
};

const requestsMiddlewareInstance = new TucanoMiddlewares.requestsMiddleware(
  requestsConfiguration
);

const sentryLogMiddleware = ({ getState }) => {
  return (next) => (action) => {
    Sentry.captureMessage("State:", getState());
    return next(action);
  };
};

export default () => {
  return applyMiddleware(
    thunkMiddleware,
    requestsMiddlewareInstance,
    sentryLogMiddleware
  );
};
