import React, { Component } from "react";

export default class PlayerVolume extends Component {
  render() {
    const { isMuted, onClick, rootClassName } = this.props;
    return (
      <svg width={24} height={24} onClick={onClick} className={rootClassName}>
        {!isMuted && (
          <path d="M9 8l8.427-5.899a1 1 0 011.573.82v18.158a1 1 0 01-1.573.82L9 16H5a1 1 0 01-1-1V9a1 1 0 011-1h4z" />
        )}
        {isMuted && (
          <g transform=" translate(2.3 1)">
            <path d="M16 3.551L2.356 15H2a1 1 0 01-1-1V8a1 1 0 011-1h4l8.427-5.899a1 1 0 011.573.82v1.63zm0 5.222v11.306a1 1 0 01-1.573.82l-7.021-4.915L16 8.773zm2.788-6.255l1.285 1.532L1.688 19.477.403 17.944 18.788 2.518z" />
          </g>
        )}
      </svg>
    );
  }
}
