import { setLoginShow } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import ArrowHeading from "components/Icons/Arrowheading";
import consts from "consts/consts";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import Collapsible from "react-collapsible";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./style.module.css";

class PageConsent extends Component {
  state = {
    isManage: false,
    concentList: {},
  };

  componentDidMount() {
    const { isManage, onlyGdprList } = this.props;
    const concentList = getFromLocal("concentList");

    if (consts.appModules.gdpr !== true) {
      this.props.history.replace("/");
    }
    if (isManage) this.setState({ isManage });

    let obj = {};
    const gdprConfig = consts.gdprConfig
      ? typeof consts.gdprConfig === "string"
        ? JSON.parse(consts.gdprConfig)
        : JSON.parse(JSON.stringify(consts.gdprConfig))
      : {};
    //(typeof consts.gdprConfig === "string" ? JSON.parse(consts.gdprConfig) : JSON.parse(JSON.stringify(consts.gdprConfig)))
    for (const [key, _value] of Object.entries(gdprConfig)) {
      let isActivated = false;
      if (
        (concentList && concentList[key] && concentList[key].isActive) ||
        (!onlyGdprList && !concentList)
      )
        isActivated = true;
      obj[key] = isActivated;
    }

    this.setState({ concentList: obj });
  }

  toggleManageModal() {
    const { onManageConcentList } = this.props;
    const { isManage } = this.state;

    this.setState({ isManage: !isManage });
    if (onManageConcentList) {
      onManageConcentList(!isManage);
    }
  }

  onApplayActionOnAll = (isAccepted) => {
    const { handleActions, dispatch, history, isConnected } = this.props;
    const concentList = {};
    const gdprConfig = consts.gdprConfig
      ? typeof consts.gdprConfig === "string"
        ? JSON.parse(consts.gdprConfig)
        : JSON.parse(JSON.stringify(consts.gdprConfig))
      : {};

    Object.keys(gdprConfig).map((one) => {
      const obj = {
        isActive: isAccepted,
      };
      if (gdprConfig[one].blockedON && gdprConfig[one].blockedON === "true") {
        obj.isActive = true;
      }
      concentList[one] = obj;
    });
    saveInLocal("concentList", concentList);
    handleActions("MODAL_CONSENT");
    if (
      history.location.pathname !== consts.routes.signIn.url &&
      consts.landOnLogin &&
      !isConnected
    ) {
      dispatch(setLoginShow(true));
    }
  };

  toggleUseCode = async (value, name) => {
    this.setState((prevState) => ({
      concentList: {
        ...prevState.concentList,
        [name]: value,
      },
    }));
  };

  savePreferences = () => {
    const { handleActions } = this.props;

    let obj = {};
    for (const [key, value] of Object.entries(this.state.concentList)) {
      obj[key] = { isActive: value };
    }

    saveInLocal("concentList", obj);
    handleActions("MODAL_CONSENT");
  };

  componentDidUpdate(prevProps, _prevState) {
    const { manageConsentListOpned } = this.props;
    const { isManage } = this.state;

    if (
      prevProps.manageConsentListOpned &&
      manageConsentListOpned === false &&
      isManage
    ) {
      this.setState({ isManage: false });
    }
  }

  render() {
    const { isManage, concentList } = this.state;
    const { onlyGdprList, handleActions, t } = this.props;
    const direction = getFromLocal("layoutDirection");

    const gdprConfig = consts.gdprConfig
      ? typeof consts.gdprConfig === "string"
        ? JSON.parse(consts.gdprConfig)
        : JSON.parse(JSON.stringify(consts.gdprConfig))
      : {};
    /*const languages = consts.enum.languageId;
    const selectedLang = Object.values(languages).filter((item) => {
      return item.value === consts.languageId;
    });*/

    return (
      <Fragment>
        <div className={style.root}>
          <div className={style.content}>
            {!isManage ? (
              <Fragment>
                <h3>
                  <Trans t={t}>Our use of cookies and other technologies</Trans>
                </h3>
                <p className={style.text}>
                  {t(
                    "The privacy and the security of your information is important to us."
                  )}
                </p>
                <p className={style.text}>
                  {t(
                    "When you interact with our service, we and our partners may collect certain information and some of them might be collected automatically as your interactions with activities."
                  )}{" "}
                  {t(
                    " We use this information to improve our services and to customise the experience according to your interests."
                  )}
                </p>
                <p className={style.text}>
                  <Trans t={t}>
                    You can manage your consent at any time by going to
                    ”Settings”.
                  </Trans>
                </p>
                <p className={style.text}>
                  <Trans t={t}>
                    Please note, however, that if you refuse to accept cookies
                    and our other technologies, you might not be able to use all
                    of the features we offer and some of our pages might not
                    display properly.
                  </Trans>
                </p>
              </Fragment>
            ) : (
              <Fragment>
                {!onlyGdprList && (
                  <>
                    <h3>
                      <Trans>Manage your consent</Trans>
                    </h3>
                    <p className={style.text}>
                      <Trans t={t}>
                        When you interact with our service, we and our partners
                        may collect certain information and some of them might
                        be collected automatically as your interactions with
                        activities.
                      </Trans>
                    </p>
                    <p className={style.text}>
                      <Trans t={t}>
                        You can manage your consent at any time.
                      </Trans>
                    </p>

                    <hr />
                  </>
                )}

                <h3>
                  <Trans>Cookies management</Trans>
                </h3>
                {/* <p className={style.text}>
                  <Trans>
                    Our partners may use data for analytics and measurement to understand how their services are used.
                  </Trans>
                </p> */}

                {consts.appModules.gdpr &&
                  gdprConfig &&
                  Object.entries(gdprConfig).map((item, index) => (
                    <Fragment key={index}>
                      <Collapsible
                        open={false}
                        trigger={
                          <span className={style.arrow}>
                            <ArrowHeading />
                          </span>
                        }
                        triggerSibling={() => (
                          <div className={style.arrowContainer}>
                            <p className={style.categoryTitle}>
                              <Trans>{item[1].key}</Trans>
                            </p>
                            <Checkbox
                              onChange={this.toggleUseCode}
                              checked={concentList[item[0]]}
                              type={"SWITCH"}
                              name={item[1].key}
                              itemKey={item[0]}
                              rootClassName={style.switch}
                              disabled={item[1].blockedON}
                            />
                          </div>
                        )}
                      >
                        <div className={style.switchElement}>
                          <p className={style.description}>
                            <Trans>{item[1].desc}</Trans>
                          </p>
                          {item[1].links.map((link, i) => (
                            <Fragment key={i}>
                              <a
                                href={link}
                                target="_blank"
                                className={style.linkPolicy}
                                rel="noreferrer"
                              >
                                Their privacy policy
                              </a>
                            </Fragment>
                          ))}
                        </div>
                      </Collapsible>
                    </Fragment>
                  ))}
              </Fragment>
            )}
          </div>
          {!isManage ? (
            <div
              className={classnames(style.bottomContent, {
                [style.reveseBottomContent]:
                  direction.toLocaleLowerCase() === "rtl",
              })}
            >
              <a
                onClick={this.toggleManageModal.bind(this)}
                className={style.link}
              >
                {t("Manage")}
              </a>
              <div className={style.actionButtons}>
                <Button
                  rootClassName={style.button}
                  type={ButtonType.BORDERED}
                  onClick={() => this.onApplayActionOnAll(false)}
                >
                  <Trans t={t}>Refuse all</Trans>
                </Button>
                <Button
                  rootClassName={style.button}
                  type={ButtonType.NORMAL}
                  onClick={() => this.onApplayActionOnAll(true)}
                >
                  <Trans t={t}>Accept all</Trans>
                </Button>
              </div>
            </div>
          ) : (
            <div className={style.bottomContentEnd}>
              <div className={style.actionButtons}>
                <Button
                  rootClassName={style.button}
                  type={ButtonType.BORDERED}
                  onClick={() =>
                    onlyGdprList
                      ? handleActions()
                      : this.toggleManageModal.bind(this)()
                  }
                >
                  <Trans>Cancel</Trans>
                </Button>
                <Button
                  rootClassName={style.button}
                  type={ButtonType.NORMAL}
                  onClick={this.savePreferences.bind(this)}
                >
                  <Trans>Save my preferences</Trans>
                </Button>
              </div>
            </div>
          )}
        </div>
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
    };
  }),
  withTranslation()
)(PageConsent);
