import classnames from "classnames";
import Dropdown from "components/Dropdown";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { convertTimestampToDate } from "web-api/helpers/dataHelper";

import style from "./style.module.css";

class SeasonSelection extends Component {
  state = { selectedSeason: "" };
  onChange(event) {
    const { onChange } = this.props;
    if (onChange) {
      this.props.onChange(event.index);
    }
  }

  render() {
    const { seasons, activeItem, rootClassName, t, i18n } = this.props;
    const options = seasons.map((item, index) => {
      return {
        index,
        label: Number.isInteger(Number(item.name))
          ? `${t(convertTimestampToDate(item.name, i18n.language))}`
          : item.name,
      };
    });

    return (
      <div className={classnames(style.customSelect, rootClassName)}>
        <div className={classnames(style.selectContainer, rootClassName)}>
          <Dropdown
            label={options[activeItem]?.label}
            selfClosing={true}
            title={options[activeItem]}
            dropdownIcon={true}
            autoWidth={true}
            handleClick={this.onChange.bind(this)}
            data={options}
          />
        </div>
        {/*} <Select
          isSearchable={false}
          options={options}
          value={options[activeItem]}
          className={classnames(style.selectContainer, rootClassName)}
          classNamePrefix={"react-select"}
          onChange={this.onChange.bind(this)}
        /> */}
      </div>
    );
  }
}

SeasonSelection.defaultProps = {
  seasons: [],
  activeItem: 0,
  rootClassName: "",
};

SeasonSelection.propTypes = {
  season: PropTypes.array,
  activeItem: PropTypes.number,
  rootClassName: PropTypes.string,
  theme: PropTypes.string,
};
export default withTranslation()(SeasonSelection);
