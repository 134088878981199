import classnames from "classnames";
import Arrow from "components/Icons/Arrow";
import ArrowHeading from "components/Icons/Arrowheading";
import { LinkDuo } from "components/LinkDuo";
import consts from "consts/consts";
import { isToday, isYesterday } from "date-fns";
import Flickity from "flickity";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import { CapitalizeFirstLetter } from "helpers/utility";
import debounce from "lodash.debounce";
import moment from "moment";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";

import style from "./style.module.css";

class Strate extends Component {
  constructor(props) {
    super(props);

    this.state = {
      flickityReady: false,
      showLeftArrow: false,
      showRightArrow: false,
      showSeeAll: false,
      options: {
        pageDots: false,
        prevNextButtons: false,
        contain: true,
        cellAlign: getFromLocal("layoutDirection") === "rtl" ? "right" : "left",
        rightToLeft: getFromLocal("layoutDirection") === "rtl",
        groupCells: true,
        selectedAttraction: 0.05,
        friction: 0.8,
        lazyLoad: true,
      },
    };

    this.refreshFlickity = this.refreshFlickity.bind(this);
  }

  componentDidMount() {
    this.flickity = new Flickity(this.flickityNode, this.state.options || {});

    this.setState({
      flickityReady: true,
    });

    this.flickity.on("ready", () => this.checkIndex());
    this.flickity.on("select", () => this.checkIndex());

    window.addEventListener(
      "resize",
      debounce(this.refreshFlickity, 1000),
      false
    );
  }

  refreshFlickity() {
    this.checkIndex();
    this.flickity.reloadCells();
    this.flickity.resize();
    this.flickity.updateDraggable();
  }

  componentWillUnmount() {
    window.removeEventListener(
      "resize",
      debounce(this.refreshFlickity, 1000),
      false
    );
    // this.flickity.destroy();
  }

  componentDidUpdate(prevProps, prevState) {
    const flickityDidBecomeActive =
      !prevState.flickityReady && this.state.flickityReady;
    const childrenDidChange =
      prevProps.children.length !== this.props.children.length;

    if (flickityDidBecomeActive || childrenDidChange) {
      this.refreshFlickity();

      /* refresh when all element is display (eg. menu display of signup to home page) */
      setTimeout(() => {
        this.refreshFlickity();
      }, 500);
    }

    if (prevProps.isMenuOpen !== this.props.isMenuOpen) {
      debounce(() => this.flickity.resize(), 500)();
    }
  }

  checkIndex() {
    if (getFromLocal("layoutDirection") === "rtl") {
      this.setState({
        showLeftArrow:
          this.flickity.selectedIndex < this.flickity.slides.length - 1,
      });
      this.setState({ showRightArrow: this.flickity.selectedIndex > 0 });
    } else {
      this.setState({
        showRightArrow:
          this.flickity.selectedIndex < this.flickity.slides.length - 1,
      });
      this.setState({ showLeftArrow: this.flickity.selectedIndex > 0 });
    }
    this.setState({ showSeeAll: this.flickity.slides.length > 1 });
  }

  handleLeftArrow() {
    if (getFromLocal("layoutDirection") === "rtl") {
      this.flickity.next();
    } else {
      this.flickity.previous();
    }
  }

  handleRightArrow() {
    if (getFromLocal("layoutDirection") === "rtl") {
      this.flickity.previous();
    } else {
      this.flickity.next();
    }
  }

  renderPortal() {
    if (!this.flickityNode) {
      return null;
    }

    const mountNode = this.flickityNode.querySelector(".flickity-slider");

    if (mountNode) {
      return ReactDOM.createPortal(this.props.children, mountNode);
    }
  }

  render() {
    const { item, t, i18n } = this.props;
    const url = getRealSlug(item.getUrl(this.props.baseUrl));

    let title;
    const parseIntTitle = parseInt(item.getTitle() * 1000);
    if (isNaN(parseIntTitle) === false && item.getTitle() !== "") {
      title = CapitalizeFirstLetter(
        `${moment(new Date(parseIntTitle))
          .locale(i18n.language == "ar" ? "ar-MA" : i18n.language)
          .format(consts.catchupDateFormat)
          .toLowerCase()}`
      );
      //console.log(title);
      if (isToday(new Date(parseIntTitle))) {
        title = t("Today");
      }
      if (isYesterday(new Date(parseIntTitle))) {
        title = t("Yesterday");
      }
    } else {
      title = item.getTitle();
    }
    const direction = getFromLocal("layoutDirection");
    return (
      <div className={style.container}>
        {url !== "/" && this.props.seeAll ? (
          <LinkDuo className={style.link} to={url}>
            <h2 className={style.title}>
              {this.props.offsetTop ? <>{t(title)}</> : <>{title}</>}
              {this.state.showSeeAll && (
                <div
                  className={classnames(style.seeAll, {
                    [style[direction?.toUpperCase()]]: true,
                  })}
                >
                  <Trans>See All</Trans>
                  <Arrow className={style.seeAllIcon} />
                </div>
              )}
            </h2>
          </LinkDuo>
        ) : (
          <h2 className={style.title}>{title}</h2>
        )}
        <div
          className={style.flickity}
          key="flickityBase"
          ref={(node) => (this.flickityNode = node)}
        />
        {this.renderPortal()}
        {this.state.showLeftArrow && (
          <div
            className={style.leftArrow}
            style={{ marginTop: this.props.offsetTop }}
            onClick={this.handleLeftArrow.bind(this)}
          >
            <ArrowHeading className={style.arrow} />
          </div>
        )}

        {this.state.showRightArrow && (
          <div
            className={style.rightArrow}
            style={{ marginTop: this.props.offsetTop }}
            onClick={this.handleRightArrow.bind(this)}
          >
            <ArrowHeading className={style.arrow} />
          </div>
        )}
      </div>
    );
  }
}

Strate.defaultProps = {
  item: {},
  baseUrl: "",
  offsetTop: 0,
  seeAll: true,
};

Strate.propTypes = {
  item: PropTypes.object,
  baseUrl: PropTypes.string,
  offsetTop: PropTypes.number,
  seeAll: PropTypes.bool,
};

export default compose(
  connect((state) => {
    return {
      isMenuOpen: state.ui.menuOpen,
    };
  }),
  withTranslation()
)(Strate);
