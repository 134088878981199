import classnames from "classnames";
import ArrowHeading from "components/Icons/Arrowheading";
import { getFromLocal } from "helpers/localStorage";
import { redirectTo } from "helpers/utility";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import style from "./style.module.css";

class LinkBack extends Component {
  render() {
    const canNavigateBack = window.history.length > 3;
    const direction = getFromLocal("layoutDirection");
    const { t } = this.props;
    return (
      <Fragment>
        {canNavigateBack && (
          <div
            className={classnames(style.root, this.props.rootClassName, {
              [style.reverseDirectionIcon]:
                direction.toLocaleLowerCase() === "rtl",
            })}
            onClick={() => {
              if (this.props.history.length > 1) {
                this.props.history.goBack();
              } else {
                redirectTo(this.props);
              }
            }}
          >
            <span
              className={classnames(style.icon, {
                [style.rotateIconArrowInArabic]:
                  direction.toLocaleLowerCase() === "rtl",
              })}
            >
              <ArrowHeading />
            </span>
            <span className={style.label}>
              <Trans t={t}>BACK</Trans>
            </span>
          </div>
        )}
      </Fragment>
    );
  }
}

export default withRouter(
  connect((state, _props) => {
    return {
      previousRoute: state.ui.previousRoute,
      previousStableRoute: state.ui.previousStableRoute,
    };
  })(LinkBack)
);
