import InlineSVG from "inline-svg-react";
import React from "react";

const SkipIntro = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/SkipIntro.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/SkipIntro.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 154 135" fill="none" {...props}>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M123.75 63.0009L71.249 0H100.947L153.448 63.0009L93.4488 135H63.7505L123.75 63.0009Z"
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M56.2501 67.4997L0.00032205 0H29.6986L85.9484 67.4997L29.6983 135H0L56.2501 67.4997Z"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default SkipIntro;
