import InlineSVG from "inline-svg-react";
import React from "react";

const Account = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Account.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Account.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M9.78219254,11.1248684 C8.67904388,10.2033793 7.97727273,8.81738042 7.97727273,7.26742975 C7.97727273,4.49373864 10.2258392,2.24545455 13,2.24545455 C15.7741608,2.24545455 18.0227273,4.49373864 18.0227273,7.26742975 C18.0227273,8.81738042 17.3209561,10.2033793 16.2178075,11.1248684 C19.3528961,12.3590838 21.5681818,15.3432007 21.5681818,18.8367636 L21.5681818,23.7928364 L19.7954545,23.7928364 L19.7954545,18.8367636 C19.7954545,15.2280592 16.7594611,12.2909091 13,12.2909091 C9.2405389,12.2909091 6.20454545,15.2280592 6.20454545,18.8367636 L6.20454545,23.7928364 L4.43181818,23.7928364 L4.43181818,18.8367636 C4.43181818,15.3432007 6.64710394,12.3590838 9.78219254,11.1248684 L9.78219254,11.1248684 Z M9.75,7.26742975 C9.75,9.0628748 11.2049415,10.5181818 13,10.5181818 C14.7950585,10.5181818 16.25,9.0628748 16.25,7.26742975 C16.25,5.47283873 14.7951604,4.01818182 13,4.01818182 C11.2048396,4.01818182 9.75,5.47283873 9.75,7.26742975 Z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default Account;
