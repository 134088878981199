import classnames from "classnames";
import { LinkDuo } from "components/LinkDuo";
import consts from "consts/consts";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import isExternal from "is-url-external";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";
import Popup from "reactjs-popup";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const sizeIconsKeys = {
  Small: "iconSizeS",
  Medium: "iconSizeM",
  Large: "iconSizeL",
};
class MenuItem extends Component {
  state = {
    languageIdMapping: consts.languageIdMapping,
    open: false,
    langEdited: false,
  };
  checkActive = (condition, location) => {
    if (!location) return false;
    return condition;
  };
  handleChangeLanguage = async (value) => {
    const { activeProfile } = this.props;
    if (activeProfile) {
      const userInfos = {
        ...activeProfile,
        language: value,
      };
      this.props
        .dispatch(TucanoActions.createOrUpdateProfile(userInfos))
        .then(async () => {
          this.props.i18n.changeLanguage(this.state.languageIdMapping[value]);
          saveInLocal("languageId", value);
          await this.setState({ open: false }, () => window.location.reload());
        });
    } else {
      this.props.i18n.changeLanguage(this.state.languageIdMapping[value]);
      saveInLocal("languageId", value);
      await this.setState({ open: false });
    }
    // Refresh the component
    window.location.reload();
  };
  render() {
    const {
      icon,
      expanded,
      children,
      to,
      exact,
      onClick,
      languages,
      pos,
      offset,
      parentName,
    } = this.props;
    const { open } = this.state;
    const isVodActive = this.props.location.pathname.match("/vod/([0-9]+)");
    const isCatchupActive = this.props.location.pathname.match("/catchup/.+");
    const showLabel = expanded ? "flex" : "none";

    const isSignIn = parentName === "signin" ? true : false;
    const mg20 = isSignIn ? style.mg20 : null;
    const labelContainerLoginPage = isSignIn
      ? style.labelContainerLoginPage
      : null;
    const iconSize = consts.menuIconSize
      ? style[sizeIconsKeys[consts.menuIconSize]]
      : style.iconSizeM;
    const direction = getFromLocal("layoutDirection");

    const content = (
      <>
        {typeof icon === "string" ? (
          <span className={style.icon + " " + mg20 + " " + iconSize}>
            <img src={`data:image/svg+xml;base64,${icon}`} />
          </span>
        ) : (
          <span
            className={classnames(style.icon + " " + mg20 + " " + iconSize, {
              [style.iconToRight]: direction === "rtl",
            })}
          >
            {icon}
          </span>
        )}
        <CSSTransition
          in={expanded}
          timeout={1000}
          classNames={{
            enter: style.labelEnter,
            enterActive: style.labelEnterActive,
            enterDone: style.labelEnterDone,
            exit: style.labelExit,
            exitActive: style.labelExitActive,
            exitDone: style.labelExitDone,
          }}
        >
          <div
            className={classnames(
              style.labelContainer + " " + labelContainerLoginPage,
              {
                [style.labelContainerToRight]: direction === "rtl",
              }
            )}
            style={{ display: showLabel }}
          >
            <span
              className={classnames(style.menuFontWeight, {
                [style.label]: !expanded,
              })}
            >
              {children}
            </span>
          </div>
        </CSSTransition>
      </>
    );
    return (
      <>
        {to === "/#" || to === "/vod" || to === "/catchup" ? (
          <>
            {to === "/vod" && (
              <NavLink
                className={!isVodActive ? style.container : [style.vodActive]}
                to={to}
                exact={exact}
                activeClassName={!isVodActive ? style.activeContainer : null}
                onClick={onClick}
              >
                {content}
              </NavLink>
            )}
            {to === "/catchup" && (
              <NavLink
                className={
                  !isCatchupActive ? style.container : [style.vodActive]
                }
                to={to}
                exact={exact}
                activeClassName={
                  !isCatchupActive ? style.activeContainer : null
                }
                onClick={onClick}
              >
                {content}
              </NavLink>
            )}
            {to === "/#" && (
              <Popup
                keepTooltipInside={true}
                contentStyle={{
                  border: "none",
                  padding: 0,
                  background: "#1d1e1c",
                  borderRadius: "5px",
                  zIndex: 1100,
                }}
                open={open}
                onOpen={() => this.setState({ open: true })}
                offsetX={offset || 0}
                offsetY={offset || 0}
                trigger={(_open) => (
                  <NavLink
                    className={style.container}
                    to={this.props.location}
                    exact={exact}
                    activeClassName={style.activeContainer}
                    isActive={() =>
                      this.checkActive(this.props.isActive, this.props.to)
                    }
                  >
                    {content}
                  </NavLink>
                )}
                position={pos ? pos : "top center"}
                arrow={false}
                closeOnDocumentClick
              >
                <div className={style.menu}>
                  {languages &&
                    languages.map((lang, i) => {
                      return (
                        <div key={i}>
                          {lang.value === getFromLocal("languageId") ? (
                            <div
                              className={style.menuItemActive}
                              key={i}
                              onClick={() =>
                                this.handleChangeLanguage(lang.value)
                              }
                            >
                              <Trans>{lang.label}</Trans>
                            </div>
                          ) : (
                            <div
                              className={style.menuItem}
                              key={i}
                              onClick={() =>
                                this.handleChangeLanguage(lang.value)
                              }
                            >
                              <Trans>{lang.label}</Trans>
                            </div>
                          )}
                        </div>
                      );
                    })}
                </div>
              </Popup>
            )}
          </>
        ) : isExternal(to) === true ? (
          <LinkDuo
            className={style.container}
            to={to}
            activeClassName={classnames(style.activeContainer, {
              [style[direction.toUpperCase()]]: true,
            })}
          >
            {content}
          </LinkDuo>
        ) : (
          <NavLink
            className={style.container}
            to={to}
            exact={exact}
            activeClassName={classnames(style.activeContainer, {
              [style[direction.toUpperCase()]]: true,
            })}
            onClick={onClick}
          >
            {content}
          </NavLink>
        )}
      </>
    );
  }
}

MenuItem.defaultProps = {
  expanded: false,
  to: "/",
  exact: true,
};

MenuItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  expanded: PropTypes.bool,
  to: PropTypes.string,
  exact: PropTypes.bool,
};
export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      customerAuthToken: state.session.customerAuthToken,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(withRouter(MenuItem));
