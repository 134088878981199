import InlineSVG from "inline-svg-react";
import React from "react";

const Library = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Library.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Library.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path
            d="M6.167 2h11.666C18.478 2 19 2.451 19 3.008v16.984c0 .557-.522 1.008-1.167 1.008-.273 0-.537-.083-.747-.234L12 17.104l-5.086 3.662c-.495.357-1.231.299-1.644-.129a.93.93 0 01-.27-.645V3.008C5 2.451 5.522 2 6.167 2zM6.4 5.562v14L12 15.5l5.6 4.062v-14H6.4z"
            fillRule="nonzero"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default Library;
