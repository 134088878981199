import actionTypes from "../consts/actionTypes";
import { cloneObject } from "../helpers/dataHelper";

const initialState = {
  loading: true,
  data: undefined,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_SEARCH_PENDING:
    case actionTypes.RESET_SEARCH_RESULTS:
      return {
        loading: true,
      };
    case actionTypes.GET_SEARCH_SUCCESS: {
      const now = new Date().getTime() / 1000;
      const results = {
        live: [],
        epg: [],
        vod: [],
        catchup: [],
      };
      action.payload.data.forEach((result) => {
        let data;
        if (result.usedIn.catchup.length > 0) {
          data = result.usedIn.catchup[0];
          const merged = Object.assign({}, JSON.parse(JSON.stringify(result.item.payload)), data);
          results.catchup.push(merged);
        }
        if (result.usedIn.epg.length > 0) {
          data = result.usedIn.epg[0];
          //const merged = Object.assign({}, JSON.parse(JSON.stringify(result.item.payload)), data);
          const merged = Object.assign({}, cloneObject(result.item.payload), data);
          if (new Date(data.startutc).getTime() < now && now < new Date(data.endutc).getTime()) {
            results.live.push(merged);
          } else {
            results.epg.push(merged);
          }
        }
        if (result.usedIn.vod.length > 0) {
          data = result.usedIn.vod[0];
          const merged = Object.assign({}, JSON.parse(JSON.stringify(result.item.payload)), data);
          results.vod.push(merged);
        }
      });
      return {
        loading: false,
        data: results,
      };
    }
    case actionTypes.GET_SEARCH_ERROR:
      return {
        loading: false,
        data: [],
      };
    case actionTypes.RESET_GLOBAL_STATE:
      return initialState;
    default:
      return state;
  }
};
