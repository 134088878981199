import Player from "components/Player";
import PlayerErrorMessage from "components/PlayerErrorMessage";
import PlayerReact from "components/PlayerReact";
import consts from "consts/consts";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";

import style from "./style.module.css";

class ThumbnailTrailer extends Component {
  componentDidMount() {
    if (!getFromLocal(consts.storageKeys.playerTrailer)) {
      saveInLocal(consts.storageKeys.playerTrailer, {
        volume: 0,
        muted: true,
      });
    }
  }

  render() {
    const { isConnected, trailerUrl, fromSlider, isPoster } = this.props;

    let providerVideo = "";
    if (trailerUrl && trailerUrl.includes("youtube.com")) {
      providerVideo = "youtube";
    } else if (trailerUrl && trailerUrl.includes("vimeo.com")) {
      providerVideo = "vimeo";
    }

    return (
      <div className={style.root} id={isPoster ? style.rootPoster : null}>
        <div className={style.container}>
          {trailerUrl && (
            <Fragment>
              {providerVideo !== "" ? (
                <PlayerReact isTrailer={true} assetInfo={{}} url={trailerUrl} />
              ) : (
                <Player
                  isConnected={isConnected}
                  isTrailer={true}
                  onTrailerEnds={this.props.onTrailerEnds}
                  assetInfo={{
                    title: ``,
                  }}
                  url={trailerUrl}
                  width={300}
                  height={250}
                  fromSlider={fromSlider}
                />
              )}
            </Fragment>
          )}
          {!trailerUrl && <PlayerErrorMessage />}
        </div>
      </div>
    );
  }
}

export default ThumbnailTrailer;
