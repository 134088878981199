import classnames from "classnames";
import PlayerSubtitleIcon from "components/Icons/PlayerSubtitle";
import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";

import style from "./style.module.css";

class PlayerSubtitleControler extends Component {
  state = {
    showPopup: false,
  };

  handleClick(event, _value) {
    event.stopPropagation();
    this.props.setShowSubtitleController(!this.props.showSubtitleController);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.hide !== true && this.props.hide === true) {
      this.props.setShowSubtitleController(false);
    }
  }

  handleTransKeys(key = "") {
    if (key.toLowerCase().includes("spa")) {
      return "es";
    } else if (key.toLowerCase().includes("jpn")) {
      return "ja";
    } else if (key.toLocaleLowerCase().includes("eng")) {
      return "en";
    } else if (key.toLocaleLowerCase().includes("fra")) {
      return "fr";
    } else if (key.toLocaleLowerCase().includes("cat")) {
      return "ca";
    } else if (key.toLocaleLowerCase().includes("glg")) {
      return "gl";
    }
    return key;
  }

  render() {
    const { tracks, handleTrackChange, showSubtitleController, menuOpen } =
      this.props;
    const layoutDirection = getFromLocal("layoutDirection");
    return (
      <div
        className={style.root}
        onClick={(event) => {
          event.stopPropagation();
        }}
      >
        {showSubtitleController && (
          <div
            className={classnames(style.popup, {
              [style.popupRtl]: layoutDirection === "rtl",
              [style.offsetPopupIsMenuOpen]:
                menuOpen && layoutDirection === "rtl",
            })}
          >
            <div className={style.audio}>
              <p className={style.listHeader}>
                <Trans>audio</Trans>
              </p>
              <ul className={style.list}>
                {tracks.audio &&
                  tracks.audio.map((item, index) => {
                    return (
                      <li
                        className={classnames(style.listItem, {
                          [style.activeListItem]: item["is-active"] === true,
                        })}
                        key={`${index}_${item.name}`}
                        onClick={(event) => {
                          event.stopPropagation();
                          handleTrackChange("audio", index);
                        }}
                      >
                        <div
                          className={classnames(style.radiobox, {
                            [style.activeRadiobox]: item["is-active"] === true,
                          })}
                        />
                        <Trans>{this.handleTransKeys(item.name)}</Trans>
                      </li>
                    );
                  })}
              </ul>
            </div>
            <div className={style.text}>
              <p className={style.listHeader}>
                <Trans>subtitle</Trans>
              </p>
              <ul className={style.list}>
                <li
                  className={classnames(style.listItem, {
                    [style.activeListItem]: undefined === tracks.activeText,
                  })}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleTrackChange("text", undefined);
                  }}
                >
                  <div
                    className={classnames(style.radiobox, {
                      [style.activeRadiobox]: undefined === tracks.activeText,
                    })}
                  />
                  <Trans>None</Trans>
                </li>
                {tracks.text.map((item, index) => {
                  return (
                    <li
                      key={`${index}_${item.name}`}
                      className={classnames(style.listItem, {
                        [style.activeListItem]: item["is-active"] === true,
                      })}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleTrackChange("text", index);
                      }}
                    >
                      <div
                        className={classnames(style.radiobox, {
                          [style.activeRadiobox]: item["is-active"] === true,
                        })}
                      />
                      <Trans>{this.handleTransKeys(item.name)}</Trans>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        )}
        <PlayerSubtitleIcon
          className={classnames(style.icon, {
            [style.active]: showSubtitleController,
          })}
          onClick={(event) => this.handleClick(event)}
        />
      </div>
    );
  }
}

export default withTranslation()(PlayerSubtitleControler);
