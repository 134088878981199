import InlineSVG from "inline-svg-react";
import React from "react";

const DeviceTV = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/DeviceTV.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/DeviceTV.svg");
    } catch (err) {
      return (
        <svg width="100px" height="100px" viewBox="0 0 100 100" {...props}>
          <g
            id="ic_popin_add_device"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <path
              d="M7.69230769,21.6842105 L7.69230769,65.2280702 L92.3076923,65.2280702 L92.3076923,21.6842105 L7.69230769,21.6842105 Z M6.41025641,14 L93.5897436,14 C97.1300304,14 100,16.8669485 100,20.4035088 L100,66.5087719 C100,70.0453322 97.1300304,72.9122807 93.5897436,72.9122807 L6.41025641,72.9122807 C2.86996955,72.9122807 0,70.0453322 0,66.5087719 L0,20.4035088 C0,16.8669485 2.86996955,14 6.41025641,14 Z M29.4871795,87 C27.3630074,87 25.6410256,85.2798309 25.6410256,83.1578947 C25.6410256,81.0359586 27.3630074,79.3157895 29.4871795,79.3157895 L70.5128205,79.3157895 C72.6369926,79.3157895 74.3589744,81.0359586 74.3589744,83.1578947 C74.3589744,85.2798309 72.6369926,87 70.5128205,87 L29.4871795,87 Z"
              id="live"
              fill="#FFFFFF"
            ></path>
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default DeviceTV;
