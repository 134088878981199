import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { TucanoReducers } from "web-api/main";

import session from "./session";
import ui from "./ui";
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["ui", "content", "reference"],
};
const combinedReducers = combineReducers({
  ui,
  session,
  ...TucanoReducers,
});
const finalReducers = persistReducer(persistConfig, combinedReducers);
export default finalReducers;
