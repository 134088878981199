export default class PaymentMethod {
  constructor(data) {
    if (data) {
      this.brandBillingProviderId = data.brandBillingProviderId;
      this.paymentMethod = data.paymentMethod;
      this.paymentBrand = data.paymentBrand;
      this.paymentAlias = data.paymentAlias;
      this.change = data.change;
    }
  }

  getPaymentMethod() {
    return this.paymentMethod;
  }

  getBrandBillingProviderId() {
    return this.brandBillingProviderId;
  }

  getPaymentBrand() {
    return this.paymentBrand;
  }

  getPaymentAlias() {
    return this.paymentAlias;
  }

  getChange() {
    return this.change;
  }
}
