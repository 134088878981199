import { createSelector } from "reselect";

import Asset from "../models/Asset";
import EPG from "../models/EPG";
import { selectActiveAssetsTVOD } from "./content";
import { selectChannels } from "./reference";
import { selectListOptions, selectOptionValidity } from "./subscription";

export const selectSearchResults = (state) => {
  if (state.search.data === undefined) {
    return undefined;
  }
  return state.search.data;
};

export const getSearchResults = createSelector(
  selectSearchResults,
  selectChannels,
  selectListOptions,
  selectOptionValidity,
  selectActiveAssetsTVOD,
  (searchResults, channels, listOptions, optionsValidity, activeAssetsTVOD) => {
    if (!searchResults) {
      return undefined;
    }
    const results = {};
    if (searchResults.live && searchResults.live.length > 0) {
      results.live = searchResults.live
        .map((item) => {
          if (channels && channels.length > 0) {
            const channel = channels.find((channel) => {
              return item.channelIds && channel.idChannel === parseInt(item.channelIds[0], 10);
            });

            if (channel) {
              const liveItem = new EPG(item);
              liveItem.setSubscribed(channel.isSubscribed);
              liveItem.setFta(channel.isFta);
              return liveItem;
            }
          }
          return undefined;
        })
        .filter(function (el) {
          return el !== undefined;
        });
    }
    if (searchResults.epg && searchResults.epg.length > 0) {
      results.epg = searchResults.epg
        .sort((a, b) => {
          return a.startutc - b.startutc;
        })
        .map((item) => {
          if (channels && channels.length > 0) {
            const channel = channels.find((channel) => {
              return item.channelIds && channel.idChannel === parseInt(item.channelIds[0], 10);
            });

            if (channel) {
              const EPGitem = new EPG(item);
              EPGitem.setSubscribed(channel.isSubscribed);
              EPGitem.setFta(channel.isFta);
              return EPGitem;
            }
          }
          return undefined;
        })
        .filter(function (el) {
          return el !== undefined;
        });
    }
    if (searchResults.catchup && searchResults.catchup.length > 0) {
      results.catchup = searchResults.catchup
        .sort((a, b) => {
          return a.startutc - b.startutc;
        })
        .map((item) => {
          const asset = new Asset(item);
          asset.setListOptions(listOptions);
          asset.setOptionsValidity(optionsValidity);
          asset.setActiveAssetTVOD(activeAssetsTVOD);
          return asset;
        });
    }
    if (searchResults.vod && searchResults.vod.length > 0) {
      results.vod = searchResults.vod.map((item) => {
        const asset = new Asset(item);
        asset.setListOptions(listOptions);
        asset.setOptionsValidity(optionsValidity);
        asset.setActiveAssetTVOD(activeAssetsTVOD);
        return asset;
      });
    }

    return results;
  }
);

export const getTotalSearchResults = createSelector(getSearchResults, (searchResults) => {
  let count = 0;
  if (!searchResults) {
    return 0;
  }
  if (searchResults.live && searchResults.live.length > 0) {
    count += searchResults.live.length;
  }
  if (searchResults.epg && searchResults.epg.length > 0) {
    count += searchResults.epg.length;
  }
  if (searchResults.catchup && searchResults.catchup.length > 0) {
    count += searchResults.catchup.length;
  }
  if (searchResults.vod && searchResults.vod.length > 0) {
    count += searchResults.vod.length;
  }
  return count;
});
