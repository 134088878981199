import isExternal from "is-url-external";
import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

const propTypes = {
  to: PropTypes.string,
};

/**
 * Link that also works for external URL's
 */
export const LinkDuo = (props) => {
  if (props.to) {
    return isExternal(props.to) === true ? (
      <a href={props.to} {...props} target="_blank" rel="noopener noreferrer" />
    ) : (
      <Link {...props} />
    );
  } else {
    return props.children;
  }
};

LinkDuo.propTypes = propTypes;
