import Button from "components/Button";
import Logo from "components/LogoLogin";
import { getQueryVariable } from "helpers/url";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageConfimAccount extends Component {
  constructor(props) {
    super(props);
  }

  async componentDidMount() {
    const { location, history, dispatch } = this.props;
    if (location.search.includes("?token")) {
      const email = getQueryVariable(location.search, "email");
      const token = getQueryVariable(location.search, "token");
      await dispatch(TucanoActions.validateAccount(email, token));
    } else {
      history.push("/sign-in");
    }
  }

  componentWillUnmount() {}

  goToSignIn = () => {
    this.props.history.push("/sign-in");
  };

  render() {
    const { accountValidationError, t } = this.props;

    return (
      <div className={style.confirmAccountContainer}>
        {!accountValidationError ? (
          <>
            <Logo className={style.logo} />
            <div className={style.textContainer}>
              <h2 className={style.infoTitle}>
                <Trans t={t}>ACCOUNT VERIFIED</Trans>
              </h2>
              <p>
                <Trans t={t}>
                  Thank you, your email has been verified. Your account is now
                  active. Please continue to the previous page
                </Trans>
              </p>
            </div>
          </>
        ) : (
          <>
            <Logo className={style.logo} />
            <div className={style.textContainer}>
              <h2 className={style.infoTitle}>
                <Trans t={t}>Link has expired</Trans>
              </h2>
              <p>
                {" "}
                <Trans t={t}>
                  We were not able to validate your account,Please try to sign
                  up again
                </Trans>
              </p>
              <Button onClick={this.goToSignIn}>Go Back</Button>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      accountValidated: state.account.validateAccount.data,
      accountValidationError: state.account.validateAccount.error,
    };
  }),
  withTranslation()
)(withRouter(PageConfimAccount));
