import classnames from "classnames";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Header extends Component {
  render() {
    const finalClassName = classnames(style.root, this.props.rootClassName);
    const { children } = this.props;
    return <h2 className={finalClassName}>{children}</h2>;
  }
}
