import { removeToast } from "actions/ui";
import Loader from "components/Loader";
import consts from "consts/consts";
import { removeFromLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";
class PageLogout extends Component {
  componentDidMount() {
    const { isConnected } = this.props;
    if (isConnected) {
      for (var i = 0; i < this.props.toasts.length; i++) {
        this.props.dispatch(removeToast(this.props.toasts[i].id));
      }
      setTimeout(() => {
        this.props.dispatch(TucanoActions.logout()).then(() => {
          this.props.dispatch(TucanoActions.resetGlobalState());
          removeFromLocal(consts.storageKeys.tokens);
          removeFromLocal(consts.storageKeys.localISP);
          removeFromLocal("productId");
          removeFromLocal("TucanoOrderId");
          window.location = "/sign-in";
        });
      }, 1000);
    } else {
      window.location = "/sign-in";
    }
  }

  render() {
    return (
      <div className={style.root}>
        <div>
          <h1>
            <Trans>You are being logged out</Trans>
          </h1>
          <Loader />
        </div>
      </div>
    );
  }
}

export default connect((state) => {
  return {
    isConnected: state.session.customerAuthToken !== undefined,
    toasts: state.ui.toasts,
  };
})(PageLogout);
