import InlineSVG from "inline-svg-react";
import React from "react";

const Privacy = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Privacy.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Privacy.svg");
    } catch (err) {
      return (
        <svg width={26} height={26} viewBox="0 0 20 20" {...props}>
          <g id="Icon-10" transform="translate(2.000000, 405.000000)">
            <g
              id="_x32_.Icons-_x2F_-Toggle-_x2F_-Lock"
              transform="translate(4.166667, 3.333333)"
            >
              <path
                id="Combined-Shape_1_"
                className="st0"
                d="M3.7-403.6c1.4,0,2.5,1,2.5,2.2l0,1.6c0.5,0.1,0.8,0.5,0.8,1v3c0,0.6-0.4,1-1,1H1.4
              c-0.6,0-1-0.4-1-1v-3c0-0.5,0.4-0.9,0.8-1l0-1.6C1.2-402.6,2.4-403.6,3.7-403.6z M3.7-398.6c-0.2,0-0.4,0.2-0.4,0.4l0,0v1.2
              c0,0.2,0.2,0.4,0.4,0.4c0.2,0,0.4-0.2,0.4-0.4l0,0v-1.2C4.2-398.4,4-398.6,3.7-398.6z M3.7-402.8c-0.9,0-1.7,0.7-1.7,1.5l0,1.6
              h3.3l0-1.6C5.4-402.2,4.7-402.8,3.7-402.8z"
              />
            </g>
            <path
              id="Rectangle"
              className="st0"
              d="M15.1-402.3l-6.4-1.9c-0.4-0.1-0.9-0.1-1.3,0l-6.4,1.9c-0.6,0.2-1,0.8-1,1.4l0,3.6l0,0.9
            c0.1,2.2,0.2,3.6,0.4,4.2c0.3,1.2,1.3,2.3,2.7,3.5c0.8,0.6,1.7,1.2,2.7,1.8c0.4,0.2,0.8,0.4,1.1,0.6l0.4,0.2
            c0.3,0.1,0.5,0.2,0.6,0.3l0.3,0.1l0.5-0.2l0.4-0.2c0.1-0.1,0.3-0.1,0.4-0.2c0.8-0.4,1.5-0.9,2.3-1.4c2.2-1.5,3.7-3,4.1-4.5
            c0.2-0.6,0.3-2,0.4-4.2l0-0.9l0-3.6C16.1-401.6,15.7-402.1,15.1-402.3z M14.9-400.9l0,3.6l0,0.8c-0.1,2.2-0.2,3.5-0.4,4
            c-0.3,1.2-1.7,2.5-3.6,3.8c-0.7,0.5-1.4,0.9-2.2,1.3l-0.5,0.3L8-387.1l-0.3-0.1c-0.4-0.2-0.9-0.5-1.4-0.8c-0.9-0.5-1.8-1.1-2.5-1.7
            c-1.2-1-2-2-2.3-2.8l0-0.1c-0.1-0.6-0.2-1.9-0.3-3.8l0-0.9l0-3.5c0-0.1,0.1-0.2,0.1-0.2l6.4-1.9c0.2-0.1,0.4-0.1,0.6,0l6.4,1.9
            C14.8-401.1,14.9-401,14.9-400.9z"
            />
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Privacy;
