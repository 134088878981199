import { saveInLocal } from "helpers/localStorage";

import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";

export function getHome(languageId = "eng", homePageType, isConnectionChanged) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.homeContent,
          params: { type: homePageType, languageId: languageId },
        },
        onStart: () => {
          if (isConnectionChanged) {
            //this check is added to verify if the loader in the home page should be displayed or not
            return {
              type: actionTypes.HOMECONTENT_PENDING,
            };
          }
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.HOMECONTENT_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.HOMECONTENT_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getVODContent(param, languageId = "eng", range, loadingMore) {
  // param is a real catalog object from /hierarchy
  if (!param || !param.categories) {
    return {
      type: actionTypes.VOD_LISTCONTENT_ERROR,
    };
  }

  let categories = [];
  // categories.push(param.categories[0]);
  categories.push(...param.categories.slice(range[0], range[1]));
  const requests = categories.map((category, index) => {
    const request = {
      method: "POST",
      url: routes.listContent,

      params: {
        languageId: languageId,
      },
    };

    request.params.id = category.idCatalog;
    const categoryToRequest = category.categories.find((item) => {
      return item.fictif === true;
    });
    if (categoryToRequest) {
      request.params.id = categoryToRequest.idCatalog;
    }
    return request;
  });
  return (dispatch) => {
    return new Promise((resolve) => {
      return dispatch({
        request: requests,
        onStart: () => {
          if (!loadingMore)
            return {
              type: actionTypes.VOD_LISTCONTENT_PENDING,
            };
        },
        onSuccess: (payload, meta, dispatch) => {
          // KILL ME PLEASE...
          // API Don't return all needed data so we "inject" data into the API response…
          // Add itemId and title for strate
          // console.log("payload", payload);
          let updatedPayload;
          updatedPayload = Object.values(payload).map((item, index) => {
            item.title = categories[index].name;
            item.idItem = categories[index].idCatalog;
            return item;
          });
          resolve();
          return {
            type: actionTypes.VOD_LISTCONTENT_SUCCESS,
            payload: updatedPayload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.VOD_LISTCONTENT_ERROR,
          };
        },
      });
    });
  };
}
export function getVODContentDetail(id, languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      return dispatch({
        request: {
          url: routes.listContent,
          method: "POST",
          params: {
            languageId: languageId,
            id: id,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.VOD_LISTCONTENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          let requests;
          requests = payload.items
            .map((item) => {
              if (item.type === "category") {
                return {
                  url: routes.listContent,
                  method: "POST",
                  params: {
                    languageId: languageId,
                    id: item.payload.idCatalog,
                  },
                };
              }
              return null;
            })
            .filter(Boolean);

          if (requests.length > 0) {
            dispatch({
              request: requests,
              onStart: () => {
                return {
                  type: actionTypes.VOD_LISTCONTENT_PENDING,
                };
              },
              onSuccess: (result) => {
                Object.values(result).map((item, index) => {
                  let data;
                  if (item.items[0].payload.name === "Promoted") {
                    let subCatData;
                    dispatch({
                      request: {
                        url: routes.listContent,
                        method: "POST",
                        params: {
                          languageId: languageId,
                          id: item.items[0].payload.idCatalog,
                        },
                      },
                      onStart: () => {
                        return {
                          type: actionTypes.EPG_PENDING,
                        };
                      },
                      onSuccess: (res) => {
                        resolve(res);

                        subCatData = res.items.map((i) => {
                          i.payload.assetType = i.type;
                          return i.payload;
                        });

                        payload.items[index].payload.assets = subCatData;
                        return {
                          type: actionTypes.VOD_SUBCATEGORY_LIST_SUCCESS,
                          payload,
                        };
                      },
                      onError: (payload, meta) => {
                        resolve(meta);
                        return {
                          type: actionTypes.EPG_ERROR,
                          meta,
                        };
                      },
                    });
                  } else {
                    data = item.items.map((i) => {
                      i.payload.assetType = i.type;
                      return i.payload;
                    });
                  }
                  payload.items[index].payload.assets = data;
                });
                return {
                  type: actionTypes.VOD_LISTCONTENT_SUCCESS,
                  payload: payload,
                };
              },
            });
          } else {
            return {
              type: actionTypes.VOD_LISTCONTENT_SUCCESS,
              payload: payload.items,
            };
          }
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.VOD_LISTCONTENT_ERROR,
          };
        },
      });
    });
  };
}

export function resetVOD() {
  return {
    type: actionTypes.VOD_RESET,
  };
}

export function getEPG(time, languageId = "eng", isConnectionStatusChanged) {
  const newDate = new Date(time);
  newDate.setHours(0, 0, 0, 0);
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.epg,
          params: {
            languageId: languageId,
            midnight: newDate.getTime() / 1000,
            startHour: 0,
            endHour: 23,
          },
        },
        onStart: () => {
          if (isConnectionStatusChanged) {
            return {
              type: actionTypes.EPG_PENDING,
            };
          }
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.EPG_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.EPG_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getEPGDetail(idEvent, idLanguage = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: `${routes.epgDetail}/${idLanguage}/${idEvent}`,
          params: {
            idEvent: idEvent,
            idLanguage: idLanguage,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.EPG_DETAIL_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.EPG_DETAIL_SUCCESS,
            payload: payload.program,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.EPG_DETAIL_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function resetEpgDetail() {
  return {
    type: actionTypes.RESET_EPG_DETAIL,
  };
}

export function resetEPG() {
  return {
    type: actionTypes.EPG_RESET,
  };
}

export function getCatchupDetail(param, languageId = "eng") {
  // param is a real catalog object from /hierarchy
  const requests = param.categories
    .map((category, index) => {
      return {
        method: "POST",
        url: routes.listContent,
        params: {
          languageId: languageId,
          id: category.idCatalog,
        },
      };
    })
    .filter(Boolean);
  return (dispatch) => {
    return new Promise((resolve) => {
      return dispatch({
        request: requests,
        onStart: () => {
          return {
            type: actionTypes.CATCHUP_LISTCONTENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          // KILL ME PLEASE...
          // API Don't return all needed data so we "inject" data into the API response…
          // Add itemId and title for strate
          let updatedPayload;
          updatedPayload = Object.values(payload).map((item, index) => {
            item.title = param.categories[index].name;
            item.idItem = param.categories[index].idCatalog;
            return item;
          });
          return {
            type: actionTypes.CATCHUP_LISTCONTENT_SUCCESS,
            payload: updatedPayload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.CATCHUP_LISTCONTENT_ERROR,
          };
        },
      });
    });
  };
}

export function getTVShow(id, languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.listContent,
          params: {
            languageId: languageId,
            id,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.TVSHOW_LIST_CONTENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.TVSHOW_LIST_CONTENT_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.TVSHOW_LIST_CONTENT_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getTVShowEpisodes(id, index, languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.listContent,
          params: {
            languageId: languageId,
            id: id,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.TVSHOW_EPISODES_LIST_CONTENT_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.TVSHOW_EPISODES_LIST_CONTENT_SUCCESS,
            payload,
            index,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.TVSHOW_EPISODES_LIST_CONTENT_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getAsset(assetId, languageId = "eng", menuorder = []) {
  const assetIds = JSON.stringify([assetId]);
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.assets,
          params: {
            languageId: languageId,
            assetIds: assetIds,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ASSETS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          if (payload.items && payload.items[0] && payload.items[0].path) {
            let index = payload.items[0].path.findIndex((element) => {
              for (let i = 0; i < menuorder.length; i++) {
                if (element.startsWith(menuorder[i].toFixed(0))) {
                  return true;
                }
              }
            });
            if (index > -1) {
              const pathSplit = payload.items[0].path[index].split("/");
              const parentId = parseInt(pathSplit.splice(pathSplit.length - 2, 1));
              saveInLocal("tvshowId", parentId);
            }
          }
          resolve(payload);
          return {
            type: actionTypes.ASSETS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.ASSETS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getAssetNextEpisode(assetId, languageId = "eng") {
  const assetIds = JSON.stringify([assetId]);
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.assets,
          params: {
            languageId: languageId,
            assetIds: assetIds,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ASSETS_NEXT_EPISODE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ASSETS_NEXT_EPISODE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.ASSETS_NEXT_EPISODE_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function resetAsset() {
  return {
    type: actionTypes.ASSET_RESET,
  };
}

export function getAssetScore(id, isTVShow, isTVShowEpisode) {
  const params = {};
  if (isTVShow) {
    params.idCategory = id;
  } else if (isTVShowEpisode) {
    params.idCategory = id;
  } else {
    params.idAsset = id;
  }

  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.assetScore,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.ASSET_SCORE_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ASSET_SCORE_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.ASSET_SCORE_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getMonolistRecommendation(idAsset, languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.monolistRecommendation,
          params: {
            languageId: languageId,
            idAsset: idAsset,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ASSET_MONOLOLIST_RECOMMENDATION_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ASSET_MONOLOLIST_RECOMMENDATION_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.ASSET_MONOLOLIST_RECOMMENDATION_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getMultifacetRecommendation(idAsset, languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.multifacetRecommendation,
          params: {
            languageId: languageId,
            idAsset: idAsset,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.ASSET_MULTIFACET_RECOMMENDATION_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.ASSET_MULTIFACET_RECOMMENDATION_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.ASSET_MULTIFACET_RECOMMENDATION_ERROR,
            meta,
          };
        },
      });
    });
  };
}
export function resetActiveAssets() {
  return {
    type: actionTypes.RESET_ACTIVE_ASSETS,
  };
}
export function getActiveAssets(params) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.activeAssets,
          params: params,
        },
        onStart: () => {
          return {
            type:
              params.commercialModel === "TVOD"
                ? actionTypes.ACTIVE_ASSETS_TVOD_PENDING
                : actionTypes.ACTIVE_ASSETS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type:
              params.commercialModel === "TVOD"
                ? actionTypes.ACTIVE_ASSETS_TVOD_SUCCESS
                : actionTypes.ACTIVE_ASSETS_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type:
              params.commercialModel === "TVOD"
                ? actionTypes.ACTIVE_ASSETS_TVOD_ERROR
                : actionTypes.ACTIVE_ASSETS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function readTrailer(idMedia) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.readTrailer,
          params: { idMedia },
        },
        onStart: () => {
          return {
            type: actionTypes.READ_TRAILER_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.READ_TRAILER_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.READ_TRAILER_ERROR,
            meta,
            payload,
          };
        },
      });
    });
  };
}

export function readSlideTrailer(trailerID) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.readTrailer,
          params: { idMedia: trailerID },
        },
        onStart: () => {
          return {
            type: actionTypes.READ_SLIDE_TRAILER_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.READ_SLIDE_TRAILER_SUCCESS,
            payload,
            trailerID,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.READ_SLIDE_TRAILER_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function toRestartAssestVideo() {
  return {
    type: actionTypes.RESTART_ASSETS_VIDEO,
  };
}

// NPVR

export function getRecord(param) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: `${routes.manageNPVRRecord}/${param}`,
        },
        onStart: () => {
          return {
            type: actionTypes.ASSETS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          let asset = {};
          if (payload.epgContext) {
            asset = JSON.parse(payload.epgContext);
          } else {
            asset = JSON.parse(payload.context);
          }
          return {
            type: actionTypes.ASSETS_SUCCESS,
            payload: {
              items: [asset],
            },
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.ASSETS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getNPVRRecords(params) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: `${routes.manageNPVRRecord}`,
          params,
        },
        onStart: () => {
          return {
            type: actionTypes.RECORD_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.RECORD_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.RECORD_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function createNPVRRecord(params) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: `${routes.manageNPVRRecord}`,
          params,
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
        },
        onError: (payload, meta) => {
          resolve(payload);
        },
      });
    });
  };
}

export function deleteNPVRRecord(params) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "DELETE",
          url: `${routes.manageNPVRRecord}/${params}`,
          params,
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
        },
        onError: (payload, meta) => {
          resolve(payload);
        },
      });
    });
  };
}

export function getQuotaNPVR() {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: `${routes.getQuotaNPVR}`,
        },
        onStart: () => {
          return {
            type: actionTypes.QUOTA_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.QUOTA_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.QUOTA_ERROR,
            meta,
          };
        },
      });
    });
  };
}
