import { removeToast } from "actions/ui";
import classnames from "classnames";
import Toast from "components/Toast";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";

import style from "./style.module.css";

class Toasts extends Component {
  render() {
    const { toasts, rootClassName } = this.props;

    return (
      <div className={classnames(style.toasts, rootClassName)}>
        {toasts.map((toast) => {
          const { id } = toast;
          return (
            <Toast
              {...toast}
              key={id}
              onDismissClick={() => this.props.dispatch(removeToast(id))}
            />
          );
        })}
      </div>
    );
  }
}

Toasts.propTypes = {
  actions: PropTypes.shape({
    removeToast: PropTypes.func.isRequired,
  }),
  toasts: PropTypes.arrayOf(PropTypes.object).isRequired,
  rootClassName: PropTypes.any,
};

export default connect((state) => {
  return {
    toasts: state.ui.toasts,
  };
})(Toasts);
