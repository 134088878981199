import consts from "consts/consts";
import screenfull from "screenfull";
export function getRealSlug(beemixUrl = consts.routes.home.url) {
  if (beemixUrl.indexOf(consts.interalLinkPrefix) === -1) {
    return beemixUrl;
  }
  let cleanedUrl = beemixUrl
    .replace(consts.interalLinkPrefix, "")
    .split("?")[0];
  if (beemixUrl.indexOf("categoryId") === -1) {
    if (consts.routes[cleanedUrl]) {
      return consts.routes[cleanedUrl].url;
    } else if (beemixUrl.indexOf("live") !== -1) {
      return consts.routes.live.url;
    } else if (beemixUrl.indexOf("epg") !== -1) {
      return consts.routes.epg.url;
    } else if (beemixUrl.indexOf("favorite") !== -1) {
      return consts.routes.favorites.url;
    } else if (beemixUrl.indexOf("continueWatching") !== -1) {
      return consts.routes.history.url;
    } else if (beemixUrl.indexOf("vod") !== -1) {
      return consts.routes.vod.url;
    } else if (beemixUrl.indexOf("catchup") !== -1) {
      return consts.routes.catchup.url;
    } else if (beemixUrl.indexOf("account") !== -1) {
      return consts.routes.account.url;
    } else if (beemixUrl.indexOf("devices") !== -1) {
      return consts.routes.devices.url;
    } else if (beemixUrl.indexOf("cgu") !== -1) {
      return consts.routes.cgu.url;
    } else if (beemixUrl.indexOf("faq") !== -1) {
      return consts.routes.faq.url;
    } else if (beemixUrl.indexOf("subscription") !== -1) {
      return consts.routes.subscription.url;
    } else if (beemixUrl.indexOf("profiles") !== -1) {
      return consts.routes.profiles.url;
    } else if (beemixUrl.indexOf("termsOfUse") !== -1) {
      return consts.routes.cgu.url;
    }
  } else {
    const id = getQueryVariable(beemixUrl, "categoryId");
    return consts.routes[cleanedUrl]?.detailUrl?.replace(":id", id);
  }
  return beemixUrl;
}

export function getQueryVariable(url, variable) {
  const query = url.split("?")[1];
  const vars = query.split("&");
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=");
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
  // console.log("Query variable %s not found in %s", variable, query);
}

export function parseQuery(queryString) {
  let query = {};
  const pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    const pair = pairs[i].split("=");
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || "");
  }
  return query;
}
export function getLiveCertParam(queryString, param) {
  let value = {};
  const pairs = (
    queryString[0] === "?" ? queryString.substr(1) : queryString
  ).split("&");
  for (let i = 0; i < pairs.length; i++) {
    let pair;
    // console.log("pairs[i]", pairs[i]);
    // console.log("pairs[i].includes", pairs[i].includes(param));
    // console.log("param", param);

    if (pairs[i].includes(param)) {
      pair = pairs[i].replace(`${param}=`, "");
      // console.log("replaced", pair);
      value = pair;
    }
  }
  return value;
}
export function shouldDisplaySearchIcon(url = window.location.pathname) {
  const routes = consts.routes;
  const routesHidingSearch = [
    routes.account.url.toLocaleLowerCase(),
    routes.confirmAccount.url.toLocaleLowerCase(),
    routes.devices.url.toLocaleLowerCase(),
    routes.cgu.url.toLocaleLowerCase(),
    routes.faq.url.toLocaleLowerCase(),
    routes.settings.url.toLocaleLowerCase(),
    routes.subscription.url.toLocaleLowerCase(),
    routes.profiles.url.toLocaleLowerCase(),
    routes.signup.url.toLocaleLowerCase(),
    routes.resetpassword.url.toLocaleLowerCase(),
    routes.signIn.url.toLocaleLowerCase(),
    routes.logout.url.toLocaleLowerCase(),
    routes.privacy.url.toLocaleLowerCase(),
  ];
  if (
    routesHidingSearch.includes(url.toLocaleLowerCase()) ||
    url.startsWith("/player") ||
    url.startsWith("/profiles/")
  ) {
    return false;
  }
  return true;
}

export function shouldDisplayMenu(url = window.location.pathname) {
  const routes = consts.routes;
  const routesHidingMenu = [
    routes.signup.url.toLocaleLowerCase(),
    routes.resetpassword.url.toLocaleLowerCase(),
    routes.signIn.url.toLocaleLowerCase(),
    routes.confirmAccount.url.toLocaleLowerCase(),
  ];
  if (
    routesHidingMenu.includes(url.toLocaleLowerCase()) ||
    screenfull.isFullscreen
  ) {
    return false;
  }
  return true;
}

export function shouldDisplayFlyCatchupPage(url = window.location.pathname) {
  const routes = consts.routes;
  const routesHidingFly = [
    routes.catchupchannel.url.toLocaleLowerCase(),
    routes.catchupdetail.url.toLocaleLowerCase(),
  ];
  if (routesHidingFly.includes(url.toLocaleLowerCase())) {
    return false;
  }
  return true;
}

export function isUrl(text) {
  return /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    text
  );
}

export function removeSpace(str) {
  return str.replace("%20", " ");
}
