import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import AssetCircleContext from "components/AssetCircleContext";
import FreeToAirCircle from "components/Icons/FreeToAirCircle";
import PadlockLock from "components/Icons/PadlockLock";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import LogoVod from "components/LogoVod";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class ThumbnailCircle extends Component {
  state = {
    hover: false,
    metaItem: null,
  };
  async componentDidMount() {
    if (consts.displayVodData) {
      const { item } = this.props;
      let metadata;
      if (item instanceof TucanoModels.Asset && item.getVodData()) {
        metadata = item.getVodData();
      }
      await this.setState({ metaItem: metadata });
    }
  }

  subscriptionModal(asset) {
    const { accountStatus, dispatch } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  isChannel() {
    const { item } = this.props;
    if (item instanceof TucanoModels.Channel) {
      return item.constructor === TucanoModels.Channel;
    }
  }

  isLock() {
    const { item, accountStatus } = this.props;

    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!consts.padlocks) return false;
    if (item.isCategory) return false;
    if (item.isAssetTVShow && !item.isAssetTVShowEpisode) return false;
    if (item.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !item.userHasAccess;
  }

  render() {
    const { item, size, fta, isConnected, usePosterImage } = this.props;
    const { hover } = this.state;
    /*let catalog;
    if (item instanceof TucanoModels.TVShow) {
      catalog = item.getCatalogue();
    }
    if (item.catalogs) {
      catalog = item.catalogs;
    }*/
    let url;

    if (item) {
      url = consts.routes.movieAsset.url.replace(":id", item.getId());
    }

    if (
      item &&
      item instanceof TucanoModels.Asset &&
      (item.isSerie || item.isAssetTVShowEpisode) === true
    ) {
      url = consts.routes.tvshowAsset.url.replace(":id", item.getParentId());
    } else if (item.isCategory) {
      url = consts.routes.vod.detailUrl.replace(":id", item.getId());
    }

    if (item.getType() === "link") {
      url = getRealSlug(item.getUrl());
    }

    if (item.getType() === "EPGBroadcast") {
      if (item.isLive()) {
        url = consts.routes.playerlive.url.replace(
          ":channelId",
          item.getChannelId()
        );
      } else {
        url = consts.routes.epgAsset.url.replace(":id", item.getEpgId());
      }
    }

    if (item instanceof TucanoModels.Channel) {
      url = consts.routes.playerlive.url.replace(":channelId", item.getId());
    }

    if (item instanceof TucanoModels.Asset && item.isCatchupAsset) {
      url = consts.routes.catchupchannel.url.replace(":slug", item.getSlug());
    }

    return (
      <div className={style.root} style={{ width: size.circle.width }}>
        <div
          className={classnames(style.assetContainer, {
            [style.container_hover]: hover,
          })}
          style={{ height: size.circle.height, width: size.circle.width }}
        >
          <LinkDuo
            to={url}
            onMouseEnter={() => this.setState({ hover: this.props.infos })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <>
              {item instanceof TucanoModels.EPGEventAsset && (
                <Image
                  rootClassName={style.channel}
                  height={35}
                  width={35}
                  src={`${consts.image_hostname}${routes.imgdata}?type=${
                    consts.imageTypes.tvChannel
                  }&objectId=${item.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  placeholder={PlaceholderType.TRANSPARENT}
                />
              )}
              {item instanceof TucanoModels.Asset &&
                item.isCatchupAsset &&
                !!item.getProviderId() && (
                  <Image
                    rootClassName={style.channel}
                    height={35}
                    width={35}
                    src={`${consts.image_hostname}${routes.imgdata}?type=${
                      consts.imageTypes.tvChannel
                    }&objectId=${item.getProviderId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                      "languageId"
                    )}`}
                    placeholder={PlaceholderType.TRANSPARENT}
                  />
                )}
              {this.isChannel() && (
                <div className={style.channelNumber}>
                  {item.getLocalizeNumber()}
                </div>
              )}

              {item && item.flyImage && item instanceof TucanoModels.Asset && (
                <div className={classnames(style.VODLogo)}>
                  <LogoVod
                    className={classnames(style.vodData)}
                    type={item.flyImage}
                    size={40}
                  />
                </div>
              )}

              <div
                className={style.content}
                style={{ height: size.circle.height, width: size.circle.width }}
              >
                <Image
                  src={item.getImage(consts.image_hostname, {
                    width: 171,
                    usePosterImage: { usePosterImage },
                    height: 171,
                    languageId: getFromLocal("languageId"),
                  })}
                  width={size.circle.width}
                  height={size.circle.height}
                  ratio={ImageRatio.CIRCLE}
                  placeholder={PlaceholderType.SECONDARY}
                  border={true}
                />
              </div>
              <ThumbnailInfos
                show={hover}
                model={item}
                subscriptionModal={this.subscriptionModal.bind(this)}
                ratioCircle={ImageRatio.CIRCLE}
                displayFavoriteToggle={true}
              />
            </>
          </LinkDuo>
        </div>
        {this.isLock() && (
          <div className={style.padlockContainerForCircle}>
            <div className={style.padlockIconContainerForCircle}>
              <PadlockLock className={style.padlockLockIcon} />
            </div>
          </div>
        )}
        <Link to={url} className={style.link}>
          <AssetCircleContext model={item} />
        </Link>
        {fta && !isConnected && (
          <div className={style.ftaIconContainer}>
            <FreeToAirCircle className={style.fta} />
          </div>
        )}
      </div>
    );
  }
}

ThumbnailCircle.defaultProps = {
  asset: {},
  rootClassName: "",
  size: {},
  infos: true,
  onlyPlay: false,
};

ThumbnailCircle.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
  infos: PropTypes.bool,
  onlyPlay: PropTypes.bool,
};

export default compose(
  connect((state, _props) => {
    return {
      accountStatus: state.account.user?.data?.status,
    };
  })
)(ThumbnailCircle);
