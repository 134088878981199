import Button, { ButtonType } from "components/Button";
import Modal from "components/Modal";
import SocialButton from "components/SocialButton";
import consts from "consts/consts";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";

import style from "./style.module.css";

class LoginSsoSocial extends Component {
  state = {
    showMore: false,
  };

  openModal = (showMore) => {
    this.setState({ showMore });
  };

  showSSOLoginSingleButton(Translate, t) {
    return (
      <div style={{ width: "100%" }}>
        <Button
          rootClassName={style.moreBtn}
          type={ButtonType.NORMAL}
          onClick={() => this.openModal(true)}
        >
          <Translate t={t}>Login with your social account</Translate>
        </Button>
      </div>
    );
  }

  showSSOLoginRawComponent(Translate, t, toShowMore, maxSocialLoginToShow) {
    return (
      <>
        <div
          className={style.operatos}
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {consts.SSOSocials &&
            consts.SSOSocials.slice(0, maxSocialLoginToShow).map((one, key) => (
              <SocialButton
                key={key}
                type={one.name}
                Translate={Translate}
                t={t}
                socialAccount={one}
              />
            ))}
        </div>
        {toShowMore && (
          <div className={style.moreSection}>
            <Button
              rootClassName={style.moreBtn}
              type={ButtonType.LOGINBORDERED}
              onClick={() => this.openModal(true)}
            >
              <Translate t={t}>More SSO socials provider</Translate>
            </Button>
          </div>
        )}
      </>
    );
  }

  showMoreModal(Translate, t) {
    return (
      <Modal
        size={"md"}
        leftContent={{ title: t("Login with your social account") }}
        rightContent={{ canDismiss: this.openModal.bind(this, false) }}
        overlay={true}
      >
        <div>
          {consts.SSOSocials &&
            consts.SSOSocials.map((one, key) => (
              <div className={style.socialItem} key={key}>
                <SocialButton type={one.name} Translate={Translate} t={t} />
              </div>
            ))}
        </div>
      </Modal>
    );
  }

  render() {
    const { showMore } = this.state;
    const { Translate, t } = this.props;
    let { maxSocialLoginToShow } = consts;
    const toShowMore =
      consts.SSOSocials && consts.SSOSocials.length > maxSocialLoginToShow;
    return (
      <div className={(style.loginSSO, style.SsoSocial)}>
        <p>
          <Translate t={t}>You can also log in with</Translate>
        </p>
        {consts.loginWithSsoSocialAsSingleButton
          ? this.showSSOLoginSingleButton(Translate, t)
          : this.showSSOLoginRawComponent(
              Translate,
              t,
              toShowMore,
              maxSocialLoginToShow
            )}
        {showMore && this.showMoreModal(Translate, t)}
      </div>
    );
  }
}
export default withTranslation()(LoginSsoSocial);
