import Button from "components/Button";
import Close from "components/Icons/Close";
import React, { Component } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";

export default class PlayerErrorMessage extends Component {
  handleClickOnModalButton() {
    if (this.props.closeAction) {
      this.props.closeAction();
    }
  }

  render() {
    const { message, title } = this.props;
    return (
      <div className={style.rootContainer}>
        <Close className={style.icon} />
        <p className={style.title}>
          {title ? <Trans>{title}</Trans> : <Trans>Video error</Trans>}
        </p>
        <p className={style.description}>
          {message ? (
            <Trans>{message}</Trans>
          ) : (
            <Trans>
              There is an error with the video,Please try again later
            </Trans>
          )}
        </p>
        <Button onClick={this.handleClickOnModalButton.bind(this)}>
          <Trans>Close</Trans>
        </Button>
      </div>
    );
  }
}
