import { setDiscoverMode } from "actions/session";
import { addToast } from "actions/ui";
import { setLoginShow } from "actions/ui";
import { clearAllBodyScrollLocks, disableBodyScroll } from "body-scroll-lock";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import CheckIcon from "components/Icons/Check";
import Sad from "components/Icons/Sad";
import LoginCredential from "components/LoginCredential";
import LoginSSO from "components/LoginSso";
import LoginSsoSocial from "components/LoginSsoSocial/LoginSsoSocial";
import Logo from "components/LogoLogin";
import Modal from "components/Modal";
import Signup from "components/Signup";
import SwitchLanguage from "components/SwitchLanguage";
import consts from "consts/consts";
import FormAskResetPassword from "containers/FormAskResetPassword";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import { getQueryVariable } from "helpers/url";
import { Translate } from "helpers/utility";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions } from "web-api/main";

import style from "./style.module.css";

let windowObjectReference = null;
let previousUrl = null;
class PageSignIn extends Component {
  state = {
    show: false,
    error: false,
    showValidationModal: false,
    switchLanguage: false,
  };

  constructor(props) {
    super(props);
  }
  toggleValidationModal = () => {
    this.setState({ showValidationModal: !this.state.showValidationModal });
  };
  async componentDidMount() {
    if (this.props.isConnected) {
      this.props.history.push("/");
    }
    if (!this.props.isPopup) {
      this.props.dispatch(setLoginShow(false));
    }
    if (this.props.location.search.includes("?token")) {
      const email = getQueryVariable(this.props.location.search, "email");
      const token = getQueryVariable(this.props.location.search, "token");
      if (this.state.showValidationModal === false) {
        await this.props
          .dispatch(TucanoActions.validateAccount(email, token))
          .then((_res) => {
            this.toggleValidationModal();
          });
      }
    }
    // Force scroll to login overlay position => on top of window
    window.scrollTo(0, 0);
    disableBodyScroll();
    document.addEventListener("keydown", this.handleKeyPressed, false);
    // window.addEventListener("scroll", this.changeSwitchLanguageBackground);

    this.props.dispatch(setDiscoverMode(false));
  }

  componentWillUnmount() {
    clearAllBodyScrollLocks();
  }

  showModal() {
    this.setState({ show: true });
  }

  hideModal() {
    this.setState({ show: false });
  }

  receiveMessage = async (event) => {
    const { dispatch, t } = this.props;

    if (
      event?.data?.type === "sso:auth:done" &&
      event?.data?.payload?.newAuthToken
    ) {
      dispatch(TucanoActions.setAuthToken(event.data.payload));
      saveInLocal("authMode", "ssooperator");
      let updatedLocalStorage = getFromLocal(consts.storageKeys.tokens);
      if (!updatedLocalStorage) {
        updatedLocalStorage = {};
      }
      updatedLocalStorage = {};
      updatedLocalStorage.customerAuthToken = event.data.payload.newAuthToken;
      saveInLocal(consts.storageKeys.tokens, updatedLocalStorage);
      if (event.data.tveProviderId && event.data.tveAdapter) {
        await dispatch(
          TucanoActions.syncFromTveRequest(
            event.data.payload.newAuthToken,
            event.data.tveProviderId,
            event.data.tveAdapter
          )
        );
      }
      await dispatch(
        TucanoActions.getActiveProducts(event.data.payload.newAuthToken)
      ).then(async (data) => {
        if (data && data.productIds && data.productIds.length > 0) {
          saveInLocal("productId", data.productIds[0]);
        }
      });
      if (!consts?.appModules?.profiles) window.location.reload();
    } else if (
      event?.data?.type === "sso:auth:done" &&
      event?.data?.payload?.code === 1
    ) {
      await dispatch(
        addToast({
          text: t("Invalid auth"),
          className: "error",
          duration: 6000,
        })
      );
    }
  };

  openPopupAuthSSO = (oauthUri, clinetID, scope, callbackUri, responseType) => {
    const name = "SSO";

    // build Oauth URL
    // state is a variable that will be sent from the backend (currently we dont need anything so i pass alphanetworks as raw value as long as its required and should contain at least 8 characters)
    let url = `${oauthUri}?client_id=${clinetID}&state=${clinetID}`;
    if (scope) url = `${url}&scope=${scope}`;
    if (callbackUri) url = `${url}&redirect_uri=${callbackUri}`;
    if (responseType) url = `${url}&response_type=${responseType}`;

    // remove any existing event listeners
    window.removeEventListener("message", this.receiveMessage);
    // window features
    const width = 600;
    const height = 700;
    const left = 600; //(screen.width/2)-(width/2);
    const top = 100; //(screen.height/2)-(height/2);
    const strWindowFeatures = `toolbar=no, menubar=no, width=${width}, height=${height}, top=${top}, left=${left}`;

    if (windowObjectReference === null || windowObjectReference.closed) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
    } else if (previousUrl !== url) {
      windowObjectReference = window.open(url, name, strWindowFeatures);
      windowObjectReference.focus();
    } else {
      windowObjectReference.focus();
    }

    window.addEventListener(
      "message",
      (event) => this.receiveMessage(event),
      false
    );
    previousUrl = url;
  };

  ssoOperator = () => {
    return <></>;
  };

  SignUp = () => {
    return (
      consts.appModules.signup && (
        <div>
          <Signup Translate={Translate} />
        </div>
      )
    );
  };

  Credential = () => {
    const { isPopup } = this.props;
    return (
      consts.loginWithCredentials && (
        <div className={style.loginCredentials}>
          <LoginCredential loginAsPopup={isPopup} Translate={Translate} />
        </div>
      )
    );
  };

  Divider = () => {
    return <hr className={style.dividerVertical} />;
  };

  SsoOperator = () => {
    return (
      consts.loginWithSSO && (
        <LoginSSO
          openPopupAuthSSO={this.openPopupAuthSSO}
          Translate={Translate}
        />
      )
    );
  };

  SsoSocial = () => {
    return (
      consts.loginWithSsoSocial && <LoginSsoSocial Translate={Translate} />
    );
  };

  handleScroll = () => {
    this.setState({ switchLanguage: true });
  };

  render() {
    const { t, accountValidationError, isPopup } = this.props;

    /*const languages = consts.enum.languageId;
    const selectedLang = Object.values(languages).filter((item) => {
      return item.value === getFromLocal("languageId");
    });*/

    const leftColumn = consts.loginLayoutLeft
      ? consts.loginLayoutLeft.match(/(?:\[)[^\][]*(?:])/g).map((o) => {
          return o.replace(/]|\[/g, "");
        })
      : [];
    const rightColumn = consts.loginLayoutRight
      ? consts.loginLayoutRight.match(/(?:\[)[^\][]*(?:])/g).map((o) => {
          return o.replace(/]|\[/g, "");
        })
      : [];
    const { backgroundLoginType, backgroundLoginImagePath } = consts;

    const logoClassName = classnames({
      [style.logoSizeSmall]: consts.loginPageLogoSize === "sm",
      [style.logoSizeMedium]: consts.loginPageLogoSize === "md",
      [style.logoSizeLarge]: consts.loginPageLogoSize === "lg",
      [style.logoSizeDefault]: consts.loginPageLogoSize === "default",
    });
    let rootStyle = null;
    let customStyle = {};
    if (backgroundLoginType === "image") {
      rootStyle = style.root;
      if (backgroundLoginImagePath && backgroundLoginImagePath.length > 0) {
        customStyle = JSON.parse(
          '{"background-image":"url(' + backgroundLoginImagePath + ')"}'
        );
      }
    } else if (backgroundLoginType === "color") {
      customStyle = JSON.parse(consts.backgroundLoginColorStyle);
    }
    return (
      <div
        className={!isPopup ? rootStyle : null}
        onScroll={this.handleScroll}
        style={customStyle}
      >
        {/* {backgroundLoginType === "video" && !isPopup &&
          <video autoPlay loop muted className={style.video} >
            <source src={`/assets/${consts.backgroundVideoName}`} type="video/mp4" />
          </video>
        } */}

        {!isPopup && (
          <div className={style.changeLanguage}>
            <SwitchLanguage />
          </div>
        )}
        <div className={!isPopup ? style.container : style.modalMode}>
          <div className={style.form}>
            <div className={style.contentWrapper}>
              <div
                className={classnames(
                  logoClassName,
                  consts.logoType === "RECTANGLE"
                    ? style.logoRectangle
                    : style.logo
                )}
              >
                <span className={style.helper}></span>
                <Logo
                  isPopup={isPopup}
                  className={logoClassName}
                  type={consts.logoType || "SQUARE"}
                />
              </div>
              <div className={style.loginContainer}>
                {leftColumn.length > 0 && (
                  <div
                    className={
                      this.props.hasOwnProperty("isPopup")
                        ? style.leftColumPopup
                        : style.leftColum
                    }
                  >
                    {leftColumn.map((elm) => (
                      <div key={elm}>{this[elm]()}</div>
                    ))}
                  </div>
                )}
                {leftColumn.length > 0 && rightColumn.length > 0 && (
                  <div className={style.divider}>
                    <div className={style.loginSeparator}></div>
                    <div className={style.dividerText}>
                      <Translate t={t}>OR</Translate>
                    </div>
                    <div className={style.loginSeparator}></div>
                  </div>
                )}
                {rightColumn.length > 0 && (
                  <div
                    className={
                      this.props.hasOwnProperty("isPopup")
                        ? style.loginSSOPopup
                        : style.loginSSO
                    }
                  >
                    {rightColumn.map((elm) => (
                      <div key={elm}>{this[elm]()}</div>
                    ))}
                  </div>
                )}
              </div>
              {(this.state.show || this.state.showValidationModal) && (
                <div className={style.actionButtonContainer}>
                  {this.state.show && (
                    <Modal
                      leftContent={{ title: t("Forgotten password") }}
                      rightContent={{ canDismiss: this.hideModal.bind(this) }}
                      rootClassName={style.modal}
                      overlay={true}
                    >
                      <FormAskResetPassword
                        onCloseClick={this.hideModal.bind(this)}
                        email={this.state.username}
                      />
                    </Modal>
                  )}
                  {this.state.showValidationModal && (
                    <Modal
                      rightContent={{ canDismiss: this.toggleValidationModal }}
                      rootClassName={style.modalValidate}
                      overlay={true}
                      buttons={
                        <Button
                          rootClassName={style.confirmButton}
                          type={ButtonType.NORMAL}
                          onClick={this.toggleValidationModal}
                        >
                          <Translate t={t}>Close</Translate>
                        </Button>
                      }
                    >
                      {!accountValidationError && (
                        <>
                          <div className={style.iconContainer}>
                            <CheckIcon className={style.checkIcon} />
                          </div>
                          <h1 className={style.infoTitle}>
                            <Translate t={t}>Awesome</Translate> !
                          </h1>

                          <span className={style.contentMessage}>
                            <Translate t={t}>
                              Your account has been successfully validated,You
                              can now login on
                            </Translate>
                            {` ${consts.name}`}
                          </span>
                        </>
                      )}

                      {accountValidationError && (
                        <>
                          <div className={style.iconContainer}>
                            <Sad className={style.sadIcon} />
                          </div>
                          <h1 className={style.infoTitle}>
                            <Translate t={t}>Link has expired</Translate>
                          </h1>

                          <span className={style.contentMessage}>
                            <Translate t={t}>
                              We were not able to validate your account,Please
                              try to sign up again
                            </Translate>
                          </span>
                        </>
                      )}
                    </Modal>
                  )}
                </div>
              )}
            </div>
            {!this.props.hasOwnProperty("isPopup") && consts.guestModeEnabled && (
              <div className={style.discoverButtonContainer}>
                <Button
                  to={consts.routes.home.url}
                  rootClassName={style.actionButton}
                  type={ButtonType.LOGINGHOST}
                >
                  <Translate t={t}>Discover</Translate>
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoginLoading: state.account.login.loading,
      loginError: state.account.login.error,
      accountValidated: state.account.validateAccount.data,
      accountValidationError: state.account.validateAccount.error,
    };
  }),
  withTranslation()
)(withRouter(PageSignIn));
