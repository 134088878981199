import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";

export default class StepProgressBar extends Component {
  getPositive(steps, currentStep, currentSubStep) {
    let totSteps = 0;
    let totSubSteps = 0;
    let position = 0;
    let positionSub = 0;

    steps.forEach(function (value, index) {
      if (index === currentStep && value.hasOwnProperty("subSteps")) {
        totSubSteps = value.subSteps.length;
      }
      totSteps += 1;
    });

    position = (100 / (totSteps - 1)) * currentStep;

    if (totSubSteps > 0) {
      positionSub = (100 / (totSteps - 1) / totSubSteps) * currentSubStep;
    }

    return position + positionSub;
  }

  render() {
    const { rootClassName, currentStep, currentSubStep, steps } = this.props;

    const finalClassName = classnames(rootClassName, style.container);
    const position = this.getPositive(steps, currentStep, currentSubStep);

    return (
      <div className={finalClassName}>
        <div className={style.checkoutProgress}>
          <div
            className={style.progressBarLine}
            style={{ width: `${position}%` }}
          />
          <div className={style.progressBar}>
            {(() => {
              let stepHtml = [];
              steps.forEach(function (step, i) {
                const activeClass = classnames({
                  [style.step]: true,
                  [style.active]: i <= currentStep,
                });

                stepHtml.push(
                  <div key={i} className={activeClass}>
                    <span>{i + 1}</span>
                    <div className={style.stepCheck} />
                    <div className={style.stepLabel}>
                      <Trans>{step.name}</Trans>
                    </div>
                  </div>
                );
              });
              return stepHtml;
            })()}
          </div>
        </div>
      </div>
    );
  }
}

StepProgressBar.defaultProps = {
  currentStep: 0,
  currentSubStep: 0,
  steps: [],
};

StepProgressBar.propTypes = {
  currentStep: PropTypes.number,
  currentSubStep: PropTypes.number,
  steps: PropTypes.array,
};
