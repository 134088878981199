import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";

let result = getFromLocal(consts.storageKeys.localISP);

export function isConnectedFromLocalISP() {
  if (result === null || result === undefined) {
    result = getFromLocal(consts.storageKeys.localISP);
  }
  return result;
}
