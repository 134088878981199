import InlineSVG from "inline-svg-react";
import React from "react";

const Delete = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Delete.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Delete.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <g fill="none" fillRule="evenodd" strokeWidth="1.3">
            <path strokeLinecap="round" d="M5.5 6.508h13.097" />
            <path d="M17.668 7.838v10.908c0 1.105-.904 2.01-2.01 2.01h-7.22a2.015 2.015 0 0 1-2.008-2.01V7.838" />
            <path
              strokeLinecap="round"
              d="M10.117 10.792v9.58M13.98 10.792v9.58M17.528 6.508l-.666-1.256c-.364-.599-.742-.752-1.155-.752H8.391c-.414 0-.792.153-1.155.752L6.57 6.508"
            />
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Delete;
