import Button, { ButtonType } from "components/Button";
import Input, { InputType } from "components/Input";
import { checkPolicy, isValidPolicy } from "helpers/Password";
import { Translate, TranslateDynamic } from "helpers/utility";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class FormChangePassword extends Component {
  state = {
    input: "",
    newPassword: "",
    confirmNewPassword: "",
    currentPassword: "",
    validPasswordPolicies: [],
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleSubmit() {
    if (this.props.onSubmitClick) {
      this.props.onSubmitClick(
        this.state.currentPassword,
        this.state.newPassword,
        this.state.confirmNewPassword
      );
    }
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      if (
        this.state.currentPassword &&
        this.state.newPassword &&
        this.state.confirmNewPassword &&
        this.state.newPassword === this.state.confirmNewPassword
      ) {
        this.handleSubmit();
      }
    } else {
      if (this.state.input === "newPassword") {
        let validated = checkPolicy(
          this.props.passwordPolicy,
          event.target.value
        );
        this.setState({ validPasswordPolicies: validated });
      }
    }
  }

  /*  handleResetPassword() {
    if (this.props.onResetLinkClick) {
      this.props.onResetLinkClick();
    }
  }*/

  render() {
    const { passwordPolicy, t } = this.props;
    const { validPasswordPolicies } = this.state;
    return (
      <div className={style.root}>
        <div className={style.form}>
          <div className={style.container}>
            <div className={style.title}>
              <Trans>Enter current password</Trans>
              <Input
                type={InputType.PASSWORD}
                placeholder={""}
                onFocus={() => {
                  this.setState({
                    validPasswordPolicies: [],
                    input: "currentPassword",
                  });
                }}
                onChange={(value) => {
                  this.setState({ currentPassword: value });
                }}
                value={this.state.currentPassword}
              />
            </div>
          </div>
          <div className={style.container}>
            <div className={style.title}>
              <Trans>New password</Trans>
              <Input
                type={InputType.PASSWORD}
                placeholder={""}
                onFocus={() => {
                  let validated = checkPolicy(
                    this.props.passwordPolicy,
                    this.state.newPassword
                  );
                  this.setState({
                    validPasswordPolicies: validated,
                    input: "newPassword",
                  });
                }}
                onChange={(value) => {
                  let validated = checkPolicy(this.props.passwordPolicy, value);
                  this.setState({
                    validPasswordPolicies: validated,
                    newPassword: value,
                  });
                }}
                value={this.state.newPassword}
              />
            </div>
          </div>
          <div className={style.container}>
            <div className={style.title}>
              <Trans>Confirm new password</Trans>
              <Input
                type={InputType.PASSWORD}
                placeholder={""}
                onFocus={() => {
                  this.setState({
                    validPasswordPolicies: [],
                    input: "newPassword2",
                  });
                }}
                onChange={(value) => {
                  this.setState({ confirmNewPassword: value });
                }}
                value={this.state.confirmNewPassword}
              />
              {this.state.newPassword !== this.state.confirmNewPassword &&
                this.state.confirmNewPassword !== "" && (
                  <div className={style.error}>
                    <Trans>The passwords are different</Trans>
                  </div>
                )}
            </div>
          </div>
          <div className={style.container}>
            {passwordPolicy && passwordPolicy.length > 0 && (
              <ul className={style.passwordPolicyList}>
                {passwordPolicy.map((item, index) => {
                  if (item.message !== "" && item.value !== false) {
                    if (item.policy == "minimumCharacters") {
                      return (
                        <li
                          key={index}
                          className={isValidPolicy(
                            validPasswordPolicies,
                            item.policy,
                            style.isValidPolicy
                          )}
                        >
                          <TranslateDynamic
                            t={t}
                            value={item.value}
                            message={item.message}
                          />
                        </li>
                      );
                    }
                    return (
                      <li
                        key={index}
                        className={isValidPolicy(
                          validPasswordPolicies,
                          item.policy,
                          style.isValidPolicy
                        )}
                      >
                        <Translate t={t}>{item.message}</Translate>
                      </li>
                    );
                  }
                })}
              </ul>
            )}
          </div>
        </div>
        <div>
          <Button
            rootClassName={style.submitButton}
            type={
              this.state.currentPassword &&
              this.state.newPassword &&
              this.state.confirmNewPassword &&
              this.state.newPassword === this.state.confirmNewPassword
                ? ButtonType.NORMAL
                : ButtonType.DISABLED
            }
            onClick={this.handleSubmit.bind(this)}
          >
            <Trans>Change password</Trans>
          </Button>
          {/*          <p className={style.message}>
            <Trans>Forgotten password</Trans> ?{" "}
            <span className={style.reset} onClick={this.handleResetPassword.bind(this)}>
              <Trans>Reset it</Trans>
            </span>
          </p>*/}
        </div>
      </div>
    );
  }
}

FormChangePassword.defaultProps = {
  required: {
    currentPassword: false,
    newPassword: false,
    confirmNewPassword: false,
  },
};

FormChangePassword.propTypes = {
  required: PropTypes.object,
};

const mapStateToProps = function (state) {
  return {
    passwordPolicy: TucanoSelectors.getPasswordPolicy(state),
  };
};

export default compose(
  connect(mapStateToProps),
  withTranslation()
)(FormChangePassword);
