import { formatFullDateAndTime } from "../helpers/timeHelper";

export default class Invoice {
  constructor(data) {
    if (data) {
      this.amountWithVat = data.amountWithVat;
      this.currency = data.currency;
      this.duedate = data.duedate;
      this.startdate = data.startdate;
      this.enddate = data.enddate;
      this.idStatus = data.idStatus;
      this.invoiceDate = data.invoiceDate;
      this.invoiceId = data.invoiceId;
      this.name = data.name;
      this.paid = data.paid;
      this.status = data.status;
      this.vatAmount = data.vatAmount;
      this.type = data.type;
      this.invoiceUrl = data.invoiceUrl;
    }
  }

  getAmountWithVat() {
    return this.amountWithVat;
  }

  getType() {
    return this.type;
  }

  getInvoiceUrl() {
    return this.invoiceUrl;
  }

  getCurrency() {
    return this.currency;
  }

  getDueDate() {
    return this.duedate;
  }

  getStartDate() {
    return this.startdate;
  }

  getFormattedStartDate() {
    return formatFullDateAndTime(new Date(this.startdate * 1000));
  }

  getEndDate() {
    return this.enddate;
  }

  getFormattedEndDate() {
    return formatFullDateAndTime(new Date(this.enddate * 1000));
  }

  getIdStatus() {
    return this.idStatus;
  }

  getInvoiceDate() {
    return this.invoiceDate;
  }

  getFormattedInvoiceDate() {
    return formatFullDateAndTime(new Date(this.invoiceDate * 1000));
  }

  getInvoiceId() {
    return this.invoiceId;
  }

  getName() {
    return this.name;
  }

  getPaid() {
    return this.paid;
  }

  getStatus() {
    return this.status;
  }

  getVatAmount() {
    return this.vatAmount;
  }
}
