import classnames from "classnames";
//import PropTypes from "prop-types";
import PlayerVolume from "components/Icons/PlayerVolume";
import { ResponsiveType } from "consts/responsive";
import React, { Component, Fragment } from "react";

import style from "./style.module.css";

export default class PlayerVolumeControler extends Component {
  handleClick(event, value) {
    event.stopPropagation();
    const { currentVolume } = this.props;
    // Handle initial click on Icon
    this.props.setShowSubtitleController(false);
    if (value === 0 && !currentVolume) {
      this.props.setVolume({
        volume: 1,
        muted: true,
      });
    } else {
      this.props.setVolume({
        volume: value === 0 ? currentVolume && currentVolume.volume : value,
        muted: value === 0 ? currentVolume && !currentVolume.muted : false,
      });
    }
  }

  render() {
    const { currentVolume, viewport } = this.props;
    const activeVolume = currentVolume ? currentVolume.volume : 1;
    const isMuted = currentVolume ? currentVolume.muted : false;
    return (
      <div className={style.root}>
        <PlayerVolume
          rootClassName={style.icon}
          isMuted={isMuted}
          onClick={(event) => this.handleClick(event, 0)}
        />
        {viewport && viewport.type !== ResponsiveType.PHONE && (
          <Fragment>
            <div
              className={style.level}
              onClick={(event) => this.handleClick(event, 0.2)}
            >
              <div
                className={classnames(style.levelItem, style.level1, {
                  [style.active]: !isMuted && activeVolume >= 0.2,
                })}
              />
            </div>
            <div
              className={style.level}
              onClick={(event) => this.handleClick(event, 0.4)}
            >
              <div
                className={classnames(style.levelItem, style.level2, {
                  [style.active]: !isMuted && activeVolume >= 0.4,
                })}
              />
            </div>
            <div
              className={style.level}
              onClick={(event) => this.handleClick(event, 0.6)}
            >
              <div
                className={classnames(style.levelItem, style.level3, {
                  [style.active]: !isMuted && activeVolume >= 0.6,
                })}
              />
            </div>
            <div
              className={style.level}
              onClick={(event) => this.handleClick(event, 0.8)}
            >
              <div
                className={classnames(style.levelItem, style.level4, {
                  [style.active]: !isMuted && activeVolume >= 0.8,
                })}
              />
            </div>
            <div
              className={style.level}
              onClick={(event) => this.handleClick(event, 1)}
            >
              <div
                className={classnames(style.levelItem, style.level5, {
                  [style.active]: !isMuted && activeVolume === 1,
                })}
              />
            </div>
          </Fragment>
        )}
      </div>
    );
  }
}

PlayerVolumeControler.defaultProps = {
  currentVolume: {
    volume: 1,
    muted: false,
  },
};
