import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";

export function postAnalyticsReport(params) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.msg,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.POST_ANALYTICS_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.POST_ANALYTICS_SUCCESS,
            payload: payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.POST_ANALYTICS_ERROR,
            meta,
          };
        },
      });
    });
  };
}
