import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import AssetContext from "components/AssetContext";
import FreeToAir from "components/Icons/FreeToAir";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";

import style from "./style.module.css";

class ThumbnailContinueWatching extends PureComponent {
  state = {
    hover: false,
    metaItem: null,
  };

  async componentDidMount() {
    if (consts.displayVodData) {
      const { asset } = this.props;
      let metadata;
      if (asset instanceof TucanoModels.Asset && asset.getVodData()) {
        metadata = asset.getVodData();
      }
      await this.setState({ metaItem: metadata });
    }
  }

  subscriptionModal(asset) {
    const { accountStatus, dispatch } = this.props;
    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  isLock() {
    const { asset, accountStatus } = this.props;

    if (accountStatus && accountStatus.toLowerCase() === "suspended")
      return true;
    if (!consts.padlocks) return false;
    if (asset.isCategory) return false;
    if (asset.isAssetTVShow && !asset.isAssetTVShowEpisode) return false;
    if (asset.getType() === "link") return false;
    if (!this.props.isConnected) return false;
    return !asset.userHasAccess;
  }

  get assetContent() {
    const {
      asset,
      onlyFavIcon,
      onlyPlay,
      fta,
      isConnected,
      showOnlyLogo,
      usePosterImage,
    } = this.props;
    const { hover } = this.state;

    // apply border radius && shadow
    const borderRadiusStyle =
      (consts.thumbnailBorderRadius &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[0][consts.thumbnailBorderRadius.toLowerCase()]
        ]) ||
      "";
    const shadowStyle =
      (consts.thumbnailShadow &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[1][consts.thumbnailShadow.toLowerCase()]
        ]) ||
      "";

    return (
      <Fragment>
        <div className={style.content}>
          <Image
            src={asset.getImage(consts.image_hostname, {
              width: 764,
              usePosterImage: usePosterImage,
              height: 430,
              languageId: getFromLocal("languageId"),
            })}
            rootClassName={borderRadiusStyle + " " + shadowStyle}
            ratio={ImageRatio.COVER}
            placeholder={PlaceholderType.PRIMARY}
          />
          {asset && asset.flyImage && (
            <div className={classnames(style.VODLogo)}>
              <LogoVod
                className={classnames(style.vodData)}
                type={asset.flyImage}
                size={40}
              />
            </div>
          )}

          {fta && !isConnected && (
            <div className={style.ftaIconContainer}>
              <FreeToAir className={style.fta} />
            </div>
          )}
          {!!asset.getSeekTime() && (
            <ProgressBar
              position={asset.getProgress()}
              rootClassName={style.progressBar}
            />
          )}
          <div className={style.gradient} />
        </div>
        <ThumbnailInfos
          show={hover}
          model={asset}
          onlyFavIcon={onlyFavIcon}
          onlyPlay={onlyPlay}
          subscriptionModal={this.subscriptionModal.bind(this)}
          showOnlyLogo={showOnlyLogo}
        />
      </Fragment>
    );
  }

  render() {
    const { asset } = this.props;
    const { hover } = this.state;
    /*let catalog;
    if (asset instanceof TucanoModels.TVShow) {
      catalog = asset.getCatalogue();
    }
    if (asset.catalogs) {
      catalog = asset.catalogs;
    }*/
    let url;

    if (asset) {
      url = consts.routes.movieAsset.url.replace(":id", asset.getId());
    }

    if (!asset.isCatchup() && !asset.getCatchupId()) {
      if (asset.isTVShowEpisode()) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      }
    }

    return (
      <div className={style.root}>
        <div
          className={classnames(style.assetContainer, {
            [style.container_hover]: hover,
          })}
          style={this.props.style}
        >
          {!this.isLock() ? (
            <Link
              to={consts.routes.player.url.replace(":assetId", asset.getId())}
              onMouseEnter={() => this.setState({ hover: this.props.infos })}
              onMouseLeave={() => this.setState({ hover: false })}
            >
              {this.assetContent}
            </Link>
          ) : (
            <div
              onMouseEnter={() => this.setState({ hover: this.props.infos })}
              onMouseLeave={() => this.setState({ hover: false })}
            >
              {this.assetContent}
            </div>
          )}
        </div>
        <Link to={url} className={style.link} style={this.props.style}>
          <AssetContext asset={asset} />
        </Link>
      </div>
    );
  }
}

ThumbnailContinueWatching.defaultProps = {
  asset: {},
  rootClassName: "",
  thumbnailSize: {},
  infos: true,
  onlyPlay: true,
  onlyFavIcon: true,
  showOnlyLogo: false,
};

ThumbnailContinueWatching.propTypes = {
  asset: PropTypes.object,
  rootClassName: PropTypes.string,
  thumbnailSize: PropTypes.object,
  infos: PropTypes.bool,
  onlyPlay: PropTypes.bool,
  onlyFavIcon: PropTypes.bool,
  showOnlyLogo: PropTypes.bool,
};

export default compose(
  connect((state, _props) => {
    return {
      accountStatus: state.account.user?.data?.status,
    };
  })
)(ThumbnailContinueWatching);
