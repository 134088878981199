import BackArrow from "components/Icons/Arrowheading";
import PlayerErrorMessage from "components/PlayerErrorMessage";
import PropTypes from "prop-types";
import React, { Component } from "react";
import ReactPlayer from "react-player";
import { connect } from "react-redux";
import { withRouter } from "react-router";

import style from "./style.module.css";

class PlayerReact extends Component {
  state = {
    seek: 0,
  };

  componentDidMount() {
    const { assetInfo } = this.props;
    // console.log(assetInfo);
    if (assetInfo && assetInfo.seek) {
      this.setState({ seek: assetInfo.seek });
    }
  }

  _onReady(_event) {
    // console.log("_onReady", event);
    this.player.seekTo(this.state.seek);
    this.sendSeekAsset(this.state.seek);
  }

  _onStart() {
    // console.log("_onStart");
  }

  _onProgress(event) {
    // console.log("_onProgress", event);
    if (event && event.playedSeconds) {
      this.setState({ seek: event.playedSeconds });
      this.sendSeekAsset(event.playedSeconds);
    }
  }

  _onPause() {
    // console.log("_onPause");
    this.setState({ seek: this.player.getCurrentTime() });
    this.sendSeekAsset(this.player.getCurrentTime());
  }

  _onSeek(event) {
    // console.log("_onSeek", event);
    if (event) {
      this.setState({ seek: event });
      this.sendSeekAsset(event);
    }
  }

  _onEnded() {
    this.sendSeekAsset(this.state.seek);
  }

  _onError() {
    this.handleClickBack();
  }

  handleClickBack(event) {
    if (event) {
      event.stopPropagation();
      event.preventDefault();
    }
    if (this.props.handleclickonback) {
      this.props.handleclickonback();
    }
  }

  sendSeekAsset(seconds) {
    if (seconds && this.props.postVideoProgress) {
      this.props.postVideoProgress(Math.floor(seconds));
    }
  }

  render() {
    const { url, previousRoute, assetInfo, isTrailer } = this.props;

    const { seek } = this.props;

    const opts = {
      youtube: {
        playerVars: {
          autoplay: 1,
          showinfo: 0,
          showInfo: 0,
          rel: 0,
          start: seek,
        },
      },
      vimeo: {
        playerOptions: {
          height: "100%",
          width: "100%",
          autoplay: 1,
        },
      },
    };

    return (
      <div className={isTrailer ? style.rootTrailer : style.root}>
        <div
          className={style.headerContainer}
          onClick={this.handleClickBack.bind(this)}
        >
          <BackArrow className={style.headerBack} />
          <div className={style.headerTexts}>
            {assetInfo.title && (
              <p className={style.headerTitle}>{assetInfo.title}</p>
            )}
            {assetInfo.subtitle && (
              <p className={style.headerSubtitle}>{assetInfo.subtitle} </p>
            )}
            {assetInfo.rating && (
              <p className={style.headerRating}> | {assetInfo.rating}</p>
            )}
          </div>
        </div>
        <div className={style.playerWrapper}>
          {url ? (
            <ReactPlayer
              ref={(player) => {
                this.player = player;
              }}
              className={style.reactPlayer}
              url={url}
              config={opts}
              playing={true}
              controls={true}
              loop={true}
              width={"100%"}
              height={"100%"}
              progressInterval={10000}
              onReady={this._onReady.bind(this)}
              onStart={this._onStart.bind(this)}
              onProgress={this._onProgress.bind(this)}
              onPause={this._onPause.bind(this)}
              onSeek={this._onSeek.bind(this)}
              onEnded={this._onEnded.bind(this)}
              onError={this._onError.bind(this)}
            />
          ) : (
            <PlayerErrorMessage
              closeAction={() => {
                this.props.history.replace(previousRoute ? previousRoute : "/");
              }}
            />
          )}
        </div>
      </div>
    );
  }
}

PlayerReact.defaultProps = {
  url: "",
};

PlayerReact.propTypes = {
  url: PropTypes.string,
};

export default withRouter(
  connect((_state, _props) => {
    // console.log("state in player => ", state, props);
    return {};
  })(PlayerReact)
);
