import consts from "consts/consts";
import i18n from "i18next";
import HttpApi from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

const languageIdMapping = {
  eng: "en",
  fra: "fr",
  por: "pt",
  ita: "it",
  spa: "es",
  ger: "de",
  ara: "ar",
};

var options = {
  whitelist: ["en", "fr", "pt", "it", "es", "de", "ar"],
  /* default language when load the website in browser */
  lng: languageIdMapping[consts.languageId],
  /* When react i18next not finding any language to as default in borwser */
  fallbackLng: languageIdMapping[consts.languageId],
  ns: ["translations"],
  defaultNS: "translations",
  // allow keys to be phrases having `:`, `.`
  nsSeparator: false,
  backend: {
    loadPath: "/lang/{{lng}}.json",
  },
  //resources,
  detection: {
    order: ["querystring", "cookie", "navigator", "localStorage"],
  },
  lookupQuerystring: "lng",
  caches: ["localStorage", "cookie"],
  react: {
    wait: true,
    bindI18n: "languageChanged loaded",
    bindStore: "added removed",
    nsMode: "default",
  },
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
    formatSeparator: ",",
  },
  /* debugger For Development environment */
  // debug: process.env.NODE_ENV !== "production",
};

i18n
  // load translation using xhr -> see /public/locales
  // learn more: https://github.com/i18next/i18next-xhr-backend
  .use(HttpApi)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(options);

export default i18n;
