import InlineSVG from "inline-svg-react";
import React from "react";

const OnDemand = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/OnDemand.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/OnDemand.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M17.277 10.31H6.74l1.736 10.937h7.065l1.736-10.937zm-6.636 12.302L9.702 8.946l.94 13.666zM8.704 9.015l1.995-.137.94 13.666-1.996.137-.94-13.666zm4.677 13.597l.94-13.666-.94 13.666zm-.057-13.734l1.995.137-.94 13.666-1.995-.137.94-13.666zm.574-.488l.425-.175.074.175h-.5zm-9.488 0h-.18A3.023 3.023 0 0 1 4 7.24c0-1.67 1.36-3.03 3.03-3.03.226 0 .45.026.67.077A4.83 4.83 0 0 1 12.314.92a4.868 4.868 0 0 1 4.791 4.043 3.555 3.555 0 0 1 2.697 3.427h-.196l-2.358 14.857H6.769L4.409 8.39zm2.153-.08h.95c.29-.13.524-.37.633-.687l.262-.759-.616-.524a1.169 1.169 0 0 0-.763-.29 1.19 1.19 0 0 0-1.19 1.189c0 .488.302.893.724 1.071zm4.144 0h7.253a1.711 1.711 0 0 0-1.706-1.61h-.92v-.92a3.023 3.023 0 0 0-3.02-3.02A3.005 3.005 0 0 0 9.31 5.673l-.034.96.512.787c.24.369.558.666.92.89z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default OnDemand;
