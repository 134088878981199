import { addToast } from "actions/ui";
import ContentWrapper from "components/ContentWrapper";
import FormProfile from "components/FormProfile";
import Header from "components/Header";
import LinkBack from "components/LinkBack";
import Modal from "components/Modal";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageEditProfile extends Component {
  state = {
    data: {
      name: "",
      kidProfile: false,
      maximumMoralityLevel: 0,
      language: getFromLocal("languageId"),
    },
    showDeleteModal: false,
  };

  errorAPIUpdateProfile = {};

  errorAPIDeleteProfile = {
    "-3051": "A default profile is mandatory",
  };

  getErrorAPI(api, code) {
    let error = "An error occured,Please retry later";

    switch (api) {
      case "updateProfile":
        if (this.errorAPIUpdateProfile[code]) {
          error = this.errorAPIUpdateProfile[code];
        }
        break;
      case "deleteProfile":
        if (this.errorAPIDeleteProfile[code]) {
          error = this.errorAPIDeleteProfile[code];
        }
        break;
      default:
        break;
    }

    return error;
  }

  static fetch(dispatch, isConnected) {
    if (isConnected) {
      dispatch(TucanoActions.getProfile());
      dispatch(TucanoActions.getActiveProfile());
      dispatch(TucanoActions.getAvatars());
    }
  }

  componentDidMount() {
    const { t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }

    if (consts.appModules.profiles !== true) {
      this.props.history.replace("/");
    }

    if (!this.props.isConnected) {
      this.props.history.push("?login=1");
    } else {
      PageEditProfile.fetch(this.props.dispatch, this.props.isConnected);
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    const id = parseInt(this.props.match.params.id);
    if (
      prevProps !== this.props &&
      this.props.profiles &&
      prevProps.profiles !== this.props.profiles
    ) {
      const profileObject = this.props.profiles.find((item) => {
        return item.id === id;
      });
      Object.assign(profileObject, { imageId: profileObject.picture });
      if (profileObject) {
        this.setState({
          data: profileObject,
        });
      } else {
        this.props.history.push(consts.routes.profiles.url);
      }
    }
  }

  handleUpdateProfile(params) {
    const { t } = this.props;
    const profiles = this.props.profiles;
    const currentProfile = profiles.filter((profile) => {
      if (+profile.id === +this.props.match.params.id) {
        return profile;
      }
      return undefined;
    });

    this.props
      .dispatch(TucanoActions.createOrUpdateProfile(params))
      .then(() => {
        const { dataOnUpdateProfile } = this.props;
        if (dataOnUpdateProfile && !dataOnUpdateProfile.code) {
          this.props.dispatch(
            addToast({
              text: t("Your profile has been successfully updated"),
              className: "primary",
              duration: 6000,
            })
          );
          this.props.dispatch(TucanoActions.getProfile());
          this.props.dispatch(TucanoActions.getActiveProfile());
          this.setState((prevState) => ({
            ...prevState,
            data: {
              name: "",
              kidProfile: false,
              maximumMoralityLevel: 0,
              language:
                currentProfile.language || this.props.userInfo.languageId,
            },
          }));
        }

        if (dataOnUpdateProfile && dataOnUpdateProfile.code) {
          this.props.dispatch(
            addToast({
              text: t(
                this.getErrorAPI("updateProfile", dataOnUpdateProfile.code)
              ),
              className: "error",
              duration: 6000,
            })
          );
        }
      })
      .catch((error) => {
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI("updateProfile", error)),
            className: "error",
            duration: 6000,
          })
        );
      });
  }

  handleDeleteProfile() {
    const { profiles, activeProfile, t } = this.props;
    const { data } = this.state;

    this.props
      .dispatch(TucanoActions.deleteProfile(data.id))
      .then(() => {
        const { dataOnDeleteProfile } = this.props;
        if (dataOnDeleteProfile.newAuthToken !== null) {
          this.props.dispatch(
            addToast({
              text: t("Your profile has been successfully deleted"),
              className: "primary",
              duration: 6000,
            })
          );
          if (activeProfile.getId() === data.id) {
            let defaultProfile = profiles.find((x) => x.default === true);
            if (defaultProfile) {
              this.props
                .dispatch(
                  TucanoActions.setActiveProfile(defaultProfile.getId(), null)
                )
                .then(() => {
                  this.props.dispatch(TucanoActions.getProfile());
                  this.props.dispatch(TucanoActions.getActiveProfile());
                  this.props.history.push(consts.routes.profiles.url);
                });
            }
          } else {
            this.props.dispatch(TucanoActions.getProfile());
            this.props.history.push(consts.routes.profiles.url);
          }
        } else {
          this.props.dispatch(
            addToast({
              text: t(
                this.getErrorAPI("deleteProfile", dataOnDeleteProfile.code)
              ),
              className: "error",
              duration: 6000,
            })
          );
        }
      })
      .catch((error) => {
        this.props.dispatch(
          addToast({
            text: t(this.getErrorAPI("deleteProfile", error)),
            className: "error",
            duration: 6000,
          })
        );
      });
  }

  showDeleteModal() {
    this.setState({ showDeleteModal: true });
  }

  hideDeleteModal() {
    this.setState({ showDeleteModal: false });
  }

  render() {
    const { activeProfile, t, avatars } = this.props;
    const { showDeleteModal } = this.state;
    return (
      <ContentWrapper>
        <div className={style.root}>
          <LinkBack rootClassName={style.linkBack} />
          <div className={style.contentContainer}>
            <Header>
              <Trans>{this.props.route.title}</Trans>
            </Header>
            {this.state.data.id && (
              <FormProfile
                onSubmitClick={this.handleUpdateProfile.bind(this)}
                onDeleteClick={this.showDeleteModal.bind(this)}
                data={this.state.data}
                activeProfile={activeProfile}
                avatars={avatars}
              />
            )}
          </div>
          {showDeleteModal === true && (
            <Modal
              size={"sm"}
              leftContent={{ title: t("Delete profile") }}
              rightContent={{ canDismiss: this.hideDeleteModal.bind(this) }}
              rootClassName={style.modal}
              overlay={true}
              buttons={
                <>
                  <span
                    onClick={this.hideDeleteModal.bind(this)}
                    className={style.cancelModalButton}
                  >
                    <Trans>CANCEL</Trans>
                  </span>
                  <span
                    onClick={this.handleDeleteProfile.bind(this)}
                    className={style.deleteModalButton}
                  >
                    <Trans>DELETE</Trans>
                  </span>
                </>
              }
            >
              <p>
                {t("Are you sure to delete {{ name }} profile?", {
                  name: this.state.data.name,
                })}
              </p>
              {activeProfile && activeProfile.getId() === this.state.data.id && (
                <p>
                  <Trans>Main profile will be automatically selected</Trans>
                </p>
              )}
            </Modal>
          )}
        </div>
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      customerAuthToken: state.session.customerAuthToken,
      userInfo: TucanoSelectors.getUserInfo(state),
      profiles: TucanoSelectors.getProfile(state),
      avatars: TucanoSelectors.getAvatars(state),
      activeProfile: TucanoSelectors.getActiveProfile(state),
      dataOnUpdateProfile: state.account.updateProfile.data,
      dataOnDeleteProfile: state.account.deleteProfile.data,
    };
  }),
  withTranslation()
)(PageEditProfile);
