import classnames from "classnames";
import Button from "components/Button";
import { ButtonType } from "components/Button";
import FavoriteAdd from "components/Icons/FavoriteAdd";
import FavoriteRemove from "components/Icons/FavoriteRemove";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";

export default class FavoriteToggleButton extends Component {
  setFavoriteStatus(e) {
    e.stopPropagation();
    e.preventDefault();

    if (this.props.onFavoriteClick) {
      const previousState = this.props.isAssetFavorite;
      this.props.onFavoriteClick(e, !previousState);
    }
  }

  render() {
    const { rootClassName, isPhoneSize } = this.props;

    return (
      <Fragment>
        {!isPhoneSize && (
          <div
            className={classnames(style.root, rootClassName)}
            onClick={this.setFavoriteStatus.bind(this)}
          >
            {this.props.isAssetFavorite === true ? (
              <Button
                rootClassName={style.button}
                type={ButtonType.BORDERED}
                icon={<FavoriteRemove className={style.removeFavIcon} />}
              >
                <span className={style.textButton}>
                  <Trans>Delete from favorite</Trans>
                </span>
              </Button>
            ) : (
              <Button
                rootClassName={style.button}
                type={ButtonType.BORDERED}
                icon={<FavoriteAdd className={style.addFavIcon} />}
              >
                <span className={style.textButton}>
                  <Trans>Add to favorite</Trans>
                </span>
              </Button>
            )}
          </div>
        )}
        {isPhoneSize && (
          <div
            className={classnames(style.root, rootClassName)}
            onClick={this.setFavoriteStatus.bind(this)}
          >
            {this.props.isAssetFavorite === true ? (
              <div className={style.buttonContainer}>
                <Button
                  rootClassName={style.roundButton}
                  type={ButtonType.BORDERED}
                  icon={<FavoriteRemove className={style.removeRoundFavIcon} />}
                ></Button>
                <div className={style.textButton}>
                  <Trans>Delete from favorite</Trans>
                </div>
              </div>
            ) : (
              <div className={style.buttonContainer}>
                <Button
                  rootClassName={style.roundButton}
                  type={ButtonType.BORDERED}
                  icon={<FavoriteAdd className={style.addRoundFavIcon} />}
                ></Button>
                <div className={style.text}>
                  <Trans>Add to favorite</Trans>
                </div>
              </div>
            )}
          </div>
        )}
      </Fragment>
    );
  }
}
