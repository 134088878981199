import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Input, { InputType } from "components/Input";
import Modal from "components/Modal";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const ADULT_CODE_REGEXP = /^[0-9]*$/;

class ModalValidatePurchaseCode extends Component {
  state = {
    data: {
      purchaseCode: "",
    },
    error: {
      purchaseCode: "",
    },
    focus: {
      purchaseCode: false,
    },
    digitNumberLength: 4,
  };

  constructor(props) {
    super(props);
    this.handleKeyPressed = this.handleKeyPressed.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPressed, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPressed, false);
  }

  handleKeyPressed(event) {
    if (event.key === "Enter") {
      if (
        this.state.focus.purchaseCode &&
        this.state.error.purchaseCode === ""
      ) {
        this.handleSubmit();
      }
    }
  }

  handleClose() {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  handleCancel() {
    if (this.props.onCloseClick) {
      this.props.onCancelClick();
    }
  }

  handleSubmit() {
    const { assetId, dispatch, onConfirmClick, asset } = this.props;
    // eslint-disable-next-line no-unused-vars
    let audioLang, subtitleLang;
    dispatch(
      TucanoActions.validatePurchaseCode(this.state.data.purchaseCode)
    ).then((result) => {
      if (result && result.newAuthToken && this.props.onConfirmClick) {
        onConfirmClick(this.state.data.purchaseCode);
        if (assetId) {
          if (asset && asset.lang) {
            audioLang = asset.lang[0].audio;
            subtitleLang = asset.lang[0].sub;
          }
          dispatch(TucanoActions.readAsset(assetId));
        }
      } else {
        const { t } = this.props;
        const updatedState = this.state;
        updatedState["error"]["purchaseCode"] = t(
          "You have entered the wrong purchase code"
        );
        this.setState(updatedState);
      }
    });
  }

  handleValidatePurchaseControl(key, value) {
    const updatedState = this.state;
    const { t } = this.props;
    updatedState["data"][key] = value;

    if (
      ADULT_CODE_REGEXP.test(value) &&
      value.length === this.state.digitNumberLength
    ) {
      updatedState["error"][key] = "";
    } else {
      updatedState["error"][key] = t("Purchase code is a 4 digit number");
    }
    updatedState["focus"][key] = true;
    this.setState(updatedState);
  }

  render() {
    const { rootClassName, isLoading, t } = this.props;
    const finalClassName = classnames(rootClassName, style.root);

    return (
      <Modal
        size={"sm"}
        leftContent={{ title: t("purchaseCode") }}
        rightContent={{ canDismiss: this.handleClose.bind(this) }}
        rootClassName={finalClassName}
        overlay={true}
        buttons={
          <>
            {isLoading && (
              <Button
                rootClassName={style.submitButton}
                type={ButtonType.LOADING}
              >
                <Trans>purchaseCode</Trans>
              </Button>
            )}

            {!isLoading && (
              <Button
                rootClassName={style.submitButton}
                type={
                  !this.state.focus.purchaseCode ||
                  this.state.error.purchaseCode !== ""
                    ? ButtonType.DISABLED
                    : ButtonType.NORMAL
                }
                onClick={this.handleSubmit.bind(this)}
              >
                <Trans>OK</Trans>
              </Button>
            )}
          </>
        }
      >
        <div className={style.form}>
          <div className={style.container}>
            <label className={style.title}>
              <Trans>Enter purchase code</Trans>
            </label>
            <Input
              type={InputType.PASSWORD}
              onChange={(value) =>
                this.handleValidatePurchaseControl("purchaseCode", value)
              }
              value={this.state.data.purchaseCode}
            />
            {this.state.error.purchaseCode && (
              <p className={style.error}>
                <Trans>{this.state.error.purchaseCode}</Trans>
              </p>
            )}
          </div>
        </div>
      </Modal>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isLoading: state.account.validatePurchaseCode.loading,
      asset: TucanoSelectors.getAsset(state),
    };
  }),
  withTranslation()
)(ModalValidatePurchaseCode);
