import InlineSVG from "inline-svg-react";
import React from "react";

const SvgPlaylist = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/SvgPlaylist.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/SvgPlaylist.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M3.333 7.667V6.333H6v1.334H3.333zm4.667 0V6.333h12.667v1.334H8zM3.333 13v-1.333H6V13H3.333zM8 13v-1.333h12.667V13H8zm-4.667 5.333V17H6v1.333H3.333zm4.667 0V17h12.667v1.333H8z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default SvgPlaylist;
