import { ButtonType } from "components/Button";
import Button from "components/Button";
import consts from "consts/consts";
import React from "react";

import style from "./style.module.css";

function CastLabel(props) {
  const { children } = props;
  return (
    <Button
      rootClassName={style.roundButton}
      to={
        consts.routes.search.url.replace(":term", children) +
        "searchField=actor"
      }
      type={ButtonType.GHOST}
    >
      {children}
    </Button>
  );
}

export default CastLabel;
