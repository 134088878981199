import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import consts from "consts/consts";
import ModalValidateParentalCode from "containers/ModalValidateParentalControl";
import Profile from "containers/Profile";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageManageProfile extends Component {
  state = {
    showParentalControl: undefined,
  };

  static async fetch(dispatch, isConnected) {
    if (isConnected) {
      await dispatch(TucanoActions.getActiveProfile());
      await dispatch(TucanoActions.getProfile());
    }
  }
  componentDidMount() {
    const { isConnected, t } = this.props;

    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.appModules.profiles !== true) {
      this.props.history.replace("/");
    }

    if (isConnected) {
      PageManageProfile.fetch(this.props.dispatch, this.props.isConnected).then(
        () => {
          const { activeProfile } = this.props;
          if (activeProfile && activeProfile.getKidProfile() === true) {
            this.setState({ showParentalControl: true });
          } else {
            this.setState({ showParentalControl: false });
          }
        }
      );
    } else {
      this.props.history.push("?login=1");
    }
  }

  selectProfile(item) {
    this.props.history.push(
      consts.routes.profile.url.replace(":id", item.getId())
    );
  }

  hideModalModalParentalControl() {
    this.setState({ showParentalControl: false });
  }

  render() {
    const { showParentalControl } = this.state;

    return (
      <ContentWrapper>
        <div className={style.root}>
          <Header>
            <Trans>{this.props.route.title}</Trans>
          </Header>
          {showParentalControl === true && (
            <ModalValidateParentalCode
              onCloseClick={() => window.history.go(-1)}
              onCancelClick={() => window.history.go(-1)}
              onConfirmClick={this.hideModalModalParentalControl.bind(this)}
            />
          )}
          {showParentalControl === false && (
            <Profile selectProfile={this.selectProfile.bind(this)} />
          )}
        </div>
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      customerAuthToken: state.session.customerAuthToken,
      activeProfile: TucanoSelectors.getActiveProfile(state),
    };
  }),
  withTranslation()
)(PageManageProfile);
