import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import LinkBack from "components/LinkBack";
import Loader from "components/Loader";
import Strate from "components/Strate";
import ThumbnailPoster from "components/ThumbnailPoster";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { getFromLocal } from "helpers/localStorage";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { connect } from "react-redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

const VOD_TYPE = 1;

class PageSuggest extends Component {
  state = {
    isLoading: false,
  };

  static async fetch(dispatch, isConnected, params) {
    const id = params.id;
    const languageId = getFromLocal("languageId") ?? consts.languageId;
    await dispatch(TucanoActions.getAsset(id, languageId));
    await dispatch(TucanoActions.getMultifacetRecommendation(id, languageId));

    if (isConnected) {
      dispatch(
        TucanoActions.getActiveAssets({
          languageId: languageId,
          allAssets: 1,
          count: 1000,
        })
      );
      dispatch(TucanoActions.getAssetScore(id, VOD_TYPE));
      dispatch(TucanoActions.getFavorites(languageId));
    }
  }

  componentDidMount() {
    this.setState({ isLoading: true });
    PageSuggest.fetch(
      this.props.dispatch,
      this.props.isConnected,
      this.props.match.params
    ).then(() => {
      this.setState({ isLoading: false });
    });
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { asset, viewport } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });

    const strates = asset ? asset.getMultifacetRecommendation() : [];

    return (
      <Fragment>
        {this.state.isLoading && <Loader centered={true} />}
        {!this.state.isLoading && (
          <ContentWrapper>
            <LinkBack rootClassName={style.backButton} />
            <Header rootClassName={style.header}>
              <Trans>Suggestions</Trans>
            </Header>
            {asset && (
              <h2 className={style.subtitle}>
                <Trans>From</Trans> {asset.getTitle()}
              </h2>
            )}
            <Fragment>
              {strates.map((strate, index) => {
                return (
                  <Fragment key={index}>
                    {strate.getLayout() === "line" && (
                      <Strate item={strate}>
                        {strate.getItems().map((item, item_index) => {
                          // Multiple itemId exists for Assets
                          return (
                            <ThumbnailPoster
                              key={item_index}
                              asset={item}
                              size={thumbnailSize}
                              subscriptionModal={this.subscriptionModal.bind(
                                this
                              )}
                            />
                          );
                        })}
                      </Strate>
                    )}
                  </Fragment>
                );
              })}
            </Fragment>
          </ContentWrapper>
        )}
      </Fragment>
    );
  }
}

export default connect((state) => {
  return {
    viewport: state.ui.viewport,
    isConnected: state.session.customerAuthToken !== undefined,
    asset: TucanoSelectors.getAsset(state),
  };
})(PageSuggest);
