import ContentWrapper from "components/ContentWrapper";
import Header from "components/Header";
import ThumbnailSquare from "components/ThumbnailSquare";
import consts from "consts/consts";
import responsiveConf from "consts/responsive";
import { getFromLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoConsts, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageCatchUp extends Component {
  componentDidMount() {
    const { t } = this.props;
    if (consts.appModules.catchup !== true) {
      this.props.history.replace("/");
    }
    document.title = `${t(this.props.route.title)} - ${consts.name}`;
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }
    if (consts.catchupMetaData.length > 0) {
      document.getElementsByTagName("meta")[3].content =
        consts.catchupMetaData[0].content;
      document.getElementsByTagName("meta")[4].content =
        consts.catchupMetaData[1].content;
      document.getElementsByTagName("meta")[5].content =
        consts.catchupMetaData[2].content;
    }
    this.props.dispatch(
      TucanoActions.getChannels(
        undefined,
        undefined,
        getFromLocal("languageId")
      )
    );

    // // Test if only one channel ==> go directely to PageCatchupChannel
    if (this.props.items && this.props.items.length === 1) {
      const channel = this.props.items[0];
      const url = consts.routes.catchupchannel.url.replace(
        ":slug",
        channel.getSlug()
      );
      this.props.history.push({ pathname: url, state: { channel: channel } });
    }
  }

  subscriptionModal(asset) {
    if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }

  render() {
    const { items, viewport, isConnected } = this.props;
    const thumbnailSize = responsiveConf.find((item) => {
      return item.name === viewport.type;
    });
    return (
      <ContentWrapper>
        <div className={style.root}>
          <Header>
            <Trans>{this.props.route.title}</Trans>
          </Header>
          <div className={style.itemContainer}>
            {items?.map((item, index) => (
              <ThumbnailSquare
                key={index}
                item={item}
                fta={false}
                size={thumbnailSize}
                isConnected={isConnected}
                noPlay={true}
                linkToPlayer={false}
                subscriptionModal={this.subscriptionModal.bind(this)}
                showFavorite={false}
              />
            ))}
          </div>
        </div>
      </ContentWrapper>
    );
  }
}

export default compose(
  connect((state) => {
    const catalog = TucanoSelectors.getCatalog(
      state,
      TucanoConsts.CatalogType.CATCHUP
    );
    const items =
      catalog && TucanoSelectors.getCatchupChannels(state, catalog.idCatalog);
    return {
      items: items,
      viewport: state.ui.viewport,
      isConnected: state.session.customerAuthToken !== undefined,
    };
  }),
  withTranslation()
)(PageCatchUp);
