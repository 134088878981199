import InlineSVG from "inline-svg-react";
import React from "react";

const Profiles = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Profiles.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Profiles.svg");
    } catch (err) {
      return (
        <svg width={24} height={22} viewBox="0 0 24 22" {...props}>
          <path d="M9.238 13.708C8.489 13.141 8 12.2 8 11.136c0-.491.104-.956.29-1.37a3.987 3.987 0 0 0-3.094-1.482c-2.234 0-4.041 1.864-4.041 4.16V16H0v-3.557c0-2.288 1.383-4.24 3.329-5.014a3.39 3.39 0 0 1-1.24-2.645c0-1.84 1.42-3.333 3.175-3.333 1.754 0 3.175 1.492 3.175 3.333a3.387 3.387 0 0 1-1.286 2.681A5.206 5.206 0 0 1 8.99 8.774 2.76 2.76 0 0 1 10.895 8c.798 0 1.52.35 2.044.914a6.002 6.002 0 0 1 2.905-2.34 3.666 3.666 0 1 1 4.415.04C22.452 7.493 24 9.611 24 12.09V16h-1.333v-3.911c0-2.524-2.087-4.574-4.667-4.574a4.67 4.67 0 0 0-4.307 2.81 3.387 3.387 0 0 1-.36 2.503v1.285c1.54.833 2.597 2.538 2.597 4.509v3.345h-1.053v-3.345c0-2.16-1.648-3.913-3.685-3.913s-3.685 1.754-3.685 3.913v3.345H6.455v-3.345c0-2.067 1.162-3.841 2.825-4.625L9.238 14v-.292zm8.84-12.375a2.335 2.335 0 0 0-2.333 2.333A2.336 2.336 0 0 0 18.078 6a2.336 2.336 0 0 0 2.333-2.334 2.335 2.335 0 0 0-2.333-2.333zM5.264 2.663c-1.114 0-2.02.951-2.02 2.121s.906 2.123 2.02 2.123c1.114 0 2.02-.952 2.02-2.123 0-1.17-.906-2.121-2.02-2.121zm5.631 10.607c1.047 0 1.895-.956 1.895-2.135 0-1.18-.848-2.135-1.895-2.135C9.848 9 9 9.956 9 11.135c0 1.18.848 2.135 1.895 2.135z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Profiles;
