export function checkPolicy(passwordPolicy, password) {
  let validPolicies = [];
  passwordPolicy.map((item) => {
    if (item.policy === "minimumCharacters") {
      password.length >= item.value && validPolicies.push(item.policy);
    }
    if (item.policy === "upperCaseEnabled") {
      hasUpperCase(password) && validPolicies.push(item.policy);
    }
    if (item.policy === "numbersEnabled") {
      hasNumbers(password) && validPolicies.push(item.policy);
    }
    if (item.policy === "specialCharacterEnabled" && item.value !== false) {
      containsSpecialChars(password, item.value) &&
        validPolicies.push(item.policy);
    }
  });
  return validPolicies;
}

export function hasUpperCase(password) {
  return new RegExp("[A-Z]").test(password);
}

export function hasNumbers(password) {
  return new RegExp("[0-9]").test(password);
}

export function containsSpecialChars(password, patternSpecialChar) {
  let specialChars = patternSpecialChar.split("");
  let isValid = false;
  specialChars.forEach((specialChar) => {
    if (password.indexOf(specialChar) > -1) {
      isValid = true;
    }
  });
  return isValid;
}

export function isValidPolicy(passwordPolicy, policy, validStyle) {
  return passwordPolicy.includes(policy) ? validStyle : "";
}
