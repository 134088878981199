import About from "components/Icons/About";
import Account from "components/Icons/Account";
import CatchUp from "components/Icons/CatchUp";
import ContinueWatching from "components/Icons/ContinueWatching";
import Devices from "components/Icons/Devices";
import Epg from "components/Icons/Epg";
import Favorites from "components/Icons/Favorites";
// Icons
import Home from "components/Icons/Home";
import Library from "components/Icons/Library";
import Live from "components/Icons/Live";
import OnDemand from "components/Icons/OnDemand";
import Privacy from "components/Icons/Privacy";
import Profiles from "components/Icons/Profiles";
import Settings from "components/Icons/Settings";
import Subscription from "components/Icons/Subscription";
import Support from "components/Icons/Support";
import consts from "consts/consts";
import App from "containers/App";
import PageAsset from "containers/PageAsset";
import PageAssetEPG from "containers/PageAssetEPG";
import PageAssetTVShow from "containers/PageAssetTVShow";
import PageCatchUp from "containers/PageCatchUp";
import PageCatchUpChannel from "containers/PageCatchUpChannel";
import PageCatchUpDetail from "containers/PageCatchUpDetail";
import PageCGU from "containers/PageCGU";
import PageConfimAccount from "containers/PageConfimAccount";
import PageDevices from "containers/PageDevices";
import PageEditProfile from "containers/PageEditProfile";
import PageEPG from "containers/PageEPG";
import PageFAQ from "containers/PageFAQ";
import PageFavorites from "containers/PageFavorites";
import PageHistory from "containers/PageHistory";
import PageHome from "containers/PageHome";
import PageLinkDevice from "containers/PageLinkDevice";
import PageLive from "containers/PageLive";
import PageLogout from "containers/PageLogout";
import PageManageProfile from "containers/PageManageProfile";
import PageMyAccount from "containers/PageMyAccount";
import PageMyLibrary from "containers/PageMyLibrary";
import PageNotFound from "containers/PageNotFound";
import PagePlayer from "containers/PagePlayer";
import PagePlayerLive from "containers/PagePlayerLive";
import PagePlayerTrailer from "containers/PagePlayerTrailer";
import PagePrivacy from "containers/PagePrivacy";
import PageResetPassword from "containers/PageResetPassword";
import PageSearch from "containers/PageSearch";
import PageSettings from "containers/PageSettings";
import PageSignIn from "containers/PageSignIn";
import PageSignUp from "containers/PageSignUp";
import PageSubscription from "containers/PageSubscription";
import PageAssetSuggest from "containers/PageSuggest";
import PageVOD from "containers/PageVOD";
import PageVODDetail from "containers/PageVODDetail";
import React from "react";
const menu = window.menuConfig;

const menuItems = [...menu.app, ...menu.account];

function getTitleFromMenu(key, defaultValue = "") {
  const menuEntry = menuItems.find((item) => item.key === key);
  if (menuEntry && menuEntry.title) {
    return menuEntry.title;
  }
  return defaultValue;
}

const routes = [
  {
    component: App,
    routes: [
      // Application routes
      {
        key: "home",
        icon: <Home />,
        path: consts.routes.home.url,
        exact: true,
        component: PageHome,
        title: getTitleFromMenu("home", consts.routes.home.title),
      },
      {
        key: "live",
        icon: <Live />,
        path: consts.routes.live.url,
        exact: true,
        component: PageLive,
        title: getTitleFromMenu("live", consts.routes.live.title),
      },
      {
        key: "catchup",
        icon: <CatchUp />,
        path: consts.routes.catchup.url,
        exact: true,
        component: PageCatchUp,
        title: getTitleFromMenu("catchup", consts.routes.catchup.title),
      },
      {
        key: "epg",
        icon: <Epg />,
        path: consts.routes.epg.url,
        exact: true,
        component: PageEPG,
        title: getTitleFromMenu("epg", consts.routes.epg.title),
      },
      {
        key: "vod",
        icon: <OnDemand />,
        path: consts.routes.vod.url,
        exact: true,
        component: PageVOD,
        title: getTitleFromMenu("vod", consts.routes.vod.title),
      },
      {
        path: consts.routes.vod.detailUrl,
        exact: true,
        component: PageVODDetail,
        title: consts.routes.vod.title || "",
      },
      {
        path: consts.routes.movieAsset.url,
        exact: true,
        component: PageAsset,
        title: consts.routes.movieAsset.title || "",
      },
      {
        path: consts.routes.suggest.url,
        exact: true,
        component: PageAssetSuggest,
        title: consts.routes.suggest.title,
      },
      {
        path: consts.routes.tvshowAsset.url,
        exact: true,
        component: PageAssetTVShow,
        title: consts.routes.tvshowAsset.title || "",
      },
      {
        path: consts.routes.epgAsset.url,
        exact: true,
        component: PageAssetEPG,
        title: consts.routes.epgAsset.title || "",
      },
      {
        path: consts.routes.catchupchannel.url,
        exact: true,
        component: PageCatchUpChannel,
        title: consts.routes.catchupchannel.title || "",
      },
      {
        path: consts.routes.catchupdetail.url,
        exact: true,
        component: PageCatchUpDetail,
        title: consts.routes.catchupdetail.title || "",
      },
      {
        path: consts.routes.live.url,
        exact: true,
        component: PageLive,
        title: consts.routes.live.title || "",
      },
      {
        path: consts.routes.player.url,
        exact: true,
        component: PagePlayer,
        title: consts.routes.player.title || "",
      },
      {
        path: consts.routes.playerlive.url,
        exact: true,
        component: PagePlayerLive,
        title: consts.routes.playerlive.title || "",
      },
      {
        path: consts.routes.playerTrailer.url,
        exact: true,
        component: PagePlayerTrailer,
        title: consts.routes.playerTrailer.title || "",
      },
      {
        key: "settings",
        path: consts.routes.settings.url,
        exact: true,
        component: PageSettings,
        title: consts.routes.settings.title,
        icon: <Settings />,
      },
      {
        key: "privacy",
        path: consts.routes.privacy.url,
        exact: true,
        component: PagePrivacy,
        title: consts.routes.privacy.title,
        icon: <Privacy />,
      },
      {
        key: "cgu",
        path: consts.routes.cgu.url,
        exact: true,
        component: PageCGU,
        title: getTitleFromMenu("cgu", consts.routes.cgu.title),
        icon: <About />,
      },
      // Account routes
      {
        key: "account",
        path: consts.routes.account.url,
        exact: true,
        component: PageMyAccount,
        title: getTitleFromMenu("account", consts.routes.account.title),
        icon: <Account />,
      },
      {
        key: "subscription",
        path: consts.routes.subscription.url,
        exact: true,
        component: PageSubscription,
        title: getTitleFromMenu(
          "subscription",
          consts.routes.subscription.title
        ),
        icon: <Subscription />,
      },
      {
        key: "devices",
        path: consts.routes.devices.url,
        exact: true,
        component: PageDevices,
        title: getTitleFromMenu("devices", consts.routes.devices.title),
        icon: <Devices />,
      },
      {
        key: "faq",
        path: consts.routes.faq.url,
        exact: true,
        component: PageFAQ,
        title: getTitleFromMenu("faq", consts.routes.faq.title),
        icon: <Support />,
      },
      {
        key: "favorites",
        path: consts.routes.favorites.url,
        exact: true,
        component: PageFavorites,
        title: getTitleFromMenu("favorites", consts.routes.favorites.title),
        icon: <Favorites />,
      },
      {
        key: "history",
        path: consts.routes.history.url,
        exact: true,
        component: PageHistory,
        title: getTitleFromMenu("history", consts.routes.history.title),
        icon: <ContinueWatching />,
      },
      {
        key: "profiles",
        path: consts.routes.profiles.url,
        exact: true,
        component: PageManageProfile,
        title: getTitleFromMenu("profiles", consts.routes.profiles.title),
        icon: <Profiles />,
      },
      {
        path: consts.routes.profile.url,
        exact: true,
        component: PageEditProfile,
        title: getTitleFromMenu("profile", consts.routes.profile.title),
      },
      {
        path: consts.routes.search.url,
        exact: true,
        component: PageSearch,
        title: consts.routes.search.title || "",
      },
      {
        path: consts.routes.subscription.url,
        exact: true,
        component: PageSubscription,
        title: consts.routes.subscription.title || "",
      },
      {
        path: consts.routes.resetpassword.url,
        exact: true,
        component: PageResetPassword,
        title: consts.routes.resetpassword.title || "",
      },
      {
        path: consts.routes.signup.url,
        exact: true,
        component: PageSignUp,
        title: consts.routes.signup.title || "",
      },
      {
        path: consts.routes.signIn.url,
        exact: true,
        component: PageSignIn,
        title: consts.routes.signIn.title || "",
      },
      {
        path: consts.routes.confirmAccount.url,
        exact: true,
        component: PageConfimAccount,
        title: consts.routes.confirmAccount.title || "",
      },
      {
        key: "library",
        path: consts.routes.library.url,
        exact: true,
        component: PageMyLibrary,
        title: getTitleFromMenu("library", consts.routes.library.title),
        icon: <Library />,
      },
      {
        path: consts.routes.logout.url,
        component: PageLogout,
        title: consts.routes.logout.title || "",
      },
      {
        path: consts.routes.link.url,
        component: PageLinkDevice,
        title: consts.routes.link.title || "",
      },
      {
        path: consts.routes.notfound.url,
        component: PageNotFound,
        title: consts.routes.notfound.title || "",
      },
    ],
  },
];

export default routes;
