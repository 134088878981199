import React, { Component } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";

export default class PageNotFound extends Component {
  render() {
    return (
      <div className={style.root}>
        <div>
          <h1>
            <Trans>Ooops, I did not find what you want</Trans>
          </h1>
          <h2>404</h2>
        </div>
      </div>
    );
  }
}
