import InlineSVG from "inline-svg-react";
import React from "react";

const StarFull = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/StarFull.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/StarFull.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path d="M8.586 7.785L12.047.87l3.383 6.953 7.646 1.154-5.566 5.366 1.265 7.63-6.824-3.636-6.865 3.56 1.35-7.614-5.51-5.428 7.66-1.07z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default StarFull;
