import classnames from "classnames";
import CloseIcon from "components/Icons/Close";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Link } from "react-router-dom";

import style from "./style.module.css";

class Toast extends Component {
  render() {
    const { className, text, duration, url, onDismissClick, stayOpen } =
      this.props;

    const finalClassName = classnames(style.toast, {
      [style.primaryStyle]: className === "primary",
      [style.errorStyle]: className === "error",
    });

    if (duration) {
      setTimeout(function () {
        onDismissClick();
      }, duration);
    }

    return (
      <div className={finalClassName}>
        {!stayOpen && (
          <CloseIcon className={style.dismiss} onClick={onDismissClick} />
        )}
        <p className={style.content}>
          {!url && <span>{text}</span>}
          {url && (
            <Link to={url} className={style.link}>
              {text}
            </Link>
          )}
        </p>
      </div>
    );
  }
}

Toast.propTypes = {
  className: PropTypes.string,
  onDismissClick: PropTypes.func.isRequired,
  text: PropTypes.any.isRequired,
  url: PropTypes.string,
  duration: PropTypes.number,
  stayOpen: PropTypes.number,
};

Toast.defaultProps = {
  className: "primary",
};

export default Toast;
