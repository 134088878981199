import InlineSVG from "inline-svg-react";
import React from "react";

const PadlockLock = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/PadlockLock.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/PadlockLock.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path
            d="M18 10.286h-.857V7.714a5.143 5.143 0 00-10.286 0v2.572H6a.857.857 0 00-.857.857v8.571c0 .474.384.857.857.857h12a.857.857 0 00.857-.857v-8.571a.857.857 0 00-.857-.857zM8.4 7.714a3.6 3.6 0 017.2 0v2.572H8.4V7.714zm4.457 8.572a.857.857 0 01-1.714 0v-2.572a.857.857 0 011.714 0v2.572z"
            fill="#1D1E1C"
            fillRule="evenodd"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default PadlockLock;
