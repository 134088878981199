import { setSuspensionShow } from "actions/ui";
import classnames from "classnames";
import FavoritesFilled from "components/Icons/FavoritesFilled";
import FreeToAir from "components/Icons/FreeToAir";
import PadlockLock from "components/Icons/PadlockLock";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import LogoVod from "components/LogoVod";
import ProgressBar from "components/ProgressBar";
import ThumbnailInfos from "components/ThumbnailInfos";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class ThumbnailSquare extends Component {
  state = {
    hover: false,
    itemMetadata: null,
  };

  subscriptionModal(asset) {
    const { accountStatus, dispatch } = this.props;

    if (accountStatus && accountStatus.toLowerCase() === "suspended") {
      dispatch(setSuspensionShow());
    } else if (this.props.subscriptionModal) {
      this.props.subscriptionModal(asset);
    }
  }
  async componentDidMount() {
    if (consts.displayVodData) {
      const { item } = this.props;
      let metadata;
      if (item instanceof TucanoModels.Asset && item.getVodData()) {
        metadata = item.getVodData();
      }
      await this.setState({ itemMetadata: metadata });
    }
  }
  render() {
    const {
      item,
      rootClassName,
      size,
      large,
      onlyPlay,
      linkToPlayer,
      isConnected,
      fta,
      isSearch,
      showFavorite,
      accountStatus,
    } = this.props;

    const { hover } = this.state;
    const itemType = item.getType();

    const isAccountSuspended =
      accountStatus && accountStatus.toLowerCase() === "suspended";
    /*let catalog;
    if (item instanceof TucanoModels.TVShow) {
      catalog = item.getCatalogue();
    }
    if (item.catalogs) {
      catalog = item.catalogs;
    }*/
    let url, type;

    if (itemType == "EPGBroadcast") {
      type = "epgEvent";
      // if (item.isLive()) {
      //   url = consts.routes.playerlive.url.replace(":channelId", item.getChannelId());
      // } else {
      url = consts.routes.epgAsset.url.replace(":id", item.getEpgId());
      // }
    }
    if (item instanceof TucanoModels.Asset) {
      if (item) {
        url = consts.routes.movieAsset.url.replace(":id", item.getId());
      }
      if (item.isCategory) {
        url = consts.routes.vod.detailUrl.replace(":id", item.getId());
      }
      if (!item.isCatchup()) {
        if (item.isTVShow() || item.isAssetTVShowEpisode === true) {
          url = consts.routes.tvshowAsset.url.replace(
            ":id",
            item.getParentId()
          );
        }
        if (item.isCategory) {
          url = consts.routes.vod.detailUrl.replace(":id", item.getId());
        }
      } else {
        url = consts.routes.catchupchannel.url.replace(":slug", item.getSlug());
      }
      if (item.getType() === "channel_logo") {
        url = isAccountSuspended
          ? "#"
          : consts.routes.playerlive.url.replace(":channelId", item.getId());
      }

      if (itemType === "link") {
        url = getRealSlug(item.getUrl());
      }
    }

    if (item instanceof TucanoModels.Channel) {
      if (linkToPlayer === true) {
        url = isAccountSuspended
          ? "#"
          : consts.routes.playerlive.url.replace(":channelId", item.getId());
      } else {
        url = consts.routes.catchupchannel.url.replace(":slug", item.getSlug());
      }
    }
    // apply border radius && shadow
    const borderRadiusStyle =
      (consts.thumbnailBorderRadius &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[0][consts.thumbnailBorderRadius.toLowerCase()]
        ]) ||
      "";
    const shadowStyle =
      (consts.thumbnailShadow &&
        consts.thumbnailStyle.length > 0 &&
        style[
          consts.thumbnailStyle[1][consts.thumbnailShadow.toLowerCase()]
        ]) ||
      "";

    return (
      <Link
        target={url.startsWith("http") ? "_blank" : "_self"}
        to={isSearch || url === "#" ? "#" : { pathname: url }}
        className={classnames(rootClassName, style.container, {
          [style.large]: large,
          [borderRadiusStyle]: true,
          [shadowStyle]: true,
          [style.container_hover]: !isSearch && hover,
        })}
        onMouseEnter={() =>
          this.setState({ hover: this.props.showInfosOverlay })
        }
        onMouseLeave={() => this.setState({ hover: false })}
        style={
          !isSearch
            ? {
                minWidth: large ? size.squareLarge.width : size.square.width,
                minHeight: large ? size.squareLarge.height : size.square.height,
              }
            : {}
        }
        onClick={this.props.onClick ? this.props.onClick : () => {}}
      >
        <div
          className={classnames({
            [style.contentSearch]: isSearch,
            [style.content]: !isSearch,
            [style.unsetObjectFit]: item instanceof TucanoModels.Asset,
          })}
        >
          <Image
            src={item.getImage(consts.image_hostname, {
              width: 171,
              height: 171,
              languageId: getFromLocal("languageId"),
            })}
            width={large ? size.squareLarge.width : size.square.width}
            height={large ? size.squareLarge.height : size.square.height}
            ratio={ImageRatio.SQUARE}
            placeholder={PlaceholderType.PRIMARY}
          />
          {type === "epgEvent" && (
            <Image
              rootClassName={style.channel}
              height={40}
              width={40}
              effect={"opacity"}
              src={`${consts.image_hostname}${routes.imgdata}?type=${
                consts.imageTypes.tvChannel
              }&objectId=${item.getChannelId()}&format_w=80&format_h=80&languageId=${getFromLocal(
                "languageId"
              )}`}
              placeholder={PlaceholderType.TRANSPARENT}
            />
          )}
          {type !== "epgEvent" &&
            !!item.seekTime &&
            (large ? (
              <ProgressBar
                position={item.getProgress()}
                rootClassName={style.progressBarForXL}
              />
            ) : (
              <ProgressBar
                position={item.getProgress()}
                rootClassName={style.progressBar}
              />
            ))}
          {type === "epgEvent" &&
            item.isLive() &&
            item.getEpgProgress() &&
            (large ? (
              <ProgressBar
                position={item.getEpgProgress()}
                rootClassName={style.progressBarForXL}
              />
            ) : (
              <ProgressBar
                position={item.getEpgProgress()}
                rootClassName={style.progressBar}
              />
            ))}
          {item && item.flyImage && (
            <div className={classnames(style.VODLogo)}>
              <LogoVod
                className={classnames(style.vodData)}
                type={item.flyImage}
                size={40}
              />
            </div>
          )}
          {fta && !isConnected && (
            <div className={style.ftaIconContainer}>
              <FreeToAir className={style.fta} />
            </div>
          )}
          {((isSearch &&
            consts.padlocks === true &&
            isConnected === true &&
            !item.userHasAccess) ||
            isAccountSuspended) && (
            <div className={style.padlockContainer}>
              <div className={style.padlockIconContainer}>
                <PadlockLock className={style.padlockLockIcon} />
              </div>
            </div>
          )}
          {showFavorite && isSearch && (
            <FavoritesFilled className={style.favorite} />
          )}
        </div>
        {!isSearch && (
          <ThumbnailInfos
            show={hover}
            model={item}
            onlyPlay={onlyPlay}
            subscriptionModal={this.subscriptionModal.bind(this)}
          />
        )}
      </Link>
    );
  }
}

ThumbnailSquare.defaultProps = {
  item: {},
  rootClassName: "",
  size: {},
  grid: false,
  showInfosOverlay: true,
  onlyPlay: false,
};

ThumbnailSquare.propTypes = {
  item: PropTypes.object,
  rootClassName: PropTypes.string,
  size: PropTypes.object,
  grid: PropTypes.bool,
  showInfosOverlay: PropTypes.bool,
  onlyPlay: PropTypes.bool,
};

export default compose(
  connect((state, _props) => {
    return {
      accountStatus: state.account.user?.data?.status,
    };
  })
)(ThumbnailSquare);
