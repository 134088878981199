export default {
  type: {
    CHANNEL: 1,
    ASSET: 4,
    LABEL: 6,
    LINK: 7,
    CATEGORY: 8,
    MOVIE: "MOVIE",
    EPISODE: "EPISODE",
    SERIES: "SERIES",
    EPGBROADCAST: "EPGBroadcast",
  },
};

export const CatalogType = {
  CATCHUP: 1,
  VOD: 2,
};
export const ASSET_TYPE = {
  VOD: "VOD",
  VOD_CATEGORY: "VOD_CATEGORY",
};
// type = VOD or VOD_CATEGORY
export function getImageType(designMode, type = "VOD") {
  if (designMode === 69) {
    if (type === ASSET_TYPE.VOD) {
      return ImageType.VOD_THUMBNAIL;
    } else if (type === ASSET_TYPE.VOD_CATEGORY) {
      return ImageType.VOD_CATEGORY_THUMBNAIL;
    }
  } else if (designMode === 169) {
    if (type === ASSET_TYPE.VOD) {
      return ImageType.VOD_BACKGROUND;
    } else if (type === ASSET_TYPE.VOD_CATEGORY) {
      return ImageType.VOD_CATEGORY_BACKGROUND;
    }
  }
}

export const ImageType = {
  // TODO delete these old code
  // VOD_THUMBNAIL: 510,
  // VOD_BACKGROUND: 512,
  // VOD_CATEGORY_THUMBNAIL: 540,
  // VOD_CATEGORY_BACKGROUND: 550,
  VOD_THUMBNAIL: "asset_vod-poster",
  VOD_BACKGROUND: "asset_vod-background",
  VOD_CATEGORY_THUMBNAIL: "category_vod-poster",
  VOD_CATEGORY_BACKGROUND: "category_vod-background",
  CATCHUP_THUMB: 511,
  CATCHUP_BACKGROUND: 511,
};
