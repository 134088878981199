import InlineSVG from "inline-svg-react";
import React from "react";

const Switch = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Switch.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Switch.svg");
    } catch (err) {
      return (
        <svg width={18} height={18} {...props}>
          <g fillRule="nonzero" fill="none">
            <circle stroke="#FFF" fill="#1D1E1C" cx={9} cy={9} r={8.5} />
            <path
              fill="#FFF"
              d="M4.5 7.179h5.464V5.25L13.5 8.464h-9zM13.5 11.036H8.036v1.928L4.5 9.75h9z"
            />
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Switch;
