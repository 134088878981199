import InlineSVG from "inline-svg-react";
import React from "react";

const SvgPlayerSubtitleAudioGbl = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/SvgPlayerSubtitleAudioGbl.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/SvgPlayerSubtitleAudioGbl.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M20.91 3.273H3.09c-1.405 0-2.545.976-2.545 2.182v13.09c0 1.206 1.14 2.182 2.546 2.182h17.818c1.406 0 2.546-.976 2.546-2.182V5.455c0-1.206-1.14-2.182-2.546-2.182zm-10 7.636H9.272v-.545H7.09v3.272h2.182v-.545h1.636v1.09c0 .6-.485 1.092-1.09 1.092H6.544a1.09 1.09 0 01-1.09-1.091V9.818c0-.6.485-1.09 1.09-1.09h3.273c.606 0 1.091.49 1.091 1.09v1.091zm7.635 0H16.91v-.545h-2.182v3.272h2.182v-.545h1.636v1.09c0 .6-.485 1.092-1.09 1.092h-3.273a1.09 1.09 0 01-1.091-1.091V9.818c0-.6.485-1.09 1.09-1.09h3.274c.605 0 1.09.49 1.09 1.09v1.091z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default SvgPlayerSubtitleAudioGbl;
