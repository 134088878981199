import consts from "consts/consts";
import moment from "moment";
export function cloneObject(obj) {
  var clone = {};
  for (var i in obj) {
    if (obj[i] != null && typeof obj[i] == "object") clone[i] = cloneObject(obj[i]);
    else clone[i] = obj[i];
  }
  return clone;
}

export function findObjectInArrayBy(arr, property, value) {
  return typeof arr === "object" && arr.find((o) => o[property]?.toLowerCase() === value.toLowerCase());
}

export function convertTimestampToDate(date, lang) {
  return moment(date * 1000)
    .locale(lang == "ar" ? "ar-MA" : lang)
    .format(consts.catchupDateFormat);
}
