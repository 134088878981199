import InlineSVG from "inline-svg-react";
import React from "react";

const Play = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Play.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Play.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 60 60" {...props}>
          <circle cx={30} cy={30} r={28} />
          <path d="m39.210999,30.775998l-13.764,8.882a1,1 0 0 1 -1.447,-0.894l0,-17.764a1,1 0 0 1 1.447,-0.894l13.764,8.882a1,1 0 0 1 0,1.788z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Play;
