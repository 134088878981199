import InlineSVG from "inline-svg-react";
import React from "react";

const Logout = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Logout.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Logout.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M20.2332963,11.1778783 L18.4805917,9.42517356 L19.5412518,8.36451339 L23.104796,11.9280575 L19.5402117,15.491518 L18.4797187,14.4306907 L20.2330839,12.6778783 L10.6348522,12.6778783 L10.6348522,11.1778783 L20.2332963,11.1778783 Z M17.4416522,16.710487 L19.722,16.710487 C18.028,19.8019652 14.7452174,21.8996174 10.9707826,21.8996174 C5.46504348,21.8996174 1,17.4355304 1,11.9278783 C1,6.42118261 5.46504348,1.95613913 10.9707826,1.95613913 C14.7452174,1.95613913 18.0270435,4.0537913 19.7210435,7.14526957 L17.4416522,7.14526957 C15.9724348,5.1624 13.6232174,3.86918261 10.9707826,3.86918261 C6.52773913,3.86918261 2.91304348,7.48387826 2.91304348,11.9278783 C2.91304348,16.3718783 6.52773913,19.9865739 10.9707826,19.9865739 C13.6232174,19.9865739 15.9724348,18.6924 17.4416522,16.710487 Z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Logout;
