import InlineSVG from "inline-svg-react";
import React from "react";

const AddMore = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/AddMore.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/AddMore.svg");
    } catch (err) {
      return (
        <svg width={40} height={40} viewBox="0 0 40 40" {...props}>
          <path d="M17.5 17.5V0h5v17.5H40v5H22.5V40h-5V22.5H0v-5h17.5z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default AddMore;
