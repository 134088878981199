import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Grid extends Component {
  render() {
    const { children, rootClassName } = this.props;
    return (
      <div className={classnames(style.itemsContainer, rootClassName)}>
        {children}
      </div>
    );
  }
}

Grid.defaultProps = {
  children: [],
  rootClassName: "",
};

Grid.propTypes = {
  children: PropTypes.any,
  rootClassName: PropTypes.string,
};
