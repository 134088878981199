import { generatePosterImageUrl } from "../helpers/imageHelper";
export default class Avatar {
  constructor(item) {
    this.id = item.id;
    this.name = item.name;
    this.imageId = item.imageId;
  }

  getId() {
    return this.id;
  }

  getImageId() {
    return this.imageId;
  }
  getName() {
    return this.name;
  }
  getPosterImage(domain = "/", options) {
    return `${domain}${generatePosterImageUrl(this, options)}`;
  }
}
