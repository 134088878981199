// import style from "./style.module.css";
import Dropdown from "components/Dropdown";
import IconLang from "components/Icons/Language";
import consts from "consts/consts";
import { getFromLocal, saveInLocal } from "helpers/localStorage";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

class SwitchLanguage extends Component {
  state = {
    selfClosing: false,
  };
  handleSwitchLanguage = (item) => {
    const { activeProfile } = this.props;
    if (activeProfile) {
      const userInfos = {
        ...activeProfile,
        language: item.value,
      };

      this.props
        .dispatch(TucanoActions.createOrUpdateProfile(userInfos))
        .then(async () => {
          this.props.i18n.changeLanguage(consts.languageIdMapping[item.value]);
          saveInLocal("languageId", item.value);
        })
        .then(() => {
          window.location.reload();
        });
    } else {
      this.props.i18n.changeLanguage(consts.languageIdMapping[item.value]);
      saveInLocal("languageId", item.value);
      window.location.reload();
    }
  };

  render() {
    const { t, shouldAddMargin, menuOpen, handleShowSubDropdownLang } =
      this.props;

    const rawlanguage = consts.enum.languageId;
    const languages = rawlanguage.map((lang) => {
      return { label: t(lang.label), value: lang.value };
    });
    const selectedLang = Object.values(languages).filter((item) => {
      return item.value === getFromLocal("languageId");
    });
    return (
      <>
        {languages && languages.length > 1 && (
          <Dropdown
            selfClosing={true}
            iconOnClick={<IconLang />}
            label={
              selectedLang[0]
                ? selectedLang[0].label
                : consts.enum.languageId[0].label
            }
            dropdownIcon={false}
            removeBackgroundColor={true}
            addMargin={shouldAddMargin}
            handleClick={this.handleSwitchLanguage}
            data={languages}
            menuOpen={menuOpen}
            handleShowSubDropdownLang={handleShowSubDropdownLang}
          />
        )}
      </>
    );
  }
}
export default compose(
  connect((state) => {
    return {
      activeProfile: TucanoSelectors.getActiveProfile(state),
      menuOpen: state.ui.menuOpen,
    };
  }),
  withTranslation()
)(SwitchLanguage);
