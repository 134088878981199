import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";

export function search(languageId, term, searchField = "fts") {
  const params = {
    languageId,
  };
  params[searchField] = term;
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "GET",
          url: routes.search,
          params: params,
        },
        onStart: () => {
          return {
            type: actionTypes.GET_SEARCH_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.GET_SEARCH_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.GET_SEARCH_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function resetSearchResults() {
  return {
    type: actionTypes.RESET_SEARCH_RESULTS,
  };
}
