import InlineSVG from "inline-svg-react";
import React from "react";

const Filter = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Filter.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Filter.svg");
    } catch (err) {
      return (
        <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
          <path
            onClick={props.onClick}
            fill="#FFF"
            d="M10.571 15.071c0 1.53-1.068 2.81-2.5 3.135v2.937a.714.714 0 01-1.428 0v-2.937a3.215 3.215 0 010-6.269V2.57a.714.714 0 011.428 0v9.366a3.215 3.215 0 012.5 3.134zm-1.428 0a1.786 1.786 0 10-3.571.001 1.786 1.786 0 003.571-.001zm10.714-6.428a3.215 3.215 0 01-2.506 3.136l.006.078v9.286a.714.714 0 01-1.428 0v-9.286c0-.027.001-.053.004-.08a3.215 3.215 0 01-.004-6.269V2.571a.714.714 0 011.428 0v2.937a3.215 3.215 0 012.5 3.135zm-1.428 0a1.786 1.786 0 10-3.573.001 1.786 1.786 0 003.573-.001z"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Filter;
