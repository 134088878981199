import InlineSVG from "inline-svg-react";
import React from "react";

const PlayLock = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/PlayLock.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/PlayLock.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 60 60" {...props}>
          <circle cx={30} cy={30} r={28} />
          <path d="M40 27.143h-1.429v-4.286a8.571 8.571 0 00-17.142 0v4.286H20c-.789 0-1.429.64-1.429 1.428v14.286c0 .79.64 1.429 1.429 1.429h20c.789 0 1.429-.64 1.429-1.429V28.571c0-.789-.64-1.428-1.429-1.428zm-16-4.286a6 6 0 1112 0v4.286H24v-4.286zm7.429 14.286a1.429 1.429 0 01-2.857 0v-4.286a1.429 1.429 0 012.857 0v4.286z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default PlayLock;
