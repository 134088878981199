import InlineSVG from "inline-svg-react";
import React from "react";

const FreeToAirCircle = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/FreeToAirCircle.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/FreeToAirCircle.svg");
    } catch (err) {
      return (
        <svg width="40px" height="40px" viewBox="0 0 40 40">
          <g
            id="Design-system"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd"
          >
            <g
              id="DesignSytem---Thumbnails"
              transform="translate(-609.000000, -2366.000000)"
            >
              <g id="#DesignSytem-/-Rules-/-Locked-&amp;-Tagged-Thumbnails">
                <g
                  id="Molecule-/-Indicator-/-FTA-/-FTA_item-Round-Copy"
                  transform="translate(609.000000, 2366.000000)"
                >
                  <circle
                    id="Oval"
                    fillRule="nonzero"
                    {...props}
                    cx="20"
                    cy="20"
                    r="20"
                  />
                  <text
                    id="FREE-!"
                    fontFamily='beemix-font, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;'
                    fontSize="11"
                    fontWeight="bold"
                    fill="#1D1E1C"
                  >
                    <tspan x="4" y="25">
                      FREE !
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default FreeToAirCircle;
