import InlineSVG from "inline-svg-react";
import React from "react";

const Close = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Close.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Close.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <circle fill="none" r={12} cx={12} cy={12} />
          <path d="M10.94 12L4.574 5.636a.75.75 0 1 1 1.061-1.06L12 10.938l6.364-6.364a.75.75 0 0 1 1.06 1.061L13.062 12l6.364 6.364a.75.75 0 0 1-1.061 1.06L12 13.062l-6.364 6.364a.75.75 0 0 1-1.06-1.061L10.938 12z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Close;
