export default class User {
  constructor(accountInfo) {
    //console.log("User input", accountInfo);
    // AccountInfo
    this.title = accountInfo.title;
    this.login = accountInfo.login;
    this.email = accountInfo.email;
    this.phoneNumber = accountInfo.phoneNumber;
    this.firstname = accountInfo.firstName;
    this.lastname = accountInfo.lastName;
    this.birthdate = accountInfo.dateofbirth;
    this.languageId = accountInfo.languageId;
    this.additionalInformation = accountInfo.additionalInformation;
    this.paymentType = accountInfo.paymentType;
    this.allowNews = accountInfo.allowNews;

    // Address
    const address = accountInfo.addresses[0];
    if (address) {
      this.number = address.addressNumber;
      this.street = address.addressStreet;
      this.box = address.addressBox;
      this.floor = address.addressFloor;
      this.secondStreet = address.addressSecondStreet;
      this.thirdStreet = address.addressThirdStreet;
      this.city = address.addressCity;
      this.zipcode = address.addressZip;
      this.state = address.addressState;
      this.country = address.addressCountry;
    }
  }

  getTitle() {
    return this.title;
  }

  getLogin() {
    return this.login;
  }

  getEmail() {
    return this.email;
  }

  getPhoneNumber() {
    return this.phoneNumber;
  }

  getLastname() {
    return this.lastname;
  }

  getFirstname() {
    return this.firstname;
  }

  getLanguageId() {
    return this.languageId;
  }
  getBirthdate() {
    return this.birthdate;
  }
  getPaymentType() {
    return this.paymentType;
  }
  getAdditionalInformation() {
    return JSON.stringify(this.additionalInformation);
  }
  getNumber() {
    return this.number === "undefined" ? undefined : this.number;
  }
  getStreet() {
    return this.street === "undefined" ? undefined : this.street;
  }
  getBox() {
    return this.box === "undefined" ? undefined : this.box;
  }
  getFloor() {
    return this.floor === "undefined" ? undefined : this.floor;
  }
  getSecondStreet() {
    return this.secondStreet === "undefined" ? undefined : this.secondStreet;
  }
  getThirdStreet() {
    return this.thirdStreet === "undefined" ? undefined : this.thirdStreet;
  }
  getCity() {
    return this.city === "undefined" ? undefined : this.city;
  }
  getZipcode() {
    return this.zipcode === "undefined" ? undefined : this.zipcode;
  }
  getState() {
    return this.state === "undefined" ? undefined : this.state;
  }
  getCountry() {
    return this.country === "unedfined" ? undefined : this.country;
  }
  getAllowNews() {
    return this.allowNews === "undefined" ? undefined : this.allowNews;
  }
}
