import InlineSVG from "inline-svg-react";
import React from "react";

const CatchUp = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/CatchUp.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/CatchUp.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path
            d="M4.96599724,3.54965558 C6.87189558,1.95878694 9.32274944,1.00036667 11.9996333,1.00036667 C18.0753,1.00036667 22.9996333,5.9247 22.9996333,12.0003667 C22.9996333,18.0751167 18.0753,23.0003667 11.9996333,23.0003667 C5.92488333,23.0003667 0.999633333,18.0751167 0.999633333,12.0003667 C0.999633333,11.4888667 1.0473,10.9911167 1.11421667,10.4988667 L3.04013333,10.0772 C2.9063,10.6977833 2.83296667,11.3403667 2.83296667,12.0003667 C2.83296667,17.0548667 6.94513333,21.1670333 11.9996333,21.1670333 C17.05505,21.1670333 21.1663,17.0548667 21.1663,12.0003667 C21.1663,6.94495 17.05505,2.8337 11.9996333,2.8337 C9.83238826,2.8337 7.84148518,3.59247004 6.27290716,4.85656549 L8.01735833,6.60101667 L1.68685833,7.98701667 L3.073775,1.65743333 L4.96599724,3.54965558 Z"
            id="path-1"
          />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};

export default CatchUp;
