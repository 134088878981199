import { secondsToHoursAndMinutes } from "helpers/time";

export default class Quota {
  constructor(item = {}) {
    this.available = 0;
    this.consumed = 0;
    if (item && item !== undefined) {
      if (item.available) {
        this.available = item.available;
      }
      if (item.consumed) {
        this.consumed = item.consumed;
      }
    }
  }

  getRecordQuota() {
    return this.available;
  }

  getRecordQuotaInhoursAndMinutes() {
    return secondsToHoursAndMinutes(this.getRecordQuota());
  }

  getConsumed() {
    return this.consumed;
  }

  getConsumedInHoursAndMinutes() {
    return secondsToHoursAndMinutes(this.getConsumed());
  }

  getAvailableInHoursAndMinutes() {
    return secondsToHoursAndMinutes(this.getAvailable());
  }

  getAvailable() {
    return this.available - this.consumed;
  }
}
