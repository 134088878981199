import Image, { ImageRatio, PlaceholderType } from "components/Image";
import { LinkDuo } from "components/LinkDuo";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { getRealSlug } from "helpers/url";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoModels } from "web-api/main";

import style from "./style.module.css";

class EditorialBanner extends Component {
  render() {
    const { asset, thumbnailSize, liveEPG, direction } = this.props;
    let type;
    if (
      asset.getType() === "asset_vod-background" ||
      asset.getType() === "category_vod-background" ||
      asset.getType() === consts.imageTypes.movie_episode_Poster ||
      asset.getType() === consts.imageTypes.movie_episode_cover
    ) {
      type = "vod";
    }
    if (
      asset.getType() === "asset_vod" ||
      asset.getType() === consts.imageTypes.catchupCover
    ) {
      type = "catchup";
    }
    if (
      asset.getType() === "channel_logo-vertical" ||
      asset.getType() === "channel_logo"
    ) {
      type = "live";
    }
    if (asset.getType() === "link") {
      type = "link";
    }
    if (asset.getType() === "EPGBroadcast") {
      type = "epgEvent";
    }
    let assetLiveId, assetLive;
    if (liveEPG) {
      assetLive = liveEPG.find((item) => {
        if (item.channelId === asset.getId()) {
          return true;
        } else {
          return undefined;
        }
      });
    }
    if (assetLive) {
      assetLiveId = assetLive.getEpgId();
    }
    let url = consts.routes.movieAsset.url.replace(":id", asset.getId());

    if (type === "live") {
      url = consts.routes.playerlive.url.replace(":channelId", asset.getId());
    }

    if (
      asset instanceof TucanoModels.Asset &&
      asset.isCategory &&
      asset.isCategory === true
    ) {
      if (asset.isTVShow() === true || asset.isSerie === true) {
        if (asset.isAssetTVShowEpisode) {
          url = consts.routes.tvshowAsset.url.replace(
            ":id",
            asset.getParentId()
          );
        } else {
          url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
        }
      } else {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }
    if (asset.isCategory === false && asset.getParentId() !== undefined) {
      if (
        asset.isAssetTVShow === false &&
        asset.isAssetTVShowEpisode === true
      ) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getParentId());
      }
    }
    if (type === "epgEvent") {
      url = consts.routes.epgAsset.url.replace(":id", assetLiveId);
    }
    if (
      asset instanceof TucanoModels.Asset &&
      !asset.isCategory &&
      asset.isAssetTVShow === true
    ) {
      if (asset.isSerie === true) {
        url = consts.routes.tvshowAsset.url.replace(":id", asset.getId());
      } else {
        url = consts.routes.vod.detailUrl.replace(":id", asset.getId());
      }
    }

    if (type === "link") {
      url = getRealSlug(asset.getUrl());
    }
    return (
      <div className={style.root}>
        <div className={style.container}>
          {type !== "link" && (
            <Link to={url}>
              <Image
                src={asset.getImage(consts.image_hostname, {
                  width: 1024,
                  height: 256,
                  languageId: getFromLocal("languageId"),
                })}
                width={thumbnailSize.banner.width}
                height={thumbnailSize.banner.height}
                ratio={ImageRatio.BANNER}
                placeholder={PlaceholderType.PRIMARY}
              />
            </Link>
          )}
          {type === "link" && (
            <>
              {direction === "promo-right" && (
                <LinkDuo to={url} className={style.containerRight}>
                  <div className={style.editorialImg}>
                    <Image
                      src={asset.getImage(consts.image_hostname, {
                        width: 1920,
                        height: 1080,
                        languageId: getFromLocal("languageId"),
                      })}
                      ratio={ImageRatio.COVER}
                      placeholder={PlaceholderType.PRIMARY}
                    />
                  </div>
                  <div className={style.editorialText}>
                    <div className={style.editorialContainer}>
                      <div className={style.editorialTextCenter}>
                        {asset.getContent().description}
                      </div>
                    </div>
                  </div>
                </LinkDuo>
              )}
              {direction === "promo-left" && (
                <LinkDuo to={url} className={style.containerLeft}>
                  <div className={style.editorialText}>
                    <div className={style.editorialContainer}>
                      <div className={style.editorialTextCenter}>
                        {asset.getContent().description}
                      </div>
                    </div>
                  </div>
                  <div className={style.editorialImg}>
                    <Image
                      src={asset.getImage(consts.image_hostname, {
                        width: 1920,
                        height: 1080,
                        languageId: getFromLocal("languageId"),
                      })}
                      ratio={ImageRatio.COVER}
                      placeholder={PlaceholderType.PRIMARY}
                    />
                  </div>
                </LinkDuo>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

EditorialBanner.defaultProps = {
  asset: {},
  thumbnailSize: {},
};

EditorialBanner.propTypes = {
  asset: PropTypes.object,
  thumbnailSize: PropTypes.object,
};

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
    };
  })
)(EditorialBanner);
