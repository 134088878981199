import actionTypes from "../consts/actionTypes";
import routes from "../consts/routes";

export function hierarchy(languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.hierarchy,
          params: {
            languageId: languageId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.HIERARCHY_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.HIERARCHY_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.HIERARCHY_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function menuOrder(languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.menuOrder,
          params: {
            languageId: languageId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.MENUORDER_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.MENUORDER_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.MENUORDER_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getChannels(id, order, languageId = "eng",isConnectionStatusChanged) {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.listChannels,
          params: {
            languageId: languageId,
          },
        },
        onStart: () => {
          if(isConnectionStatusChanged){
            return {
              type: actionTypes.LISTCHANNELS_PENDING,
            };
          }
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.LISTCHANNELS_SUCCESS,
            position: order,
            payload,
          };
        },
        onError: (payload, meta) => {
          // Do something if the httpCode if >= 300
          resolve(meta);
          return {
            type: actionTypes.LISTCHANNELS_ERROR,
            meta,
          };
        },
      });
    });
  };
}

export function getFaq(languageId = "eng") {
  return (dispatch) => {
    return new Promise((resolve) => {
      dispatch({
        request: {
          method: "POST",
          url: routes.faqData,
          params: {
            languageId: languageId,
          },
        },
        onStart: () => {
          return {
            type: actionTypes.FAQ_PENDING,
          };
        },
        onSuccess: (payload, meta, dispatch) => {
          resolve(payload);
          return {
            type: actionTypes.FAQ_SUCCESS,
            payload,
          };
        },
        onError: (payload, meta) => {
          resolve(meta);
          return {
            type: actionTypes.FAQ_ERROR,
            meta,
          };
        },
      });
    });
  };
}
