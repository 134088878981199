import classnames from "classnames";
import StarFull from "components/Icons/StarFull";
import React, { Component } from "react";

import style from "./style.module.css";

export default class AverageRating extends Component {
  render() {
    const { averageScore, rootClassName } = this.props;
    const finalClassName = classnames(rootClassName, style.root);
    return (
      <div className={finalClassName}>
        <StarFull className={style.fullStar} />
        {averageScore && (
          <span className={style.averageScore}>{averageScore}</span>
        )}
      </div>
    );
  }
}
