import consts from "../consts/consts";
import { findObjectInArrayBy } from "../web-api/helpers/dataHelper";
const appleConf = findObjectInArrayBy(consts.SSOSocials, "name", "apple");

export function initAppleIdAuth() {
  if (appleConf?.clientID) {
    AppleID.auth.init({
      clientId: appleConf.clientID,
      response_type: "code",
      scope: appleConf.scope,
      redirectURI: appleConf.callbackAPI,
      state: "Alphanentworks",
      usePopup: appleConf?.usePopup, //or false defaults to false
    });
  }
}

export function loginWithApple() {
  // eslint-disable-next-line no-async-promise-executor
  return new Promise(async (resolve, reject) => {
    try {
      const data = await AppleID.auth.signIn();
      resolve(data);
    } catch (error) {
      reject(error);
    }
  });
}
