import { formatFullDateAndTime } from "../helpers/timeHelper";

export default class OptionValidity {
  constructor(data) {
    let item = data;

    if (item) {
      this.idOption = item.optionId;
      this.statusId = item.statusId;
      this.saleOptionId = item.saleOptionId;
      this.contractEndDate = item.contractEndDate;
      this.cancellationDate = item.cancelDate;
      this.allowCancel = item.allowCancel;
      this.allowPay = item.allowPay;
      this.allowRenewal = item.allowRenewal;
      this.price = item.totalPrice;
      this.radiationEndDate = item.radiationEndDate;
      this.engagmentDate = item.engagmentDate;
      this.displayUntil = item.displayUntil;
      this.validTo = item.validTo;
      this.validFrom = item.validFrom;
    }
  }

  getIdOption() {
    return this.idOption;
  }

  getDisplayUntil() {
    return formatFullDateAndTime(new Date(this.displayUntil * 1000));
  }

  getContractEndDate() {
    return formatFullDateAndTime(new Date(this.contractEndDate * 1000));
  }

  getEngagmentDate() {
    return formatFullDateAndTime(new Date(this.engagmentDate * 1000));
  }

  getCancellationDate() {
    return formatFullDateAndTime(new Date(this.cancellationDate * 1000));
  }

  getRadiationEndDate() {
    return formatFullDateAndTime(new Date(this.radiationEndDate * 1000));
  }

  getAllowCancel() {
    return this.allowCancel;
  }

  getAllowPay() {
    return this.allowPay;
  }

  getAllowRenewal() {
    return this.allowRenewal;
  }

  getPrice() {
    return this.price;
  }
}
