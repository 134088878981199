import ScrollIcon from "components/Icons/Scroll";
import React, { Component } from "react";
import { Trans } from "react-i18next";

import style from "./style.module.css";
export default class ScrollButton extends Component {
  state = {
    intervalId: 0,
  };
  scrollStep() {
    if (window.pageYOffset === 0) {
      clearInterval(this.state.intervalId);
    }
    window.scroll(0, window.pageYOffset - 150);
  }

  scrollToTop(e) {
    e.preventDefault();
    let intervalId = setInterval(this.scrollStep.bind(this), 15);
    this.setState({ intervalId: intervalId });
  }
  render() {
    return (
      <a
        href="#top"
        className={style.container}
        onClick={(e) => {
          this.scrollToTop(e);
        }}
      >
        <ScrollIcon className={style.icon} />
        <p className={style.labelContainer}>
          <Trans>Back to top</Trans>
        </p>
      </a>
    );
  }
}
