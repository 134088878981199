import classnames from "classnames";
import { isConnectedFromLocalISP } from "helpers/localIsp";
import React, { Component } from "react";

import style from "./style.module.css";

export default class ContentWrapper extends Component {
  render() {
    const { children, rootClassName } = this.props;
    const isLocalIsp = isConnectedFromLocalISP();
    return (
      <div
        className={classnames(style.container, rootClassName, {
          [style.withFooter]: isLocalIsp,
        })}
      >
        {children}
      </div>
    );
  }
}
