import InlineSVG from "inline-svg-react";
import React from "react";

const Subscription = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Subscription.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Subscription.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <path d="M12.4149231,20.1201663 C12.4149231,20.9693971 11.7267179,21.6586278 10.8764615,21.6586278 C10.0272308,21.6586278 9.338,20.9693971 9.338,20.1201663 C9.338,19.2699099 10.0272308,18.5817048 10.8764615,18.5817048 C11.7267179,18.5817048 12.4149231,19.2699099 12.4149231,20.1201663 Z M18.4694278,20.1201663 C18.4694278,20.9693971 17.7812227,21.6586278 16.9309663,21.6586278 C16.0817355,21.6586278 15.3925048,20.9693971 15.3925048,20.1201663 C15.3925048,19.2699099 16.0817355,18.5817048 16.9309663,18.5817048 C17.7812227,18.5817048 18.4694278,19.2699099 18.4694278,20.1201663 Z M23.8181818,7.19209524 C23.8181818,7.49334147 22.8242424,10.5505218 20.8363636,16.3636364 L7.09090909,16.3636364 L6.01790293,7.51002249 L4.41711025,4.81818182 L1,4.81818182 C0.548130659,4.81818182 0.181818182,4.45186934 0.181818182,4 C0.181818182,3.54813066 0.548130659,3.18181818 1,3.18181818 L5.34784213,3.18181818 L7.24612785,6.37391342 L23,6.37391342 C23.4518693,6.37391342 23.8181818,6.7402259 23.8181818,7.19209524 Z M8.53253042,14.710761 L19.6579197,14.710761 C20.6373289,11.7336394 21.3718858,9.50277967 21.8615904,8.01818182 C20.7272727,8.01818182 9.81818182,8.01818182 7.70480501,8.00298228 C7.88874399,9.49359978 8.16465246,11.729526 8.53253042,14.710761 Z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Subscription;
