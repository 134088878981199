import classnames from "classnames";
import Image, { PlaceholderType } from "components/Image";
import consts from "consts/consts";
import React, { Component } from "react";

import style from "./style.module.css";

export default class AssetHeaderBackground extends Component {
  render() {
    const { width, height, url, rootClassName } = this.props;
    return (
      <div className={classnames(style.root, rootClassName)}>
        <div className={style.headerContainer}>
          <Image
            rootClassName={style.image}
            width={width}
            height={height}
            src={url}
            placeholder={PlaceholderType.PRIMARY}
          />
        </div>
        {consts.showSliderGradient && <div className={style.headerGradient} />}
      </div>
    );
  }
}
