import InlineSVG from "inline-svg-react";
import React from "react";

const RoundLive = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/RoundLive.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/RoundLive.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 24 24" {...props}>
          <circle cx="11" cy="12" r="11" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default RoundLive;
