import InlineSVG from "inline-svg-react";
import React from "react";

const Check = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Check.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Check.svg");
    } catch (err) {
      return (
        <svg viewBox="0 0 30 30" {...props}>
          <circle r={15} cx={15} cy={15} />
          <path d="M8.867 12.335L7.5 13.736l6.6 6.764 8.4-11.835L20.936 7.5l-7.068 9.96z" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Check;
