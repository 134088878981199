import classnames from "classnames";
import { getFromLocal } from "helpers/localStorage";
import PropTypes from "prop-types";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Checkbox extends Component {
  state = {
    checked: false,
  };

  componentDidMount() {
    this.setState({ checked: this.props?.checked });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      // Reset Error Handler state on pathname change
      this.setState({ checked: this.props.checked });
    }
  }

  onChange() {
    if (this.props.change) {
      this.props.change();
    } else {
      const value = !this.state.checked;
      this.setState({
        checked: value,
      });
      if (this.props.onChange) {
        this.props.itemKey
          ? this.props.onChange(value, this.props.itemKey)
          : this.props.name
          ? this.props.onChange(value, this.props.name)
          : this.props.onChange(value);
      }
    }
  }

  render() {
    const { type, hasError, disabled, label, rootClassName } = this.props;
    const layoutDirection = getFromLocal("layoutDirection");
    const { checked } = this.state;

    const checkboxClassname = classnames(style.checkbox, rootClassName, {
      [style.checkboxSwitch]: type === CheckboxTypes.SWITCH,
      [style.checkboxHasError]: hasError,
      [rootClassName]: true,
    });

    const inputClassname = classnames(style.input, rootClassName, {
      [style.inputSwitch]: type === CheckboxTypes.SWITCH,
      [style.inputSwitchRTL]:
        type === CheckboxTypes.SWITCH &&
        layoutDirection.toLowerCase() === "rtl",
      [style.inputHasError]: hasError,
    });

    const labelClassname = classnames(style.label, {
      [style.labelSwitch]: type === CheckboxTypes.SWITCH,
    });

    return (
      <div className={checkboxClassname}>
        {type === CheckboxTypes.SWITCH && (
          <label className={labelClassname} onClick={this.onChange.bind(this)}>
            {label}
          </label>
        )}
        <input
          type="checkbox"
          className={inputClassname}
          checked={checked}
          disabled={disabled}
          onChange={this.onChange.bind(this)}
        />
        {type === CheckboxTypes.DEFAULT && label !== "" && (
          <label className={labelClassname} onClick={this.onChange.bind(this)}>
            {label}
          </label>
        )}
      </div>
    );
  }
}

export const CheckboxTypes = {
  DEFAULT: "DEFAULT",
  SWITCH: "SWITCH",
};

Checkbox.defaultProps = {
  checked: false,
  disabled: false,
  hasError: false,
  type: CheckboxTypes.DEFAULT,
  label: "",
};

Checkbox.propTypes = {
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
  hasError: PropTypes.bool,
  type: PropTypes.oneOf(Object.values(CheckboxTypes)),
  label: PropTypes.any,
  onChange: PropTypes.func,
};
