import InlineSVG from "inline-svg-react";
import React from "react";

const Kebab = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Kebab.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Kebab.svg");
    } catch (err) {
      return (
        <svg width="25" height="25" viewBox="0 0 60 60" {...props}>
          <circle cx="10" cy="30" r="5" stroke="#000" />
          <circle cx="25" cy="30" r="5" stroke="#000" />
          <circle cx="40" cy="30" r="5" stroke="#000" />
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Kebab;
