import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans } from "react-i18next";
import { withTranslation } from "react-i18next";

import style from "./style.module.css";

const noop = () => {
  // no operation (do nothing real quick)
};

class SelectCustom extends Component {
  state = {
    value: "",
    showErrorMessage: false,
    error: [],
  };

  componentDidMount() {
    if (
      this.props &&
      this.props.defaultValue &&
      this.props.defaultValue.length > 0
    ) {
      this.setState((prevState) => ({
        ...prevState,
        value: this.props.defaultValue[0] || "",
      }));
    }
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (
      prevProps !== this.props &&
      this.props.defaultValue &&
      this.props.defaultValue.length > 0
    ) {
      this.setState((prevState) => ({
        ...prevState,
        value: this.props.defaultValue[0]
          ? {
              label: this.props.t(this.props.defaultValue[0].label),
              value: this.props.defaultValue[0].value,
            }
          : "",
      }));
    }

    if (prevProps.error !== this.props.error) {
      let error = [];
      let showErrorMessage = false;

      if (this.props.error) {
        error.push("Required");
        showErrorMessage = true;
      }

      this.setState((prevState) => ({
        ...prevState,
        error: error,
        showErrorMessage: showErrorMessage,
      }));
    }
  }

  selectRef = null;
  setSelectRef = (ref) => {
    this.selectRef = ref;
  };

  onChange = (value) => {
    const { required, t } = this.props;
    const valueOption = value.value || "";
    let error = [];

    if (required && (!valueOption || valueOption === "")) {
      error.push(t("Required"));
    }

    this.setState({ error: error });

    if (this.props.onChange) {
      this.props.onChange(valueOption);
    }

    this.setState({ value: valueOption, showErrorMessage: true });
  };

  render() {
    const {
      SelectComponent,
      required,
      isDisabled,
      className,
      placeholder,
      options,
      ...props
    } = this.props;
    const { value } = this.state;

    const enableRequired = !isDisabled;
    const selectOptions = options.map((option) => {
      if (option.value) {
        return { value: option.value, label: props.t(option.label) };
      } else {
        return option;
      }
    });
    const { showErrorMessage, error } = this.state;
    const finalClassName = classnames(className, style.selectContainer);
    return (
      <Fragment>
        <div>
          <SelectComponent
            {...props}
            options={selectOptions}
            value={value}
            isDisabled={isDisabled}
            className={finalClassName}
            ref={this.setSelectRef}
            onChange={this.onChange}
            placeholder={placeholder}
          />
          {enableRequired && (
            <input
              tabIndex={-1}
              autoComplete="off"
              style={{
                opacity: 0,
                display: "none",
              }}
              value={value}
              onChange={noop}
              onFocus={() => this.selectRef.focus()}
              required={required}
            />
          )}
        </div>
        {showErrorMessage && error && error.length > 0 && (
          <div>
            {this.state.error.map((item) => (
              <span key={item} className={style.error}>
                <Trans>{item}</Trans>
              </span>
            ))}
          </div>
        )}
      </Fragment>
    );
  }
}

SelectCustom.defaultProps = {
  onChange: noop,
  required: false,
  placeholder: "",
};

SelectCustom.propTypes = {
  SelectComponent: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default withTranslation()(SelectCustom);
