import ThumbnailCover from "components/ThumbnailCover";
import React, { Component } from "react";
import { connect } from "react-redux";
import { TucanoSelectors } from "web-api/main";

class ThumbnailRecord extends Component {
  render() {
    const { record, thumbnailSize, isConnected, channel } = this.props;
    const program = record.getProgram();

    return (
      <ThumbnailCover
        usePosterImage={true}
        asset={program}
        activeTrailer={true}
        isConnected={isConnected}
        size={thumbnailSize}
        imageType={500}
        showIconNPVRRecord={true}
        record={record}
        channel={channel}
      />
    );
  }
}

export default connect((state, props) => {
  const epg = props.record.getProgram();
  return {
    channel: epg && TucanoSelectors.getChannelById(state, epg.getChannelId()),
  };
})(ThumbnailRecord);
