import InlineSVG from "inline-svg-react";
import React from "react";

const Scheduled = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Scheduled.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Scheduled.svg");
    } catch (err) {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g fill="none" fillRule="evenodd">
            <path
              d="M3 11.2v7.61c0 .52.403.934.889.934H20.11c.487 0 .89-.414.89-.934V11.2H3zm19.627-1.504c.006.04.01.08.01.122v8.992c0 1.415-1.127 2.57-2.527 2.57H3.89c-1.4 0-2.525-1.155-2.525-2.57V11.2h-.01V7.422c0-.936.83-1.695 1.85-1.695H4.53v1.091h.015A2.447 2.447 0 0 0 6.971 9a2.447 2.447 0 0 0 2.427-2.182h.015v-1.09h4.884v1.09h.015A2.447 2.447 0 0 0 16.74 9a2.447 2.447 0 0 0 2.427-2.182h.015v-1.09h1.596c1.022 0 1.85.758 1.85 1.694v2.274zM6.182 3.546a.818.818 0 1 1 1.636 0v3.272a.818.818 0 0 1-1.636 0V3.545zm9.818 0a.818.818 0 0 1 1.636 0v3.272a.818.818 0 1 1-1.636 0V3.545z"
              fill="#FFF"
              fillRule="nonzero"
            />
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Scheduled;
