import { addToast, removeToast, setLoginShow } from "actions/ui";
import ContentWrapper from "components/ContentWrapper";
import DevicesManager from "components/DevicesManager";
import Header from "components/Header";
import Loader from "components/Loader";
import consts from "consts/consts";
import PageAbstract from "containers/PageAbstract";
import { getFromLocal } from "helpers/localStorage";
import React, { Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { compose } from "redux";
import { TucanoActions, TucanoSelectors } from "web-api/main";

import style from "./style.module.css";

class PageDevices extends PageAbstract {
  static fetch(dispatch, _isConnected, _params) {
    dispatch(TucanoActions.getDevices());
  }

  errorAPIcasRemoveDevice = {
    "-1": "Invalid auth",
    "-10": "Device not found",
    "-11":
      "The maximum number of device removal has been reached,Please use a device linked to your account",
  };

  getErrorAPI(api, code) {
    if (api === "casRemoveDevice" && this.errorAPIcasRemoveDevice[code]) {
      return this.errorAPIcasRemoveDevice[code];
    } else {
      return "An error occured,Please retry later";
    }
  }

  addToast(text, url, className, duration) {
    this.props.dispatch(
      addToast({
        text: text,
        url: url,
        className: className,
        duration: duration,
      })
    );
  }

  handleOnDelete(index) {
    const devicesData = this.props.devices;
    const { t } = this.props;
    const deviceId = devicesData[index].getUniqueDeviceId();
    const currentDeviceId = getFromLocal(consts.storageKeys.device);

    this.props.dispatch(TucanoActions.casRemoveDevice(deviceId)).then(() => {
      const { dataOnCasRemoveDevice } = this.props;
      if (dataOnCasRemoveDevice && dataOnCasRemoveDevice.code) {
        for (var i = 0; i < this.props.toasts.length; i++) {
          if (
            this.props.toasts[i].text.includes(
              t(this.getErrorAPI("casRemoveDevice", dataOnCasRemoveDevice.code))
            )
          ) {
            this.props.dispatch(removeToast(this.props.toasts[i].id));
          }
        }
        let found = this.props.toasts.find((element) =>
          element.text.includes(
            t(this.getErrorAPI("casRemoveDevice", dataOnCasRemoveDevice.code))
          )
        )
          ? true
          : false;
        if (!found) {
          this.addToast(
            t(this.getErrorAPI("casRemoveDevice", dataOnCasRemoveDevice.code)),
            null,
            "error",
            6000
          );
        }
      } else {
        if (currentDeviceId === deviceId) {
          this.props.history.push(consts.routes.logout.url);
        } else {
          PageDevices.fetch(this.props.dispatch, this.props.isConnected);
        }
      }
    });
  }

  componentDidMount() {
    if (consts.appModules.devices !== true) {
      this.props.history.replace("/");
    }
    const { t } = this.props;
    document.title = t(this.props.route.title);
    if (consts.appTitle) {
      document.title = `${consts.appTitle} - ${t(this.props.route.title)}`;
    }

    if (this.props.isConnected) {
      PageDevices.fetch(this.props.dispatch, this.props.isConnected);
    } else {
      // this.props.history.push("?login=1");
      if (!consts.landOnLogin) {
        this.props.dispatch(setLoginShow(true));
      } else {
        this.props.history.push("?login=1");
      }
    }
  }

  onLoginDevicePairing = async (code, deviceId) => {
    const { dispatch } = this.props;
    this.props.history.replace(consts.routes.devices.url);
    return dispatch(TucanoActions.loginByPairing(code, deviceId));
  };

  getDevicesList = () => {
    const { dispatch } = this.props;
    PageDevices.fetch(dispatch);
  };

  render() {
    const { isLoading, devices, deviceCounter } = this.props;
    return (
      <Fragment>
        <ContentWrapper>
          {isLoading && <Loader centered={true} />}
          {!isLoading && (
            <Fragment>
              <Header rootClassName={style.header}>
                <Trans>{this.props.route.title}</Trans>
              </Header>
              <DevicesManager
                devices={devices}
                deviceCounter={deviceCounter}
                onDeleteClick={this.handleOnDelete.bind(this)}
                onLoginDevicePairing={this.onLoginDevicePairing.bind(this)}
                getDevicesList={this.getDevicesList.bind(this)}
              />
            </Fragment>
          )}
        </ContentWrapper>
      </Fragment>
    );
  }
}

export default compose(
  connect((state) => {
    return {
      isConnected: state.session.customerAuthToken !== undefined,
      isLoading: state.account.devices.loading,
      toasts: state.ui.toasts,
      devices: TucanoSelectors.getDevices(state),
      deviceCounter: TucanoSelectors.getDeviceCounter(state),
      dataOnCasRemoveDevice: state.account.casRemoveDevice.data,
    };
  }),
  withTranslation()
)(PageDevices);
