import InlineSVG from "inline-svg-react";
import React from "react";

const Failed = (props) => {
  let customIcon = null;
  try {
    customIcon = require("!!raw-loader!../../../public_skin/icons/Failed.svg");
  } catch (err) {
    try {
      customIcon = require("!!raw-loader!../../../public/icons/Failed.svg");
    } catch (err) {
      return (
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          {...props}
        >
          <g fill="none" fillRule="evenodd">
            <g fill="#D93535" fillRule="nonzero">
              <path
                d="M11.5 2a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19zm0 .704a8.796 8.796 0 1 1 0 17.592 8.796 8.796 0 0 1 0-17.592z"
                stroke="#D93535"
                strokeWidth=".5"
              />
              <path d="m12.136 13.189.363-6.967h-1.97l.37 6.967h1.237zm-.625 3.237c.331 0 .588-.095.77-.284.183-.19.275-.446.275-.77s-.09-.577-.27-.76c-.18-.182-.439-.273-.775-.273-.342 0-.605.087-.79.263-.184.176-.277.432-.277.77s.094.598.281.78c.187.183.45.274.786.274z" />
            </g>
          </g>
        </svg>
      );
    }
  }
  if (customIcon !== null) {
    return <InlineSVG icon={customIcon.default} {...props} />;
  }
};
export default Failed;
