import classnames from "classnames";
import PropTypes from "prop-types";
import React, { Component } from "react";

import style from "./style.module.css";

export default class Loader extends Component {
  render() {
    return (
      <div
        className={classnames(
          { [style.centered]: this.props.centered },
          this.props.loaderMinHeightClassName
        )}
      >
        <div className={style.root}>
          <div className={style.rect_1} />
          <div className={style.rect_2} />
          <div className={style.rect_3} />
          <div className={style.rect_4} />
          <div className={style.rect_5} />
        </div>
      </div>
    );
  }
}

Loader.propTypes = {
  centered: PropTypes.bool,
};

Loader.defaultProps = {
  centered: false,
};
