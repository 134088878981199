import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { TucanoActions } from "web-api/main";

export function sendAnalytics(dispatch, level, code, classification, context) {
  const deviceId = getFromLocal(consts.storageKeys.device);
  const applicationId = "BEEMIX-WEB";
  dispatch(
    TucanoActions.postAnalytics({
      deviceId,
      level,
      code,
      classification,
      applicationId,
      context,
    })
  );
}
