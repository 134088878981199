import {
  setChannelFilter,
  setChannelSort,
  setDirectArrayForFavorites,
  setTerm,
} from "actions/ui";
import classnames from "classnames";
import EpgInfos from "components/EpgInfos";
import ArrowHeading from "components/Icons/Arrowheading";
import Filter from "components/Icons/Filter";
import Sad from "components/Icons/Sad";
import SearchIcon from "components/Icons/Search";
import consts from "consts/consts";
import { ResponsiveType } from "consts/responsive";
import jwt_decode from "jwt-decode";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { TucanoSelectors } from "web-api/main";

import style from "./style.module.css";
const sortArray = [
  "N° ascending",
  "N° descending",
  "Name ascending",
  "Name descending",
  "Favorites first",
];
class PlayerRemote extends Component {
  constructor(args) {
    super(args);
    this.activeChannelRef = React.createRef();
    this.remoteContainerRef = React.createRef();
    this.menuContextFilterRef = React.createRef();
    this.handleOutsideClick = this.handleOutsideClick.bind(this);
    this.state = {
      clientX: 0,
      clientY: 0,
      menuContextFilterOpen: false,
      menuContextFilter: {},
      favoriteGroup: [],
      sortedGroups: [],
      token: null,
      filterInputFocus: false,
      isSortOpen: false,
      inputValue: "",
      inputFocus: false,
    };
  }
  async componentDidMount() {
    const { isConnected, groups, profileToken } = this.props;
    if (this.activeChannelRef && this.activeChannelRef.current) {
      //this.remoteContainerRef.current.scrollTo(0, this.activeChannelRef.current.offsetTop);
      this.activeChannelRef.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
    if (isConnected) {
      const decoded = jwt_decode(profileToken);
      this.setState({ token: decoded.id_profile });
    }
    await this.initialiseChannels(groups);
    // document.addEventListener("mousedown", this.handleOutsideClick, false);
  }
  toggleClickSort() {
    const { isSortOpen } = this.state;
    this.setState({ isSortOpen: !isSortOpen });
  }
  handleInputOnChange(event) {
    const term = event.target.value;
    this.setState({ inputValue: term });
    this.props.dispatch(setTerm(term));
  }
  componentWillUnmount() {
    const { isConnected } = this.props;
    if (!isConnected) {
      this.props.dispatch(
        setChannelSort({ value: null, profile: null }, isConnected)
      );
      this.props.dispatch(
        setChannelFilter({ value: null, profile: null }, isConnected)
      );
    }
    document.removeEventListener("mousedown", this.handleOutsideClick, false);
  }
  handleClickOnClose() {
    if (this.props.viewport.type !== "DESKTOP" && this.props.onClick) {
      // if ( this.props.onClick) {
      this.props.onClick();
    }
    this.setState({
      inputValue: "",
      inputFocus: false,
    });
  }
  initialiseChannels = async (groups) => {
    const { isConnected, channelFavorits, channels } = this.props;
    //kill me please: find a user's favorite channels and build a group that we add to sorting groups
    let favoriteGroup = [];
    channelFavorits?.map((favChan) => {
      channels?.forEach((channel) => {
        if (channel.id == favChan.idChannel) favoriteGroup.push(channel);
      });
    });
    if (
      this.props.filter !== "Favorites" ||
      !isConnected ||
      this.props.filter == null
    ) {
      await this.props.dispatch(setDirectArrayForFavorites(null));
    }
    // add favorite category to premade groups then resort
    let sortedGroups = groups;
    if (isConnected) {
      sortedGroups?.push({ group: "Favorites", count: favoriteGroup.length });
      if (this.props.filter == "Favorites") {
        await this.props.dispatch(setDirectArrayForFavorites(favoriteGroup));
      }
    }
    sortedGroups = sortedGroups?.sort(function (a, b) {
      var textA = a.group.toUpperCase();
      var textB = b.group.toUpperCase();
      return textA < textB ? -1 : textA > textB ? 1 : 0;
    });
    await this.setState({
      favoriteGroup: favoriteGroup,
      sortedGroups: sortedGroups,
    });
  };
  handleApplyFilter = async (filter) => {
    const { groups, isConnected } = this.props;
    // if (filter == "Favorites") {
    //   this.props.dispatch(setDirectArrayForFavorites(this.state.favoriteGroup));
    // } else {
    await this.props.dispatch(setTerm(""));
    if (filter !== "All channels")
      this.props.dispatch(
        setChannelFilter(
          { value: filter, profile: this.state.token },
          isConnected
        )
      );
    else {
      await this.props.dispatch(
        setChannelFilter(
          { value: null, profile: this.state.token },
          isConnected
        )
      );
    }
    await this.props.dispatch(setDirectArrayForFavorites(null));
    // }
    await this.initialiseChannels(groups);
  };
  handleApplySort = async (item) => {
    const { isConnected } = this.props;
    await this.props.dispatch(
      setChannelSort({ value: item, profile: this.state.token }, isConnected)
    );
  };
  openFilterMenuContext(event) {
    this.setState({
      menuContextFilterOpen: !this.state.menuContextFilterOpen,
      menuContextFilter: { clientX: event.clientX, clientY: event.clientY },
      filterInputFocus: !this.state.filterInputFocus,
    });
  }
  handleOutsideClick(event) {
    if (
      this.menuContextFilterRef.current &&
      !this.menuContextFilterRef.current.contains(event.target)
    ) {
      this.setState({
        menuContextFilterOpen: false,
        filterInputFocus: false,
        menuContextFilter: { clientX: 0, clientY: 0 },
      });
    }
  }
  render() {
    const {
      isConnected,
      liveEPG,
      filteredChannels,
      currentEPG,
      isFullscreen,
      viewport,
      isMenuOpen,
      isEPGLoading,
      filter,
      sortValue,
      t,
      term,
    } = this.props;
    const {
      menuContextFilterOpen,
      menuContextFilter,
      sortedGroups,
      filterInputFocus,
      isSortOpen,
      inputValue,
      inputFocus,
    } = this.state;
    /*
    DESKTOP HEIGHT CALCULATE = 277.76 = 127.76 + 70 + 50 + 30
      Title Header Height = 127.76 (height=48, padding-top=10, padding-bottom=10, margin-top=29.880, margin-bottom=29.880)
      EPG Header Selector Height = 70 (height=40, padding-bottom=30)
      EPG Header Channel Height = 50 (height=50)
      Space bottom = 30
    */
    //let height = viewport.height - 277.76;
    /*
   PHONE HEIGHT CALCULATE = 241.84 = 91.84 + 70 + 50 + 30
     Title Header Height = 91.84 (height=32, padding-top=10, padding-bottom=10, margin-top=19.920, margin-bottom=19.920)
     Space bottom = 20
   */
    /* height =
      viewport.type === ResponsiveType.DESKTOP
        ? height
        : viewport.type === ResponsiveType.TABLET
        ? height - 60
        : height - 25.92;
    height = height < viewport.height / 2 ? viewport.height - 200 : height;*/
    /*
   DESKTOP WIDTH CALCULATE
     Open menu width = 300
     Closed menu width = 100
     Container padding = 100 (padding-right=50 padding-left=50)
   */
    let width = viewport.width - (isMenuOpen ? 300 : 100) - 100;
    /*
   PHONE WIDTH CALCULATE
     Menu width = 0
     Container padding = 20 (padding-right=10 padding-left=10)
   */
    width =
      viewport.type !== ResponsiveType.DESKTOP ? viewport.width - 20 : width;

    // WIDTH COLUMN MAX = 365
    let widthColumn = width - 30 > 365 ? 255 : width - 30;
    return (
      <div className={style.container} ref={this.remoteContainerRef}>
        <div
          className={style.actionBar}
          onClick={(event) => {
            this.handleOutsideClick(event);
          }}
        >
          <div
            className={classnames(style.iconContainer, {
              [style.filterInputFocus]: filterInputFocus === true,
            })}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              this.openFilterMenuContext(event);
            }}
          >
            <Filter height={40} width={40} />
          </div>
          <div
            // style={{ width: viewport.type === ResponsiveType.PHONE ? widthColumn - 30 : widthColumn + 145 }}
            className={classnames(style.searchContainer, {
              [style.searchContainerFocus]: inputFocus === true,
            })}
          >
            <div
              className={classnames(style.iconSearch, {
                [style.iconFocus]: inputFocus === true,
              })}
            >
              <SearchIcon className={style.icon} />
            </div>
            <input
              className={classnames(style.input, {
                [style.inputFocus]: inputFocus === true,
              })}
              type="text"
              onChange={this.handleInputOnChange.bind(this)}
              value={inputValue}
              placeholder={t("Find a channel")}
            />
          </div>
        </div>
        {menuContextFilterOpen === true && (
          <div
            ref={this.menuContextFilterRef}
            style={{
              top:
                viewport.type !== ResponsiveType.DESKTOP
                  ? menuContextFilter.clientY
                  : menuContextFilter.clientY - 25,
              width: widthColumn - 40,
              left:
                viewport.type !== ResponsiveType.DESKTOP
                  ? menuContextFilter.clientX - 35
                  : menuContextFilter.clientX - 280,
              zIndex: 10,
            }}
            className={style.menuContext}
          >
            {sortedGroups && sortedGroups.length > 0 ? (
              <>
                <div
                  className={style.sortSection}
                  onClick={this.toggleClickSort.bind(this)}
                >
                  {isSortOpen ? (
                    <>
                      <ArrowHeading
                        className={classnames(style.arrow, style.arrowLeft)}
                      />
                      <div className={style.textSort}>
                        <Trans>Categories</Trans>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        className={style.text}
                        onClick={this.toggleClickSort.bind(this)}
                      >
                        <Trans>Sort by</Trans> :{` ${t(sortValue)}  `}
                      </div>
                      <ArrowHeading className={style.arrow} />
                    </>
                  )}
                </div>
                <div
                  className={style.separator}
                  style={{ width: widthColumn - 26 }}
                ></div>
                {!isSortOpen ? (
                  <>
                    {sortedGroups.map((group, index) => {
                      return (
                        <div
                          className={classnames(style.buttonContainerFilter, {
                            [style.groupFocused]:
                              group.group == filter ||
                              (group.group == "All channels" &&
                                filter === null),
                          })}
                          key={group + index}
                          onClick={() => this.handleApplyFilter(group.group)}
                        >
                          <div className={style.text}>
                            <Trans>{group.group}</Trans>
                          </div>
                          <div className={style.count}>{group.count}</div>
                        </div>
                      );
                    })}
                  </>
                ) : (
                  <>
                    <div
                      className={classnames(
                        style.buttonContainerFilter,
                        style.sortBy
                      )}
                    >
                      <Trans>Sort by</Trans>
                    </div>
                    {sortArray.map((item, index) => {
                      return (
                        <div
                          className={classnames(style.buttonContainerFilter, {
                            [style.groupFocused]: item == sortValue,
                          })}
                          key={item + index}
                          onClick={() => this.handleApplySort(item)}
                        >
                          <div className={style.text}>
                            <Trans>{item}</Trans>
                          </div>
                        </div>
                      );
                    })}
                  </>
                )}
              </>
            ) : (
              <div className={style.buttonContainerFilter}>
                <div className={style.text}>
                  <Trans>no groups</Trans>
                </div>
              </div>
            )}
          </div>
        )}
        <div className={style.EPGItemsContainer}>
          <>
            {term !== " " ? (
              <>
                {!isEPGLoading &&
                  filteredChannels &&
                  filteredChannels.foundChannels &&
                  filteredChannels.foundChannels.map((channel, index) => {
                    const epg = liveEPG.find((epg) => {
                      return channel.id === epg.getChannelId();
                    });
                    if (channel.id === currentEPG?.channel?.id) {
                      return (
                        <div ref={this.activeChannelRef} key={index}>
                          <EpgInfos
                            onRemote={true}
                            isConnected={isConnected}
                            showLock={true}
                            epg={epg}
                            channel={channel}
                            rootClassName={style.activeEPG}
                            showFavorite
                          />
                        </div>
                      );
                    }
                    if (channel.found || term === "") {
                      return (
                        <Link
                          className={style.epgLink}
                          key={index}
                          to={{
                            pathname: consts.routes.playerlive.url.replace(
                              ":channelId",
                              channel.id
                            ),
                            state: { fullScreen: isFullscreen },
                          }}
                        >
                          <EpgInfos
                            isConnected={isConnected}
                            showLock={true}
                            epg={epg}
                            channel={channel}
                            onRemote={true}
                            showFavorite
                          />
                        </Link>
                      );
                    }
                  })}
                {!isEPGLoading &&
                  filteredChannels &&
                  !filteredChannels.atLeastOneFound &&
                  term !== "" && (
                    <div className={style.noResults}>
                      <Sad className={style.noResultsIcon} />
                      <p>
                        <Trans>No results on :</Trans>
                        {`  `}
                        {filter !== null ? filter : t(" All channels")}
                      </p>
                    </div>
                  )}
              </>
            ) : (
              <>
                {!isEPGLoading &&
                  filteredChannels &&
                  filteredChannels.sortedChannels &&
                  filteredChannels.sortedChannels.map((channel, index) => {
                    const epg = liveEPG.find((epg) => {
                      return channel.id === epg.getChannelId();
                    });
                    if (channel.id === currentEPG?.channel?.id) {
                      return (
                        <div ref={this.activeChannelRef} key={index}>
                          <EpgInfos
                            onRemote={true}
                            isConnected={isConnected}
                            showLock={true}
                            epg={epg}
                            channel={channel}
                            rootClassName={style.activeEPG}
                            showFavorite
                          />
                        </div>
                      );
                    }
                    return (
                      <Link
                        className={style.epgLink}
                        key={index}
                        to={{
                          pathname: consts.routes.playerlive.url.replace(
                            ":channelId",
                            channel.id
                          ),
                          state: { fullScreen: isFullscreen },
                        }}
                      >
                        <EpgInfos
                          isConnected={isConnected}
                          showLock={true}
                          epg={epg}
                          channel={channel}
                          onRemote={true}
                          showFavorite
                        />
                      </Link>
                    );
                  })}
              </>
            )}
          </>
        </div>
      </div>
    );
  }
}
PlayerRemote.defaultProps = {
  liveEPG: [],
  channels: [],
  isConnected: false,
};

PlayerRemote.propTypes = {
  isConnected: PropTypes.bool,
  liveEPG: PropTypes.array,
  channels: PropTypes.array,
};
export default compose(
  connect((state, props) => {
    const EPGDate = state.ui.EPGDate;
    const channelFavorits = state.content.favoritesChannels.data;
    let filter = state.ui.discoverFilter;
    let sort = state.ui.discoverSort;
    const term = state.ui.searchTerm;
    const profileToken = state.session.profileToken || null;
    if (props.isConnected) {
      const decoded = jwt_decode(profileToken);
      const channelFilter = state.ui.channelFilter;
      const sortValue = state.ui.channelSort;
      // object that has profile and filter for the given profile
      const profileFilter =
        channelFilter?.filter((item) => item.profile === decoded.id_profile) ||
        null;
      const profileSort =
        sortValue?.filter((item) => item.profile === decoded.id_profile) ||
        null;
      filter = (profileFilter?.length > 0 && profileFilter[0]?.value) || null;
      sort = (profileSort?.length > 0 && profileSort[0]?.value) || null;
    }
    const directArrayValueForFavorites = state.ui.directArrayForFavorites;
    return {
      channelFavorits,
      viewport: state.ui.viewport,
      isConnected: state.session.customerAuthToken !== undefined,
      profileToken: profileToken,
      EPGDate: EPGDate,
      isEPGLoading: state.content.epg.loading,
      channels: TucanoSelectors.getEPG(
        state,
        EPGDate,
        consts.durationEPGDisplayImage
      ),
      groups: TucanoSelectors.getGroups(state),
      filter: filter,
      sortValue: sort,
      term: term,
      filteredChannels: TucanoSelectors.getFilteredEpg(
        state,
        EPGDate,
        filter,
        sort,
        term,
        directArrayValueForFavorites,
        channelFavorits,
        consts.durationEPGDisplayImage
      ),
    };
  }),
  withTranslation()
)(PlayerRemote);
